import { createSlice } from "@reduxjs/toolkit";
import { resetAction } from "./reset";

type CustomError = {
  isError: boolean;
  errorMessage: string;
  checkoutError: any;
  isValidateRefNumberError: boolean;
  simConfigurationError: {
    errorType: string;
    errorCode: number;
    errorDescription: string;
  };
};

const initialState: CustomError = {
  isError: false,
  errorMessage: "",
  checkoutError: null,
  isValidateRefNumberError: false,
  simConfigurationError: null,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setCheckoutError: (state, action) => {
      return { ...state, checkoutError: action.payload };
    },
    setIsValidateRefNumberError: (state, action) => {
      state.isValidateRefNumberError = action.payload;
    },
    setSimConfigurationError: (state, action) => {
      return { ...state, simConfigurationError: action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  setError,
  setErrorMessage,
  setCheckoutError,
  setIsValidateRefNumberError,
  setSimConfigurationError,
} = errorSlice.actions;

export default errorSlice.reducer;
