import "./style.scss";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { updateCartBarrings, updateDirectoryListing } from "@state/cart-slice";
import {
  CheckboxGroup,
  Checkbox,
  Column,
  Grid,
  Spacing,
  TextStyle,
  RadioGroup,
  RadioButton,
} from "@able/react";
import { Accordion } from "telstra-ui/components/accordion/Accordion";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import { useGetBarringsAddonsQuery } from "@services/catalog";

const BarringSection = (props) => {
  const { data: barringDetails } = useGetBarringsAddonsQuery();
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.cart.plans);
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );

  const checkedState = (barringId) => {
    let checked = true;
    for (const planId of selectedPlans) {
      if (!plans[planId]?.barrings[barringId]) {
        checked = false;
        break;
      }
    }
    return checked;
  };

  const onChangeCheckboxOrRadio = (e, type) => {
    if (selectedPlans.length === 0) {
      props.setServiceError(
        NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS.SERVICE_ERRORS.BARRING
      );
    } else {
      const { id, value, name, checked } = e.target;
      const updatedValues = {
        id,
        value,
        name,
        checked,
        selectedPlans,
      };
      type === "checkbox"
        ? dispatch(updateCartBarrings(updatedValues))
        : dispatch(updateDirectoryListing(updatedValues));
    }
  };

  return (
    <div className="barringSection" key="barringSection">
      <Accordion
        exclusive={false}
        items={[
          {
            header: (
              <TextStyle alias="HeadingD">
                {
                  NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS.BARRING
                    .SECTION_TITLE
                }
              </TextStyle>
            ),
            contentId: "barring",
            content: (
              <>
                {barringDetails && (
                  <>
                    <TextStyle alias="Base">
                      {
                        NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS.BARRING
                          .ASSISTIVE_TEXT
                      }
                    </TextStyle>
                    <Spacing top="spacing4x" bottom="spacing4x">
                      <Grid>
                        {barringDetails?.barringDetails.map(
                          (barringItems, index) => {
                            return (
                              <Column
                                vxs={12}
                                bsm={3}
                                bmd={3}
                                blg={3}
                                key={index}
                              >
                                {barringItems.selectionType === "checkbox" ? (
                                  <Spacing right="spacing5x">
                                    <CheckboxGroup
                                      groupLabel={barringItems.barringType}
                                      name={barringItems.barringType}
                                      variant="Default"
                                      required={true}
                                    >
                                      {barringItems.values.map(
                                        (barringItem) => (
                                          <Checkbox
                                            label={barringItem.name}
                                            id={`checkbox-${barringItem.id}`}
                                            value={barringItem.name}
                                            checked={
                                              selectedPlans.length === 0
                                                ? false
                                                : checkedState(
                                                    `${barringItem.id}`
                                                  )
                                            }
                                            key={barringItem.name}
                                            events={{
                                              onChange: (e) =>
                                                onChangeCheckboxOrRadio(
                                                  e,
                                                  "checkbox"
                                                ),
                                            }}
                                          />
                                        )
                                      )}
                                    </CheckboxGroup>
                                  </Spacing>
                                ) : (
                                  <RadioGroup
                                    groupLabel={barringItems.barringType}
                                    name={barringItems.barringType}
                                    variant="Default"
                                  >
                                    {barringItems.values.map((barringItem) => (
                                      <RadioButton
                                        label={barringItem.name}
                                        id={`radio-${barringItem.id}`}
                                        value={barringItem.name}
                                        checked={
                                          selectedPlans.length === 0
                                            ? barringItem.checkStatus
                                            : checkedState(`${barringItem.id}`)
                                        }
                                        key={barringItem.name}
                                        events={{
                                          onChange: (e) =>
                                            onChangeCheckboxOrRadio(e, "radio"),
                                        }}
                                      />
                                    ))}
                                  </RadioGroup>
                                )}
                              </Column>
                            );
                          }
                        )}
                      </Grid>
                    </Spacing>
                  </>
                )}
              </>
            ),
            startOpen: true,
          },
        ]}
      />
    </div>
  );
};

export default BarringSection;
