import React from "react";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleSelectedNumbers } from "@state/sim-configuration-slice";

export default function AvailableNumberCheckbox({ item, quantity }) {
  const dispatch = useAppDispatch();
  const selectedNumbers = useAppSelector(
    (state) => state.simConfiguration.selectedNumbers
  );
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );

  return (
    <Checkbox
      id={item.number}
      name="available-number"
      checked={selectedNumbers.includes(item.number)}
      onChange={(e) => {
        dispatch(toggleSelectedNumbers(e.target.id));
      }}
      disabled={
        quantity > selectedNumbers.length
          ? selectedNumbers.length >= selectedPlans.length
            ? !selectedNumbers.includes(item.number)
            : false
          : false
      }
    />
  );
}
