import { createSlice } from "@reduxjs/toolkit";
import { DeviceProduct } from "./types";
import { resetAction } from "./reset";

export type SelectDevicesState = {
  selectedDevices: DeviceProduct[];
  backOrder: boolean;
  configuredDeviceSelected: string[];
  isDeviceSelected: boolean;
};

const initialState: SelectDevicesState = {
  selectedDevices: [],
  backOrder: false,
  configuredDeviceSelected: [],
  isDeviceSelected: true,
};

export const selectDevicesSlice = createSlice({
  name: "select-devices",
  initialState,
  reducers: {
    updateMultipleDevices: (state, action) => {
      const selectedDevices = action.payload;
      state.selectedDevices.push(...selectedDevices);
      return state;
    },
    updateSelectedDevices: (state, action) => {
      const { device, newQuantity } = action.payload;
      const index = state.selectedDevices.findIndex(
        (selectedDevice) => selectedDevice.id == device.id
      );
      if (index === -1) {
        state.selectedDevices.push({ ...device, quantity: newQuantity });
      } else if (newQuantity === 0) {
        state.selectedDevices = state.selectedDevices.filter(
          (selectedDevice) => selectedDevice.id != device.id
        );
      } else {
        state.selectedDevices[index].quantity = newQuantity;
      }
    },
    deleteSelectedDevice: (state, action) => {
      const deviceId = action.payload;
      state.selectedDevices = state.selectedDevices.filter(
        (selectedDevice) => selectedDevice.id !== deviceId
      );
    },
    toggleAllDevices: (state, action) => {
      if (action.payload.length === 0) {
        return { ...state, configuredDeviceSelected: [] };
      }
      return { ...state, configuredDeviceSelected: [...action.payload] };
    },
    toggleDeviceSelection: (state, action) => {
      const id = action.payload;
      const position = state.configuredDeviceSelected.indexOf(id);
      if (position === -1) {
        state.configuredDeviceSelected.push(id);
      } else {
        state.configuredDeviceSelected.splice(position, 1);
      }
    },
    updateBackOrderStatus: (state, action) => {
      return { ...state, backOrder: action.payload };
    },
    deleteSelectedConfiguredDevice: (state, action) => {
      return { ...state, configuredDeviceSelected: [] };
    },
    setIsDeviceSelected: (state, action) => {
      state.isDeviceSelected = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  updateSelectedDevices,
  updateMultipleDevices,
  deleteSelectedDevice,
  toggleAllDevices,
  toggleDeviceSelection,
  updateBackOrderStatus,
  setIsDeviceSelected,
  deleteSelectedConfiguredDevice,
} = selectDevicesSlice.actions;
export default selectDevicesSlice.reducer;
