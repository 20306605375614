import {
  CartItem,
  Characteristic,
  CreateCartParams,
  NormalizedDevice,
  DeviceProduct,
  IOffer,
  PatchCart,
  NormalizedPlan,
} from "@state/types";
import { CONSTANTS } from "@services/constant";
import { CART } from "@utils/common-static-data";
import { RootState } from "@state/store";

const { ATTRIBUTES } = CART;
const { REMOVE, UPDATE } = CONSTANTS.CART_ACTIONS;

export function createCartBody(params: CreateCartParams) {
  const cartAttributes = [
    {
      name: ATTRIBUTES.TELSTRA_INTERNAL_REFERENCE,
      value: params.internalReferenceNumber,
    },
    {
      name: ATTRIBUTES.CUSTOMER,
      value: params.cidn,
    },
    {
      name: ATTRIBUTES.REQUESTER,
      value: params.requester,
    },
    {
      name: ATTRIBUTES.ORDER_REFERENCE,
      value: params.orderReference,
    },
  ];

  return { cartAttributes };
}

function generateSelectPlansPatchCartItemBody(
  offer: IOffer,
  index: number,
  billingAccount: string
): CartItem {
  return {
    id: null,
    consumerItemId: `${index + 2}`,
    action: CONSTANTS.CART_ACTIONS.ADD,
    quantity: offer.quantity,
    itemRelationships: [
      {
        id: "1",
        relationshipType: "ReliesOn",
      },
    ],
    itemAttributes: [
      {
        name: ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
        value: billingAccount,
        valueType: "string",
        "@type": "member",
      },
    ],
    productOffering: {
      id: offer.offerId,
      name: offer.offerName,
      characteristics: offer.characteristics,
      marketSegments: offer.marketSegment,
    },
  };
}

export function generateSelectPlansPatchCartBody(params: any): PatchCart {
  const cartItems: CartItem[] = [
    {
      id: null,
      consumerItemId: "1",
      action: CONSTANTS.CART_ACTIONS.ADD,
      quantity: 1,
      itemRelationships: [],
      itemAttributes: [
        {
          name: ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
          value: params.billingAccount,
          valueType: "string",
          "@type": "member",
        },
      ],
      productOffering: {
        id: "DMCAT_Offer_001233",
        name: "Adaptive Mobility",
        characteristics: [],
        marketSegments: [
          {
            id: "B2B",
            name: "B2B",
          },
        ],
      },
    },
  ];

  params.selectedOffers.forEach((offer, index) =>
    cartItems.push(
      generateSelectPlansPatchCartItemBody(offer, index, params.billingAccount)
    )
  );

  return {
    cartId: params.cartId,
    cartItems,
  };
}

export function generatePatchCartCancelDraftOrder(reason) {
  return {
    status: "Cancelled",
    cartAttributes: [
      {
        name: "cancellationReason",
        value: reason,
      },
    ],
  };
}

export function generatePatchCartOnBarringAndAddon(params) {
  const { previousPatchCartResponse, deltaPlans } = params;
  const cartItems: CartItem[] = previousPatchCartResponse.cartItems.map(
    (item) => {
      if (item.id in deltaPlans)
        return { ...item, action: CONSTANTS.CART_ACTIONS.REMOVE };
      else return { ...item, action: CONSTANTS.CART_ACTIONS.NO_CHANGE };
    }
  );

  return {
    cartId: previousPatchCartResponse.cartId,
    cartItems,
  };
}

export function generatePatchCartOnCheckout(params) {
  const { orderReferenceNumber, internalReferenceNumber } =
    params.previousPatchCartAttributes;

  const { cartItems } = params.previousPatchCartResponse;

  const reqCartAttributes = [];

  if (internalReferenceNumber !== params.internalReferenceNumber) {
    reqCartAttributes.push({
      name: CART.ATTRIBUTES.TELSTRA_INTERNAL_REFERENCE,
      value: params.internalReferenceNumber,
      valueType: "text",
    });
  }

  if (orderReferenceNumber !== params.orderReference) {
    reqCartAttributes.push({
      name: CART.ATTRIBUTES.ORDER_REFERENCE,
      value: params.orderReference,
      valueType: "text",
    });
  }

  const reqCartItems = [];
  for (const cartItem of cartItems) {
    const { productOffering } = cartItem;
    const { id: productOfferingID } = productOffering;
    const {
      deliveryAddress: { id: deliveryAddressID },
      contactDetails: { id: contactDetailsID },
    } = params;
    if (
      deliveryAddressID &&
      productOfferingID &&
      (productOfferingID === CONSTANTS.PRODUCT_IDS.AM_PLAN ||
        productOfferingID === CONSTANTS.PRODUCT_IDS.DEVICES ||
        productOfferingID === CONSTANTS.PRODUCT_IDS.ACCESSORIES)
    ) {
      reqCartItems.push({
        ...cartItem,
        action: CONSTANTS.CART_ACTIONS.UPDATE,
        itemPlace: [
          {
            type: CART.ITEM_PLACE_ATTRIBUTES.TYPE,
            id: deliveryAddressID,
            idType: CART.ITEM_PLACE_ATTRIBUTES.ID_TYPE,
            itemPlaceAttributes: [
              {
                name: CART.ITEM_PLACE_ATTRIBUTES.CONTACT_ID,
                value: contactDetailsID,
                valueType: "string",
              },
            ],
          },
        ],
      });
    } else {
      reqCartItems.push({
        ...cartItem,
        action: CONSTANTS.CART_ACTIONS.NO_CHANGE,
      });
    }
  }
  return {
    cartId: params.previousPatchCartResponse.cartId,
    ...(reqCartAttributes.length > 0 && { cartAttributes: reqCartAttributes }),
    cartItems: reqCartItems,
  };
}

export function generatePatchCartOnSimConfiguration(params) {
  const res = JSON.parse(JSON.stringify(params.previousPatchCartResponse));
  const changedPlans = params.plansInfo.filter(
    (plan) => plan.planUpdated === true
  );
  const cartItems: CartItem[] = changedPlans.map((currentPlan) => {
    const id = currentPlan.id;

    const previousPlan = res.cartItems.find(
      (item) => item.id === currentPlan.id
    );

    let itemAttributes;
    const previousItemAttributes = previousPlan.itemAttributes;
    const productOffering = previousPlan.productOffering;
    const itemRelationships = previousPlan.itemRelationships;
    const characteristics = [];

    if (currentPlan?.username?.length > 0) {
      const previousUsernameIndex = previousItemAttributes.findIndex(
        (item) => item.name === "Alias"
      );
      if (
        previousUsernameIndex !== -1 &&
        previousItemAttributes[previousUsernameIndex].value !==
          currentPlan.username
      ) {
        previousItemAttributes[previousUsernameIndex].value =
          currentPlan.username;
        itemAttributes = previousItemAttributes[previousUsernameIndex];
      } else if (previousUsernameIndex === -1) {
        itemAttributes = [
          {
            name: "Alias",
            valueType: "string",
            value: currentPlan.username,
            "@type": "member",
          },
        ];
      }
    }

    const previousPhoneNumber = productOffering.characteristics.find(
      (item) => item.name === "CustomerFacingServiceId"
    );

    if (previousPhoneNumber?.value !== currentPlan?.phoneNumber) {
      characteristics.push({
        name: "CustomerFacingServiceId",
        value: currentPlan?.phoneNumber,
      });
    }
    if (!currentPlan?.simSerialNumber && currentPlan?.simType) {
      characteristics.push({
        name: "SIMType",
        value: currentPlan.simType,
      });
    }

    if (currentPlan?.simSerialNumber?.length > 0) {
      const previousSimSerialNumberIndex =
        productOffering.characteristics.findIndex(
          (item) => item.name === "SIMSerialNumber"
        );
      if (
        previousSimSerialNumberIndex !== -1 &&
        productOffering.characteristics[previousSimSerialNumberIndex].value !==
          currentPlan.simSerialNumber
      ) {
        characteristics.push({
          name: "SIMSerialNumber",
          value: currentPlan.simSerialNumber,
        });
      } else if (previousSimSerialNumberIndex === -1) {
        characteristics.push({
          name: "SIMSerialNumber",
          value: currentPlan.simSerialNumber,
        });
      }
    }

    productOffering.characteristics = characteristics;
    return {
      id,
      action: "update",
      itemAttributes,
      itemRelationships,
      productOffering,
    };
  });

  return {
    cartId: params.previousPatchCartResponse.cartId,
    cartItems,
  };
}

const patchCartRequestSkeleton = (plan) => {
  return {
    id: plan.id,
    action: plan.action,
    quantity: plan.quantity,
    itemRelationships: plan.itemRelationships,
    productOffering: plan.productOffering,
  };
};
export function generatePatchCartRequest(params) {
  const deltaPlans = params.deltaPlans;
  const cartItems = [];
  const cartItemIds = Object.keys(deltaPlans);
  cartItemIds.forEach((id) =>
    cartItems.push(patchCartRequestSkeleton(deltaPlans[id]))
  );
  return {
    cartId: params.cartId,
    cartItems,
  };
}

export function generateSelectDevicesPatchCartBody(
  params: RootState
): PatchCart {
  const { billingAccount, cartId } = params.cart;
  const { selectedDevices } = params.selectDevices;

  function generateCartItemBody(device: DeviceProduct, index): CartItem {
    return {
      id: null,
      consumerItemId: `${index + 2}`,
      action: CONSTANTS.CART_ACTIONS.ADD,
      quantity: device?.quantity || 1,
      itemRelationships: device
        ? [{ id: "1", relationshipType: "ReliesOn" }]
        : [],
      itemAttributes: [
        {
          name: ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
          value: billingAccount,
          valueType: "string",
          "@type": "member",
        },
      ],
      productOffering: {
        id: device?.productId || "DMCAT_Offer_001231",
        name: device?.name || "Standalone Devices and Accessories",
        characteristics: device?.characteristics || [],
        marketSegments: device?.marketSegment || [{ id: "B2B", name: "B2B" }],
      },
    };
  }

  return {
    cartId,
    cartItems: [
      generateCartItemBody(null, -1),
      ...selectedDevices.map((device, index) =>
        generateCartItemBody(device, index)
      ),
    ],
  };
}

type Action = "update" | "remove";

export function createDeltaItem(
  item: NormalizedDevice | NormalizedPlan,
  action: Action,
  updatedCharacteristics?: Characteristic[]
): CartItem {
  return {
    id: item.id,
    action: action === "update" ? UPDATE : REMOVE,
    quantity: item.quantity,
    itemRelationships: item.itemRelationships,
    productOffering: {
      id: item.productOfferingId,
      marketSegments: item.marketSegments,
      characteristics: updatedCharacteristics,
    },
  };
}
