import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  buildPath,
  isInternalReferenceNumberInvalid,
  REGULAR_EXPRESSIONS,
  useGetFeatureFlagHook,
} from "@utils/helper";
import { useNavigate } from "react-router-dom";
import { NEW_ORDER_DETAILS } from "../constant";
import {
  NewOrderDetailsState,
  updateNewOrderDetails,
} from "@state/new-order-slice";
import { Card, Column } from "@able/react";
import { PATHS } from "@utils/common-static-data";
import { Config } from "@config/index";

type Props = {
  state: NewOrderDetailsState;
  setState: any;
  optionDetails: {
    LABEL: string;
    ID: string;
    LINK: string;
    TAG: string;
    PICTO: string;
  };
  createCart: any;
  validateRef: any;
};

export default function OrderingOptionButton(props: Props) {
  const { state, setState, optionDetails, createCart, validateRef } = props;
  const { LABEL, LINK, TAG, PICTO } = optionDetails;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enableQAMockForCart } = useGetFeatureFlagHook();

  const cidn = useAppSelector((store) => store.loggedInCIDN.cidn);

  async function handleNavigate() {
    const { requester, internalReferenceNumber, orderReference } = state;
    const formData = {
      cidn,
      requester,
      internalReferenceNumber,
      orderReference,
    };
    const res = await createCart(formData).unwrap();
    let cartId = res.data.cartId;
    if (res.code === 201) {
      if (LINK === PATHS.SELECT_DEVICES) {
        localStorage.setItem(
          "select-devices-page-from",
          PATHS.NEW_ORDER_DETAILS
        );
        if (
          Config.apiHostUrl.indexOf("localhost") > 0 ||
          Config.apiHostUrl.indexOf("mock") > 0 ||
          (enableQAMockForCart && Config.apiHostUrl.indexOf("slot3.org002") > 0)
        ) {
          cartId += "DVC";
        }
      }
      navigate(buildPath(LINK, cartId));
    }
  }

  async function handleSubmit() {
    const newState = { ...state };
    newState.internalReferenceNumberError = "";
    const { requester, internalReferenceNumber } = newState;
    if (!requester) {
      newState.requesterError = NEW_ORDER_DETAILS.REQUIRED_ERROR;
    }
    if (!internalReferenceNumber) {
      newState.internalReferenceNumberError = NEW_ORDER_DETAILS.REQUIRED_ERROR;
    } else if (isInternalReferenceNumberInvalid(internalReferenceNumber)) {
      newState.internalReferenceNumberError =
        NEW_ORDER_DETAILS.INTERNAL_REFERENCE_NUMBER_ERROR;
    } else if (
      internalReferenceNumber.length === NEW_ORDER_DETAILS.PHOENIX_LENGTH &&
      REGULAR_EXPRESSIONS.PHOENIX.test(internalReferenceNumber)
    ) {
      const res = await validateRef(internalReferenceNumber).unwrap();
      if (!res.isValid) {
        newState.internalReferenceNumberError = NEW_ORDER_DETAILS.PHOENIX_ERROR;
      }
    }

    if (newState.requesterError || newState.internalReferenceNumberError) {
      setState({ ...newState });
    } else {
      dispatch(updateNewOrderDetails(newState));
      handleNavigate();
    }
  }

  return (
    <Column cols={12} bsm={4} bmd={4} blg={4}>
      <Card
        title={LABEL}
        tag={TAG}
        imageType="picto"
        picto={PICTO}
        useLargePictoOnDesktop={false}
        destinationTitleActionProps={{
          element: "a",
          onClick: (e) => {
            e.preventDefault();
            handleSubmit();
          },
        }}
        titleElement="strong"
      />
    </Column>
  );
}
