import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { v4 as uuidv4 } from "uuid";
import {
  IRequester,
  IPlansInfo,
  IAssociatedBillingAccounts,
  IValidateReferenceNumberRes,
  IGetFeatureFlagsAEM,
  RecommendedAddresses,
} from "../state/types";
import { Config, getLocalFeatureFlags } from "@config/index";
import { CONSTANTS } from "./constant";
import { setFlags } from "@state/aem-dynamic-content-slice";

export const baseUrl = Config.apiHostUrl;
export const caseManagementHostUrl = Config.caseManagementHostUrl;
export const numberManagementHostURL = Config.numberManagementHostURL;
export const aemHostURL = Config.aemDynamicContentHostURL; // change to 'http://localhost:5050' when pointing to mock.

interface IWindow {
  authProvider: any;
  cidn: string;
}
declare const window: IWindow;

export const baseApiService = createApi({
  reducerPath: "baseApiService",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const correlationId = uuidv4();
      const accessToken = await window.authProvider?.getAccessToken();
      const cidn = window.cidn;
      headers.set(CONSTANTS.HEADERS.AUTHORIZATION, `Bearer ${accessToken}`);
      headers.set(CONSTANTS.HEADERS.DS_CORRELATION_ID, correlationId);
      headers.set(
        CONSTANTS.HEADERS.DS_APPLICATION_LABEL,
        CONSTANTS.HEADERS.REIMAGINE_SPA
      );
      headers.set(
        CONSTANTS.HEADERS.DS_SOURCE_SYSTEM,
        CONSTANTS.HEADERS.STRATEGIC_ORDERING_UI
      );
      headers.set(CONSTANTS.HEADERS.CIDN, cidn);
      headers.set(
        CONSTANTS.HEADERS.CONTENT_TYPE,
        CONSTANTS.HEADERS.APPLICATION_JSON
      );
      headers.set(CONSTANTS.HEADERS.CONTENT_LANGUAGE, CONSTANTS.HEADERS.EN_AU);
      headers.set(CONSTANTS.HEADERS.ACCEPT, CONSTANTS.HEADERS.APPLICATION_JSON);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getContacts: builder.query<IRequester, void>({
      query: () => ({
        url: "customer-information/associated-contacts",
        method: CONSTANTS.METHODS.GET,
        headers: {
          "access-control-request-method": "GET",
        },
      }),
    }),
    getBillingAccounts: builder.query<IAssociatedBillingAccounts, void>({
      query: () => "customer-information/associated-billing-accounts",
      keepUnusedDataFor: 1,
    }),
    getPlansInfo: builder.query<IPlansInfo, void>({
      query: () => "plans-info",
      transformResponse: (responseData: IPlansInfo) => {
        responseData?.plans.map((plan) => {
          plan.invalid_UNMS_SIM_number = false;
          plan.invalid_SIM_number = false;
          plan.invalid_username = false;
          plan.invalid_SIM_type = false;
          return plan;
        });
        return responseData;
      },
    }),
    validateReferenceNumber: builder.query<IValidateReferenceNumberRes, string>(
      {
        query: (caseNumber) => `case-management/cases/${caseNumber}`,
      }
    ),
    getRecommendedAddresses: builder.query<RecommendedAddresses, void>({
      query: () => "customer-information/recommended-addresses",
    }),
  }),
});

export const AEMDynamicContentAPI = createApi({
  reducerPath: "AEMDynamicContentAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: aemHostURL,
  }),
  tagTypes: ["getFeatureFlags"],
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<IGetFeatureFlagsAEM, void>({
      query: () => `/feature-flags/config.json`, // remove config.json from query path when pointing to mock.
      providesTags: ["getFeatureFlags"],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setFlags(data));
        } catch {
          dispatch(setFlags(getLocalFeatureFlags()));
        }
      },
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetBillingAccountsQuery,
  useGetPlansInfoQuery,
  useLazyValidateReferenceNumberQuery,
  useGetRecommendedAddressesQuery,
} = baseApiService;

export const { useGetFeatureFlagsQuery } = AEMDynamicContentAPI;
