import { baseApiService } from "./base";
import {
  IOffersResponse,
  IOffer,
  DeviceOffersResponse,
  DeviceOffer,
} from "../state/types";

export const catalogApiService = baseApiService.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<IOffer[], void>({
      query: () => "catalog-management/offers?offer=ENTERPRISE_MOBILITY",
      transformResponse(response: IOffersResponse) {
        return response.data.offers;
      },
    }),
    getBarringsAddons: builder.query<any, void>({
      query: () => "barrings-addons",
    }),
    getDeviceOffers: builder.query<DeviceOffer[], void>({
      query: () =>
        "catalog-management/offers?offer=DEVICES_ACCESSORIES&category=Mobile",
      transformResponse(response: DeviceOffersResponse) {
        return response.data.offers;
      },
    }),
  }),
});

export const {
  useGetOffersQuery,
  useGetBarringsAddonsQuery,
  useGetDeviceOffersQuery,
} = catalogApiService;
