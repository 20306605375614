export const CART = {
  NEW_PLAN_ORDER: "New plan order",
  PAGE_TITLE: "Cart",
  PLAN: "Plans",
  DEVICES_AND_ACCESSORIES: "Device and accessories",
  CHARGES: "Charges",
  INLINE_MESSAGE:
    "Please review all details on this page and make any required changes before proceeding to checkout.",
  SEND_QUOTE_BUTTON: "Send quote",
  MODIFY_PLANS_BUTTON: "Modify plans",
  MODIFY_BARRING_ADDON_BUTTON: "Modify barrings & add-ons",
  MODIFY_SIM_CONFIG_BUTTON: "Modify SIM configuration",
  MODIFY_DEVICES_AND_ACCESSORIES: "Modify devices and accessories",
  MODIFY_CONFIGURATION: "Modify configuration",
};

export const CART_PLANS_TABLE = {
  ID: "id",
  QUANTITY: "Qty",
  PLAN: "Plan",
  BARRING: "Barring",
  ADD_ONS: "Add-ons",
  PHONE_NUMBER: "Phone number",
  SIM_TYPE: "SIM type",
  SIM_SERIAL_NUMBER: "SIM serial number",
  USERNAME: "Username",
  REMOVE: "Remove",
  GROUP_BY_CATEGORY: "groupByCategory",
  EXISTING_SIM: "existing SIM Card",
};

export const CART_CHARGES_TABLE = {
  ITEM: "Item",
  PAYMENT_TYPE: "Payment type",
  UNIT_CHARGES: "Unit charges(inc GST)",
  CHARGES_INC_GST: "Charges(inc GST)",
  CHARGES_EX_GST: "Charges(ex GST)",
  TOTAL_CHARGE: "Total charge",
  ONCE_OFF: "Once-Off",
  RECURRING: "Recurring",
};

export const CART_DEVICES_TABLE = {
  ID: "id",
  QUANTITY: "Quantity",
  ITEM: "Item",
  PAYMENT_TYPE: "Payment type",
  SKU: "sku",
  REMOVE: "Remove",
  REMOVE_DEVICE: "Remove Device",
};

export const REMOVE_DIALOG = {
  SINGLE_ITEM_TITLE: "Are you sure you want to remove this item?",
  SINGLE_ITEM_DESCRIPTION: "This item will be permanently removed.",
  MULTIPLE_ITEM_TITLE: "Are you sure you want to remove these items?",
  MULTIPLE_ITEM_DESCRIPTION: "These items will be permanently removed.",
  CONFIRM_BUTTON_LABEL: "Remove",
  CANCEL_BUTTON_LABEL: "Cancel",
};
