import "./style.scss";
import { useEffect } from "react";
import { ableDevelopmentUrl, renderBoldText } from "@utils/helper";
import { useShouldEnableGetCartHook } from "@utils/cart";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { MessageSection, Select } from "@able/react";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import AssignedPhoneNumber from "./assigned-phone-number";
import PlanCheckbox from "../../components/plan-checkbox";
import SIMSerialNumber from "./sim-serial-number";
import AddUsername from "./username";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleAll } from "@state/common-slice";
import { setPagination } from "@state/sim-configuration-slice";
import SelectedPlansCount from "../../components/selected-items-count";
import Selections from "../../components/selections";
import { updatePlansArrUsingIndex } from "@state/cart-slice";
import { useParams } from "react-router-dom";
import { useCommonGetCartQuery } from "@services/cart";
import { useGetConfigsQuery } from "@services/config";

const SimConfigurationTable = () => {
  const { cartId = "" } = useParams();
  const dispatch = useAppDispatch();
  const skipGetCart = useShouldEnableGetCartHook();
  useCommonGetCartQuery({ cartId }, { skip: !skipGetCart });
  const { data: configs = { SIM_TYPE_OPTIONS: [] } } = useGetConfigsQuery();

  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );

  const paginationState = useAppSelector(
    (state) => state.simConfiguration.paginationState
  );
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlans);

  const invalidSIM = plansInfo.some(
    (plan) =>
      plan.invalid_UNMS_SIM_number ||
      plan.invalid_SIM_number ||
      plan.invalid_username ||
      plan.invalid_SIM_type
  );

  const handleToggleAll = () => {
    if (selectedPlans.length === plansInfo.length) {
      dispatch(toggleAll([]));
    } else {
      const planIds = plansInfo.map((plan) => plan.id);
      dispatch(toggleAll(planIds));
    }
  };

  const generateTableData = (
    pageNum,
    sorted = [{}],
    pageSizeVal = 0,
    event = ""
  ) => {
    const totalPlans = plansInfo.length;

    if (totalPlans) {
      let pSize = paginationState.pageSize;
      const resultData = [];
      let lastIndex, startIndex;

      pSize = pageSizeVal > 0 ? pageSizeVal : pSize;

      lastIndex = pageNum * pSize;

      if (lastIndex > totalPlans) {
        lastIndex = totalPlans;
      }

      startIndex = lastIndex - pSize;

      if (pSize * pageNum > totalPlans) {
        startIndex = pSize * (pageNum - 1);
      }

      if (pageNum === 1 && pSize > totalPlans) {
        lastIndex = totalPlans;
        startIndex = 0;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        resultData.push(plansInfo[i]);
      }

      dispatch(
        setPagination({
          data: resultData,
          pageSize: pSize,
          totalRecordLength: totalPlans,
          pages: Math.ceil(totalPlans / pSize),
          page: pageNum,
          recordsPerApi: pSize,
        })
      );
    }
  };

  useEffect(() => {
    plansInfo.length && generateTableData(paginationState.page, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansInfo]);

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-left",
      Header: (
        <Checkbox
          id="toggle-all"
          label={renderBoldText(SIM_CONFIG_TABLE.PLAN_TYPE)}
          checked={selectedPlans?.length === plansInfo.length}
          onChange={() => handleToggleAll()}
        />
      ),
      resizable: false,
      width: 400,
      pinned: true,
      Cell: (rowData) => <PlanCheckbox item={rowData?.original} bold={true} />,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(SIM_CONFIG_TABLE.BARRING),
      resizable: false,
      minWidth: 200,
      Cell: (rowData) => (
        <Selections plan={rowData.original} field="barrings" />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(SIM_CONFIG_TABLE.ADD_ONS),
      resizable: false,
      minWidth: 250,
      Cell: (rowData) => <Selections plan={rowData.original} field="addOns" />,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(SIM_CONFIG_TABLE.ASSIGNED_PHONE_NUMBER.HEADING),
      resizable: false,
      minWidth: 245,
      pinned: true,
      Cell: (rowData) => {
        return <AssignedPhoneNumber planId={rowData.original.id} />;
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(SIM_CONFIG_TABLE.SIM_TYPE),
      resizable: false,
      minWidth: 300,
      Cell: (rowData) => {
        return (
          <Select
            id={`simType-${rowData?.original?.id}`}
            label=""
            options={configs?.SIM_TYPE_OPTIONS} // TODO test case check
            value={rowData?.original?.simType}
            invalid={rowData?.original?.invalid_SIM_type}
            events={{
              onChange: (e) => onChangeSimType(e, rowData),
            }}
            developmentUrl={ableDevelopmentUrl}
          />
        );
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER),
      resizable: false,
      minWidth: 270,
      Cell: (rowData) => {
        return (
          rowData?.original?.simType === "existing SIM Card" && (
            <SIMSerialNumber planId={rowData.original.id} />
          )
        );
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(SIM_CONFIG_TABLE.ADD_USERNAME),
      resizable: false,
      minWidth: 280,
      Cell: (rowData) => <AddUsername planId={rowData.original.id} />,
    },
  ];

  const onChangeSimType = (e, rowData) => {
    const planIndex = plansInfo.findIndex(
      (plan) => plan.id === rowData.original.id
    );
    const isPlanPresent = selectedPlans.indexOf(rowData.original.id) > -1;
    const selectedIndex = [];
    if (isPlanPresent) {
      for (const planId of selectedPlans) {
        const selectedPlanIndex = plansInfo.findIndex(
          (plan) => planId === plan.id
        );
        selectedIndex.push(selectedPlanIndex);
      }
    }
    const payload = {
      planIndexes: isPlanPresent ? selectedIndex : [planIndex],
      fields: {
        simSerialNumber: "",
        invalid_SIM_number: false,
        invalid_UNMS_SIM_number: false,
        invalid_SIM_type: false,
        simType: e.target.value,
        planUpdated: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  };

  return (
    <>
      {invalidSIM && (
        <MessageSection
          description={SIM_CONFIG_TABLE.ALL_SIM_SERIAL_NUMBER_VALIDATION}
          variant="Error"
          developmentUrl={ableDevelopmentUrl}
        />
      )}
      <SelectedPlansCount />
      <AdvanceTableV3
        data-testid="configTable"
        columns={columnData}
        data={paginationState.data}
        totalRecordLength={paginationState.totalRecordLength}
        pages={paginationState.pages}
        pageSize={paginationState.pageSize}
        defaultPageSize={paginationState.pageSize}
        isStickyHeader={true}
        pageHeaderClassName={"header"}
        sendApi={generateTableData}
        page={paginationState.page}
        getTrProps={(state, rowInfo, column, instance) => {
          const invalidRow =
            rowInfo.original.invalid_UNMS_SIM_number ||
            rowInfo.original.invalid_SIM_number ||
            rowInfo.original.invalid_username ||
            rowInfo.original.invalid_SIM_type;
          return invalidRow ? { className: "invalid-row" } : {};
        }}
      />
    </>
  );
};

export default SimConfigurationTable;
