// eslint-disable-next-line import/no-extraneous-dependencies
import ableIconUrl from "@able/web/dist/symbol/able-sprites.svg";
import { TextStyle } from "@able/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ICartState } from "@state/select-plans-slice";
import { useAppSelector } from "@state/hooks";
import { CUSTOM_ERRORS, ERRORS, PATHS } from "./common-static-data";
import {
  updateBillingAccount,
  updatePlansArrUsingIndex,
} from "@state/cart-slice";
import { setPagination } from "@state/sim-configuration-slice";
import { ERROR_TYPES } from "@pages/new-plan-order/constant";
import { setPhoenixNumber } from "@state/checkout-slice";
import {
  setError,
  setErrorMessage,
  setCheckoutError,
  setIsValidateRefNumberError,
  setSimConfigurationError,
} from "@state/error";
import { NEW_ORDER_DETAILS } from "@pages/new-order-details/constant";
import { CartItem, PatchCartParams } from "@state/types";
import {
  generatePatchCartOnCheckout,
  generatePatchCartOnSimConfiguration,
  generateSelectDevicesPatchCartBody,
  generateSelectPlansPatchCartBody,
} from "@services/templates/cart";
import { updateBackOrderStatus } from "@state/select-devices-slice";

export const ableDevelopmentUrl = ableIconUrl;
const { SIM_CONFIG_PATCH_CART, SIM_CONFIG_NUMBER_REFRESH } = ERROR_TYPES;

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const renderBoldText = (label: string) => {
  return (
    <div className="text-icon-wrapper">
      <TextStyle alias="Label">{label}</TextStyle>
    </div>
  );
};

export const renderLabel = (value: string) => {
  return <TextStyle alias="Label">{value}</TextStyle>;
};

export const renderText = (value: string) => {
  return <TextStyle>{value}</TextStyle>;
};

export const REGULAR_EXPRESSIONS = {
  NUMBERS_ONLY: /^[0-9\b]+$/,
  USERNAME: /^[0-9a-zA-Z'-]*$/,
  PHOENIX: /^[0-9]{8}$/,
};

export const isInternalReferenceNumberInvalid = (
  watchInternalReferenceNumber: any
) => {
  const pattern = /^TRN[0-9]{10}$|TNC[0-9]{9}$|GESPO[0-9]{9}$|TBAPO[0-9]{9}$/;
  const phoenixPattern = /^[0-9]{8}$/;
  if (
    !(
      (watchInternalReferenceNumber.length === 8 &&
        phoenixPattern.test(watchInternalReferenceNumber)) ||
      pattern.test(watchInternalReferenceNumber)
    )
  ) {
    return true;
  }
  return false;
};

export const useReloadHook = () => {
  useEffect(() => {
    const onUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

export const useGetFeatureFlagHook = () => {
  const featureFlags = useAppSelector(
    (state) => state.aemDynamicContent.featureFlags
  );
  return featureFlags;
};

export function buildPath(path: string, cartId: string) {
  return `${path}/${cartId}`;
}

export const dispatchRequiredBillingError = (dispatch, billingAccount) => {
  window.scrollTo({
    top: 180,
    behavior: "smooth",
  });
  dispatch(
    updateBillingAccount({
      billingAccount,
      invalidBillingAccount: false,
      requiredBillingError: true,
    })
  );
};

const getPatchCartAndNavigate = async (
  patchCart,
  requestObjForCheckout,
  navigate,
  cartId,
  dispatch
) => {
  const params: PatchCartParams = {
    payload: generatePatchCartOnCheckout(requestObjForCheckout),
    product: "all",
    tapasAction: "SO",
  };
  try {
    const patchCartRes = await patchCart(params).unwrap();
    if (patchCartRes.code === 200) {
      dispatch(setCheckoutError(null));
      dispatch(setError(false));
      navigate(buildPath(PATHS.ORDER_CONFIRMATION, cartId));
    }
  } catch (error: any) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let errorDescription = "";

    let status;
    if (error && "status" in error) {
      status = error?.status;
      errorDescription = ERRORS[status];
    }
    dispatch(
      setCheckoutError({
        errorObj: error,
        errorDescription,
      })
    );
    if (status && status !== 500) {
      dispatch(setError(true));
    }
  }
};
export async function checkoutSubmitHandler(
  internalReferenceNumber: string,
  getInternalReferenceNumber: Function,
  dispatch: Function,
  triggerValidateRefNumberQuery: Function,
  patchCart: Function,
  requestObjForCheckout,
  navigate: Function,
  cartId: string
) {
  if (!isInternalReferenceNumberInvalid(internalReferenceNumber)) {
    if (
      getInternalReferenceNumber() != internalReferenceNumber &&
      internalReferenceNumber.length === 8
    ) {
      dispatch(setPhoenixNumber(internalReferenceNumber));
      try {
        const res = await triggerValidateRefNumberQuery(
          internalReferenceNumber
        ).unwrap();
        if (res.isValid) {
          getPatchCartAndNavigate(
            patchCart,
            requestObjForCheckout,
            navigate,
            cartId,
            dispatch
          );
        } else {
          dispatch(setErrorMessage(NEW_ORDER_DETAILS.PHOENIX_ERROR));
          dispatch(setIsValidateRefNumberError(true));
        }
        dispatch(setCheckoutError(null));
        dispatch(setError(false));
      } catch (error: any) {
        dispatch(
          setCheckoutError({
            errorObj: error,
          })
        );
        dispatch(setError(true));
      }
    } else {
      getPatchCartAndNavigate(
        patchCart,
        requestObjForCheckout,
        navigate,
        cartId,
        dispatch
      );
    }
  } else if (internalReferenceNumber.length === 0) {
    dispatch(setIsValidateRefNumberError(true));
  }
}

export async function patchCartHandler(
  patchCart: Function,
  itemsInCart: ICartState,
  dispatch: Function,
  setCart: Function,
  navigate: Function,
  nextPagePath: string,
  cartId: string,
  billingAccount: string
) {
  const isFormInvalid =
    itemsInCart?.selectedOffers?.length <= 0 || billingAccount?.length <= 0;
  if (isFormInvalid) {
    if (itemsInCart?.selectedOffers?.length <= 0) {
      dispatch(setCart({ error: CUSTOM_ERRORS[1000] }));
      const selectedItemsDiv = document.getElementsByClassName(
        "selected-items-text"
      )[0];
      selectedItemsDiv?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    if (billingAccount?.length <= 0)
      dispatchRequiredBillingError(dispatch, billingAccount);
    return;
  } else {
    const params: PatchCartParams = {
      payload: generateSelectPlansPatchCartBody({
        ...itemsInCart,
        billingAccount,
        cartId,
      }),
      product: "plan",
    };
    try {
      const res = await patchCart(params).unwrap();
      if (res.code === 200) {
        dispatch(setCart({ error: null }));
        dispatch(setError(false));
        navigate(buildPath(nextPagePath, cartId));
      }
    } catch (error: any) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      let errorDescription = "";
      let errorCode: number;

      if ("status" in error) {
        const { status } = error;
        errorCode = status;
        errorDescription = ERRORS[status];
      }

      dispatch(
        setCart({
          error: {
            errorCode,
            errorDescription,
          },
        })
      );
      dispatch(setError(true));
    }
  }
}

export const prefixWithDollarSign = (value) => {
  return "$" + value;
};

interface ISIM {
  [simNumber: string]: number[];
}

const invalidSimSerialData = (res, dispatch, plansInfo) => {
  const errors = res.errors;
  if (errors && errors.length) {
    errors.forEach((e) => {
      if (e.invalidSimSerialNumbers.length) {
        const invalidPlanIndexes = [];
        const invalidSet = new Set(e.invalidSimSerialNumbers);
        plansInfo.forEach((plan, index) => {
          if (invalidSet.has(plan.simSerialNumber)) {
            invalidPlanIndexes.push(index);
          }
        });
        if (invalidPlanIndexes.length) {
          const payload = {
            planIndexes: invalidPlanIndexes,
            fields: {
              invalid_UNMS_SIM_number: true,
            },
          };
          dispatch(updatePlansArrUsingIndex(payload));
        }
      }
    });
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
    dispatch(updatePlansArrUsingIndex({ sort: true }));
  }
};

const setPageOneAndNavigate = async (
  navigation,
  dispatch,
  navigate,
  paginationState,
  plansInfo,
  requestObjForSimConfiguration,
  patchCart,
  cartId
) => {
  if (paginationState.page !== 1) {
    await dispatch(
      setPagination({
        data: plansInfo.slice(0, paginationState.pageSize),
        pageSize: paginationState.pageSize,
        totalRecordLength: plansInfo.length,
        pages: Math.ceil(plansInfo.length / paginationState.pageSize),
        page: 1,
        recordsPerApi: paginationState.pageSize,
      })
    );
  }
  if (navigation) {
    const changedPlans = requestObjForSimConfiguration.plansInfo.filter(
      (plan) => plan.planUpdated === true
    );
    if (changedPlans.length > 0) {
      const params: PatchCartParams = {
        payload: generatePatchCartOnSimConfiguration(
          requestObjForSimConfiguration
        ),
        product: "plan",
        tapasAction: "VS",
      };
      try {
        const res = await patchCart(params).unwrap();
        if (res.code === 200) {
          if (res.responseCode === "PARTIAL_SUCCESS") {
            invalidSimSerialData(res, dispatch, plansInfo);
          } else navigate(buildPath(PATHS.CART, cartId));
        }
      } catch (err: any) {
        const { status } = err;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        dispatch(
          setSimConfigurationError({
            errorType: SIM_CONFIG_PATCH_CART,
            errorCode: status,
            errorDescription: ERRORS[status],
          })
        );
      }
    } else navigate(buildPath(PATHS.CART, cartId));
  }
};

export const simConfigPatchCartHandler = (
  dispatch,
  navigate,
  plansInfo,
  paginationState,
  requestObjForSimConfiguration,
  patchCart,
  cartId
) => {
  const invalidSIMPlans = [];
  const invalidSIMTypePlans = [];
  const SIMSerialNumbers: ISIM = {};
  let duplicatesSIMNumbers = [];
  let nonDuplicateSIMNumbers = [];
  plansInfo.forEach((plan, index) => {
    if (plan.simType === "") {
      invalidSIMTypePlans.push(index);
    } else if (
      plan.simType !== undefined &&
      plan.simType !== "SIM Card" &&
      plan.simType !== "eSIM" &&
      plan.simType !== "" &&
      plan.simSerialNumber === ""
    ) {
      invalidSIMPlans.push(index);
    } else if (plan.simSerialNumber.length === 13) {
      if (!SIMSerialNumbers[plan.simSerialNumber]) {
        SIMSerialNumbers[plan.simSerialNumber] = [];
      }
      SIMSerialNumbers[plan.simSerialNumber].push(index);
    }
  });

  if (Object.keys(SIMSerialNumbers).length) {
    Object.values(SIMSerialNumbers).forEach((SIMsIndex) => {
      if (SIMsIndex.length > 1) {
        duplicatesSIMNumbers = [...duplicatesSIMNumbers, ...SIMsIndex];
      } else {
        nonDuplicateSIMNumbers = [...nonDuplicateSIMNumbers, ...SIMsIndex];
      }
    });
  }

  if (invalidSIMTypePlans.length) {
    const payload = {
      planIndexes: invalidSIMTypePlans,
      fields: {
        invalid_SIM_type: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  }

  if (invalidSIMPlans.length || duplicatesSIMNumbers.length) {
    const invalidSIMs = invalidSIMPlans.concat(duplicatesSIMNumbers);
    const payload = {
      planIndexes: invalidSIMs,
      fields: {
        invalid_SIM_number: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  }

  if (nonDuplicateSIMNumbers.length) {
    const payload = {
      planIndexes: nonDuplicateSIMNumbers,
      fields: {
        invalid_SIM_number: false,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  }

  const invalidSimNumbers = plansInfo.some(
    (plan) =>
      plan.invalid_UNMS_SIM_number ||
      (plan.invalid_SIM_number && plan.simSerialNumber?.length !== 13) ||
      plan.invalid_username ||
      plan.invalid_SIM_type
  );

  if (
    invalidSimNumbers ||
    invalidSIMPlans.length ||
    invalidSIMTypePlans.length ||
    duplicatesSIMNumbers.length
  ) {
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
    dispatch(updatePlansArrUsingIndex({ sort: true }));
    setPageOneAndNavigate(
      false,
      dispatch,
      navigate,
      paginationState,
      plansInfo,
      requestObjForSimConfiguration,
      patchCart,
      cartId
    );
  } else {
    setPageOneAndNavigate(
      true,
      dispatch,
      navigate,
      paginationState,
      plansInfo,
      requestObjForSimConfiguration,
      patchCart,
      cartId
    );
  }
};

export async function refreshPhoneNumber(
  dispatch,
  setPlanId,
  planId,
  refreshNumber,
  plan,
  planIndex,
  SIM_CONFIG_ERRORS
) {
  const params = {
    currentNumber: plan.phoneNumber,
    refreshCount: plan.refreshCount || 0,
    planIndex,
  };

  try {
    await refreshNumber(params).unwrap();
    dispatch(setSimConfigurationError(null));
  } catch (err: any) {
    const { status } = err;
    dispatch(
      setSimConfigurationError({
        errorType: SIM_CONFIG_NUMBER_REFRESH,
        errorCode: status,
        errorDescription: SIM_CONFIG_ERRORS[status]?.ASSIGN_NUMBERS,
      })
    );
    dispatch(setPlanId(planId));
  }
}

export const changeColorCase = (color) => {
  if (color) {
    return `${color[0]}${color.substring(1).toLowerCase()}`;
  }
  return "";
};

export const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) observer.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isIntersecting;
};

export async function configureDevicesPagePatchCartHandler({
  cartId,
  deltaPlans,
  patchCart,
  setCart,
  navigate,
  dispatch,
  normalizedDevicesAndAccesories,
  setRequiredForPaymentType,
}) {
  const deviceIds = [];
  normalizedDevicesAndAccesories.forEach((item) => {
    if (item.paymentType === "") {
      deviceIds.push(item.id);
    }
  });
  if (deviceIds.length > 0) {
    dispatch(setRequiredForPaymentType(deviceIds));
  } else {
    const cartItems: CartItem[] = Object.values(deltaPlans);
    if (cartItems.length) {
      const params: PatchCartParams = {
        product: "device",
        payload: {
          cartId,
          cartItems,
        },
        resetDelta: true,
      };
      try {
        const res = await patchCart(params).unwrap();
        if (res.code === 200) {
          dispatch(setCart({ error: null }));
          dispatch(setError(false));
          navigate(buildPath(PATHS.CART, cartId));
        }
      } catch (error: any) {
        if (error.status === 500) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          dispatch(setError(false));
        } else {
          dispatch(setError(true));
        }
        dispatch(setCart({ error }));
      }
    } else {
      navigate(buildPath(PATHS.CART, cartId));
    }
  }
}

export async function cartPagePatchCartHandler({
  cartId,
  deltaPlans,
  patchCart,
  navigate,
  dispatch,
  setCart,
  product,
}) {
  const cartItems: CartItem[] = Object.values(deltaPlans);
  if (cartItems.length) {
    const params: PatchCartParams = {
      product,
      payload: { cartId, cartItems },
      resetDelta: true,
    };

    try {
      const res = await patchCart(params).unwrap();
      if (res.code === 200) {
        dispatch(setCart({ error: null }));
        dispatch(setError(false));
        navigate(buildPath(PATHS.CHECKOUT, cartId));
      }
    } catch (error: any) {
      const errorCode = error.status;
      let errorDescription = "";

      if (errorCode === 500) {
        errorDescription = ERRORS[errorCode];
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        dispatch(setError(false));
      } else {
        dispatch(setError(true));
      }
      dispatch(
        setCart({
          error: {
            errorCode,
            errorDescription,
          },
        })
      );
    }
  } else navigate(buildPath(PATHS.CHECKOUT, cartId));
}

export async function selectDevicesPatchCartHandler({
  cartId,
  billingAccount,
  selectedDevices,
  itemsInCart,
  backOrder,
  setCart,
  dispatch,
  patchCart,
  reduxStore,
  navigate,
}) {
  const isFormInvalid =
    billingAccount?.length <= 0 || selectedDevices.length <= 0;
  if (isFormInvalid) {
    if (selectedDevices.length <= 0) {
      dispatch(setCart({ error: CUSTOM_ERRORS[1000] }));
      const errorMessageElement = document.querySelector(".add-devices-error");
      errorMessageElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    if (billingAccount?.length <= 0) {
      dispatchRequiredBillingError(dispatch, itemsInCart);
    }
  } else {
    const backorders = selectedDevices.filter(
      (el) => el.stockStatus === "OUT_OF_STOCK"
    );
    if (backorders.length > 0 && !backOrder) {
      dispatch(updateBackOrderStatus(true));
    } else {
      try {
        const res = await patchCart({
          product: "device",
          payload: generateSelectDevicesPatchCartBody(reduxStore),
        }).unwrap();
        if (res.code === 200) {
          navigate(buildPath(PATHS.CONFIGURE_DEVICES, cartId));
        }
      } catch (error: any) {
        if (error && "status" in error && error.status === 500) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          dispatch(setError(false));
        } else {
          dispatch(setError(true));
        }
        dispatch(
          setCart({
            error,
          })
        );
      }
    }
  }
}
