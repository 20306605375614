import {
  ModalContent,
  ActionButton,
  RadioGroup,
  RadioButton,
  TextArea,
  Spacing,
} from "@able/react";
import { useLazyPatchCartCancelDraftOrderQuery } from "@services/cart";
import {
  setCancellationReason,
  setDefaultState,
  setOtherReason,
  setRequiredReason,
  setShowTextBox,
} from "@state/common-slice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  CANCEL_DRAFT_ORDER_CANCELLATION_REASONS,
  CANCEL_DRAFT_ORDER,
} from "@utils/common-static-data";
import { ableDevelopmentUrl } from "@utils/helper";
import { useNavigate, useParams } from "react-router-dom";

const BodyContent = (props) => {
  const dispatch = useAppDispatch();
  const { cancellationReason, otherReason, requiredReason, showTextBox } =
    useAppSelector((state) => state.commonStore);
  const radioButtonEvent = {
    onChange: (e) => {
      dispatch(setCancellationReason(e.target.value));
      if (e.target.value === CANCEL_DRAFT_ORDER.OTHER) {
        dispatch(setShowTextBox(true));
        dispatch(setRequiredReason(false));
      } else dispatch(setShowTextBox(false));
    },
  };
  const otherReasonTextAreaEvents = {
    onChange: (e) => {
      dispatch(setOtherReason(e.target.value));
      if (otherReason.length === 0) dispatch(setRequiredReason(false));
    },
  };

  return (
    <>
      <Spacing bottom="spacing10x">
        <RadioGroup
          groupLabel={CANCEL_DRAFT_ORDER.CANCELLATION_REASON_GROUP_LABEL}
          name="cancellation-radiogroup"
          variant="Default"
        >
          {CANCEL_DRAFT_ORDER_CANCELLATION_REASONS.map(
            (cancellationReasonItem) => (
              <RadioButton
                value={cancellationReasonItem.LABEL}
                label={cancellationReasonItem.LABEL}
                key={cancellationReasonItem.ID}
                id={cancellationReasonItem.ID}
                checked={cancellationReasonItem.LABEL === cancellationReason}
                events={radioButtonEvent}
              />
            )
          )}
        </RadioGroup>

        {showTextBox && (
          <Spacing top="spacing3x">
            <TextArea
              developmentUrl={ableDevelopmentUrl}
              invalid={requiredReason}
              required={true}
              label={CANCEL_DRAFT_ORDER.OTHER_REASON_TEXT_AREA}
              size="Long"
              name="otherReasons"
              value={otherReason}
              events={otherReasonTextAreaEvents}
            />
          </Spacing>
        )}
      </Spacing>
    </>
  );
};
const FooterContent = (props) => {
  const { toggleModal } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cartId } = useParams();
  const [cancelDraftOrder] = useLazyPatchCartCancelDraftOrderQuery();
  const { cancellationReason, otherReason } = useAppSelector(
    (state) => state.commonStore
  );

  const cancelHandler = () => {
    toggleModal();
    dispatch(setDefaultState());
  };

  const cancelEvent = {
    onClick: () => {
      cancelHandler();
    },
  };

  const callCancelOrderAPI = (id, reason) => {
    cancelDraftOrder({ cartId: id, cancellationReason: reason })
      .unwrap()
      .then((data) => {
        if (data.code === 200) {
          cancelHandler();
          navigate("/");
        }
      });
  };

  const cancelOrderEvent = {
    onClick: () => {
      if (
        cancellationReason === CANCEL_DRAFT_ORDER.OTHER &&
        otherReason.length === 0
      )
        dispatch(setRequiredReason(true));
      else if (
        cancellationReason === CANCEL_DRAFT_ORDER.OTHER &&
        otherReason.length > 0
      )
        callCancelOrderAPI(cartId, otherReason);
      else callCancelOrderAPI(cartId, cancellationReason);
    },
  };
  return (
    <>
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        label={CANCEL_DRAFT_ORDER.CANCEL_BUTTON}
        variant="MediumEmphasis"
        events={cancelEvent}
      />
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        label={CANCEL_DRAFT_ORDER.CANCEL_DRAFT_ORDER_BUTTON}
        variant="HighEmphasisDestructive"
        events={cancelOrderEvent}
      />
    </>
  );
};

const CancelDraftOrder = (props) => {
  const { modalIsOpen, toggleModal } = props;
  const dispatch = useAppDispatch();
  return (
    <ModalContent
      developmentUrl={ableDevelopmentUrl}
      title={CANCEL_DRAFT_ORDER.MODAL_TITLE}
      variant="Comfortable"
      onClose={() => {
        dispatch(setDefaultState());
      }}
      bodyContent={<BodyContent />}
      footerContent={<FooterContent toggleModal={toggleModal} />}
      isShowing={modalIsOpen}
      setHideDialog={toggleModal}
    />
  );
};

export default CancelDraftOrder;
