import { createSlice } from "@reduxjs/toolkit";
import { resetAction } from "./reset";

const initialState = {
  internalReferenceNumberError: "",
  phoenixNumber: "",
  internalReferenceNumber: "",
  orderReference: "",
  deliveryAddress: {
    id: "",
    value: "",
  },
  deliveryAddressRequired: false,
  contactDetails: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  contactDetailsRequired: false,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    updateInternalReferenceNumber: (state, action) => {
      state.internalReferenceNumber = action.payload;
    },
    updateOrderReference: (state, action) => {
      state.orderReference = action.payload;
    },
    setPhoenixNumber: (state, action) => {
      state.phoenixNumber = action.payload;
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    setDeliveryAddressRequired: (state, action) => {
      state.deliveryAddressRequired = action.payload;
    },
    setContactDetails: (state, action) => {
      let data;
      if (action.payload) data = action.payload;
      else data = initialState.contactDetails;
      state.contactDetails = data;
    },
    setContactDetailsRequired: (state, action) => {
      state.contactDetailsRequired = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  updateInternalReferenceNumber,
  updateOrderReference,
  setPhoenixNumber,
  setDeliveryAddress,
  setDeliveryAddressRequired,
  setContactDetails,
  setContactDetailsRequired,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
