import { configureStore } from "@reduxjs/toolkit";
import { baseApiService, AEMDynamicContentAPI } from "@services/base";
import newOrderReducer from "./new-order-slice";
import selectPlansReducer from "./select-plans-slice";
import simConfigurationReducer from "./sim-configuration-slice";
import commonReducer from "./common-slice";
import cartReducer from "./cart-slice";
import loggedInCIDNReducer from "./logged-in-cidn-slice";
import aemDynamicContentReducer from "./aem-dynamic-content-slice";
import checkoutReducer from "./checkout-slice";
import errorReducer from "./error";
import selectDevicesReducer from "./select-devices-slice";

export const reducers = {
  error: errorReducer,
  newOrder: newOrderReducer,
  selectPlans: selectPlansReducer,
  simConfiguration: simConfigurationReducer,
  commonStore: commonReducer,
  cart: cartReducer,
  loggedInCIDN: loggedInCIDNReducer,
  checkout: checkoutReducer,
  selectDevices: selectDevicesReducer,
  [baseApiService.reducerPath]: baseApiService.reducer,
  [AEMDynamicContentAPI.reducerPath]: AEMDynamicContentAPI.reducer,
  aemDynamicContent: aemDynamicContentReducer,
};

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: reducers,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(baseApiService.middleware)
        .concat(AEMDynamicContentAPI.middleware),
  });
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApiService.middleware)
      .concat(AEMDynamicContentAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
