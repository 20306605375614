import "./style.scss";
import {
  ActionButton,
  Column,
  IconButton,
  Spacing,
  Surface,
  Grid,
  TextStyle,
} from "@able/react";
import { CONFIGURE_BARRINGS_AND_ADDONS } from "@pages/new-plan-order/steps/configure-plan-barring-add-ons/constant";
import { ableDevelopmentUrl } from "@utils/helper";
import { QuantityInput } from "telstra-ui/components/quantity/quantity";
import Selections from "../../selections";

const ConfigurePlansListSurface = ({ plan }) => {
  return (
    <Grid>
      <Column cols={12}>
        <Surface
          background="materialBasePrimary"
          cornerRadius="cornerRadiusSlight"
          variant="SurfaceSlight"
          key={plan.id}
        >
          <>
            <div className="listViewSurface">
              <Spacing
                bottom="spacing1nHalf"
                left="spacing4x"
                right="spacing4x"
                top="spacing1nHalf"
              >
                <Grid className="planHeader">
                  <Column cols={7}>
                    <div tabIndex={0}>
                      <TextStyle alias="HeadingD" element="h2">
                        {`${plan.planName}`}
                      </TextStyle>
                    </div>
                  </Column>
                  <Column cols={5}>
                    <div className="headingSectionButtons">
                      <QuantityInput
                        className="quantity-button"
                        id={plan.id}
                        value={plan.quantity}
                        min={0}
                        max={100}
                        enable={true}
                        step={1}
                      />
                      <ActionButton
                        element="button"
                        label={CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW.SPLIT}
                        icon="Directions"
                        variant="LowEmphasis"
                        developmentUrl={ableDevelopmentUrl}
                      />
                      <IconButton
                        icon="Calendar"
                        screenReaderContent={
                          CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                            .REMOVE_PLAN_BUTTON
                        }
                        variant="Destructive"
                        developmentUrl={ableDevelopmentUrl}
                        size="Big"
                      />
                    </div>
                  </Column>
                </Grid>
              </Spacing>
            </div>
            <Spacing
              bottom="spacing3x"
              left="spacing4x"
              right="spacing4x"
              top="spacing3x"
            >
              <Grid>
                <div className="able-column cols-8" tabIndex={0}>
                  <Grid>
                    <Column cols={6}>
                      <TextStyle alias="Label" element="h3">
                        {
                          CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                            .NETWORK_FEATURE_HEADER
                        }
                      </TextStyle>
                      <Selections plan={plan} field="barrings" />
                    </Column>
                    <Column cols={6}>
                      <TextStyle alias="Label" element="h3">
                        {CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW.ADDON_HEADER}
                      </TextStyle>
                      <Selections plan={plan} field="addOns" />
                    </Column>
                  </Grid>
                </div>
                <Column cols={4}>
                  <div className="configureBtn">
                    <ActionButton
                      element="button"
                      label={
                        CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                          .CONFIGURE_PLANS_BUTTON
                      }
                      icon="ActivateService"
                      variant="MediumEmphasis"
                      className="configureBtn"
                    />
                  </div>
                </Column>
              </Grid>
            </Spacing>
          </>
        </Surface>
      </Column>
    </Grid>
  );
};

export default ConfigurePlansListSurface;
