/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Spacing, Column } from "@able/react";
import { useCommonGetCartQuery, useGetCartQuery } from "@services/cart";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleAll } from "@state/common-slice";
import { useEffect, useState } from "react";
import PlansByCategory from "./plans-by-category";
import BillingAccount from "@components/billing-account";
import NewPlansByCategory from "./new-plans-by-category";
import { useParams } from "react-router-dom";
import { useGetFeatureFlagHook } from "@utils/helper";
import { useShouldEnableGetCartHook } from "@utils/cart";
import ErrorScreen from "@components/error-screen";

const ConfigurePlanBarringAddOns = () => {
  const dispatch = useAppDispatch();
  const { cartId } = useParams();
  const customError = useAppSelector((state) => state.error.isError);
  const [billingError, setBillingError] = useState(null);
  const { enableNewGetCartForBarringsAndAddOns } = useGetFeatureFlagHook();

  const { isFetching, isError, isSuccess } = useGetCartQuery("cartId", {
    skip: enableNewGetCartForBarringsAndAddOns,
  });
  const skipGetCart = useShouldEnableGetCartHook();

  useCommonGetCartQuery({ cartId }, { skip: !skipGetCart });
  useEffect(() => {
    return () => {
      dispatch(toggleAll([]));
    };
  }, []);

  const catchBillingError = (error) => {
    setBillingError(error);
  };

  if (customError && billingError) {
    return <ErrorScreen error={billingError} />;
  }

  return (
    <Grid>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <Spacing top="spacing10x">
          <BillingAccount catchBillingError={catchBillingError} />
        </Spacing>
      </Column>
      {isFetching && <div>Loading...</div>}
      {isError && <div>Error</div>}
      {enableNewGetCartForBarringsAndAddOns && <NewPlansByCategory />}
      {!enableNewGetCartForBarringsAndAddOns && isSuccess && (
        <PlansByCategory />
      )}
    </Grid>
  );
};

export default ConfigurePlanBarringAddOns;
