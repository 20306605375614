import "./style.scss";
import { useState } from "react";
import { ActionButton, FilterToggle } from "@able/react";
import { updateSelectedDeviceFilters } from "@state/common-slice";
import { useAppDispatch } from "@state/hooks";
import { DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { ableDevelopmentUrl } from "@utils/helper";

const DeviceFilters = (props) => {
  const dispatch = useAppDispatch();
  const defaultFilter = {
    id: "",
    dataId: "",
    selected: false,
  };
  let categoryFilters = "";
  for (const offer of props.offers) {
    if (offer.name === "Mobile") {
      categoryFilters = offer.filter;
    }
  }
  const initialFilter = [];
  if (categoryFilters) {
    for (const offer of categoryFilters) {
      initialFilter.push({
        ...defaultFilter,
        id: offer,
        dataId: offer,
      });
    }
  }
  const filterState = {
    pills: initialFilter,
    selectedIds: [],
  };
  const [filters, setFilters] = useState(filterState);
  const onClickItem = (item) => {
    const { pills, selectedIds } = filters;
    let selectedIndex = "";
    selectedIndex = pills.findIndex((obj) => obj.id == item.filterIdentifier);
    pills[selectedIndex].selected = !pills[selectedIndex].selected;
    if (pills[selectedIndex].selected) {
      selectedIds.push({ id: item.filterIdentifier });
    } else {
      selectedIds.splice(
        selectedIds.findIndex((obj) => obj.id === item.filterIdentifier),
        1
      );
    }
    setFilters({ ...filters, pills });
    dispatch(updateSelectedDeviceFilters(selectedIds));
  };
  const onClearFilterClick = () => {
    setFilters(filterState);
    dispatch(updateSelectedDeviceFilters([]));
  };
  return (
    <>
      <div className="filter-column">
        <FilterToggle
          filters={filters.pills}
          onAbleFilterTogglePressed={onClickItem}
        />
      </div>
      {filters.selectedIds.length > 0 && (
        <div className="filter-column">
          <ActionButton
            developmentUrl={ableDevelopmentUrl}
            element="button"
            label={DEVICES_AND_ACCESSORIES.CLEAR_FILTER_LABEL}
            variant="LowEmphasis"
            events={{
              onClick: onClearFilterClick,
            }}
          />
        </div>
      )}
    </>
  );
};
export default DeviceFilters;
