export const CHECKOUT = {
  TITLE: "Checkout",
  YOUR_ITEMS: "Charge details",
  PLANS: "Plans",
  DEVICES: "Device and accessories",
  CHARGES: "Charges",
  CUSTOMER_DETAILS: {
    TITLE: "Customer details",
    LABEL: {
      NAME: "Customer name",
      CIDN: "Customer identification (CIDN)",
      BILLING_ACCOUNT: "Billing account",
      REQUESTER: "Requester",
      TELSTRA_REFERENCE_NUMBER: "Telstra reference number",
      ORDER_REFERENCE: "Order reference",
    },
  },
  DELIVERY_DETAILS: {
    TITLE: "Delivery details",
    LABEL: {
      DELIVERY_ADDRESS: "Delivery address",
      DELIVERY_ADDRESS_SUBTEXT:
        "Select from saved addresses or add a new address.",
      DELIVERY_ADDRESS_ADD_NEW: "Start by adding a new delivery address",
      ADD_NEW_ADDRESS: "Add a delivery address",
      DELIVER_TO: "Deliver to",
      ADD_ADDRESS: "Add a delivery address",
      ADDRESS_NOT_RIGHT: "Address not right?",
      CONTACT_DETAILS: "Delivery contact",
      CONTACT_DETAILS_SUBTEXT:
        "Select from saved contacts or add a new contact.",
      CONTACT_DETAILS_ADD_NEW: "Start by adding a new delivery contact",
      ADD_NEW_CONTACT: "Add a delivery contact",
      CONTACT_NOT_RIGHT: "Contact not right?",
      GIVEN_NAME: "Given name",
      FAMILY_NAME: "Family name",
      EMAIL: "Email address",
      MOBILE_NUMBER: "Mobile number",
      ADD_CONTACT: "Add a delivery contact",
      CUSTOMER_REQ_DATE: "Customer required date",
    },
    REQUIRED_FIELD: "Required field.",
  },
};
