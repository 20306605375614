import "./style.scss";
import { TextField } from "@able/react";
import { ableDevelopmentUrl, REGULAR_EXPRESSIONS } from "@utils/helper";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { updatePlansArrUsingIndex } from "@state/cart-slice";

export default function SIMSerialNumber({ planId }) {
  const dispatch = useAppDispatch();
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlans);
  const planIndex = plansInfo.findIndex((plan) => plan.id === planId);

  const checkSIMValidation = () => {
    const SNValue = plansInfo[planIndex].simSerialNumber;
    let invalidSIM;
    if (SNValue === "") {
      invalidSIM = plansInfo[planIndex].invalid_SIM_number || false;
    } else if (SNValue.length !== 13) {
      invalidSIM = true;
    } else {
      invalidSIM = false;
    }
    const payload = {
      planIndexes: [planIndex],
      fields: {
        invalid_SIM_number: invalidSIM,
        planUpdated: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  };
  const updateSIMNumber = (e) => {
    const payload = {
      planIndexes: [planIndex],
      fields: {
        simSerialNumber: e.target.value,
        invalid_SIM_number: false,
        invalid_UNMS_SIM_number: false,
        planUpdated: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  };

  const getErrorMessage = () => {
    const plan = plansInfo[planIndex];
    return (
      (plan.invalid_SIM_number &&
        plan.simSerialNumber.length > 0 &&
        plan.simSerialNumber.length != 13 &&
        SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER_13DIGIT_ERROR) ||
      (plan.invalid_UNMS_SIM_number &&
        SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER_INVALID_UNMS) ||
      (plan.invalid_SIM_number &&
        plan.simSerialNumber.length === 13 &&
        SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER_DUPLICATE)
    );
  };

  return (
    <>
      <TextField
        key={"simSerialNumber" + planId}
        id={"simSerialNumber" + planId}
        name={"simSerialNumber" + planId}
        developmentUrl={ableDevelopmentUrl}
        className="sim-serial-number"
        invalid={
          plansInfo[planIndex].invalid_SIM_number ||
          plansInfo[planIndex].invalid_UNMS_SIM_number
        }
        invalidInputText={getErrorMessage()}
        value={plansInfo[planIndex].simSerialNumber}
        events={{
          onBlur: () => {
            checkSIMValidation();
          },
          onChange: (e) => {
            if (
              e.target.value === "" ||
              REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(e.target.value)
            ) {
              updateSIMNumber(e);
            }
          },
        }}
      />
    </>
  );
}
