import { Spacing } from "@able/react";
import ConfigurePlansListSurface from "./list-surface";

const ConfigurePlansListView = (props) => {
  const { plansByCategoryActiveCategory } = props;
  return (
    <>
      {plansByCategoryActiveCategory.map((plan) => (
        <Spacing bottom="spacing3x" key={plan.id}>
          <ConfigurePlansListSurface plan={plan} />
        </Spacing>
      ))}
    </>
  );
};

export default ConfigurePlansListView;
