/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "@able/react";
import { useLocation } from "react-router-dom";
import BILLING_ACCOUNT_CONTSTANTS from "./constants";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { useGetBillingAccountsQuery } from "@services/base";
import { ableDevelopmentUrl } from "@utils/helper";
import { useEffect } from "react";
import { setError } from "@state/error";
import { PATHS } from "@utils/common-static-data";
import { setCart, updateBillingAccount } from "@state/cart-slice";

const mapDropdownOptions = (data) => {
  const res = data.billingAccounts.map((account) => {
    return {
      label: account.billingAccountNumber,
      value: account.billingAccountNumber,
    };
  });
  return res;
};

const BillingAccount = ({ catchBillingError }) => {
  const { pathname } = useLocation();
  const {
    data: billingAccountsRes,
    isError: billingAccountsError,
    isLoading: billingAccountsLoading,
    isFetching,
    error,
  } = useGetBillingAccountsQuery();
  const dispatch = useAppDispatch();
  const { billingAccount, invalidBillingAccount, requiredBillingError } =
    useAppSelector((state) => state.cart);
  const selectBillingAccount = (event) => {
    dispatch(
      updateBillingAccount({
        billingAccount: event?.target?.value,
        invalidBillingAccount: false,
        requiredBillingError: false,
      })
    );
  };

  useEffect(() => {
    if (billingAccountsRes?.billingAccounts?.length === 1) {
      dispatch(
        updateBillingAccount({
          billingAccount:
            billingAccountsRes?.billingAccounts[0].billingAccountNumber,
          invalidBillingAccount: false,
        })
      );
    } else if (billingAccountsRes?.billingAccounts?.length === 0) {
      dispatch(
        updateBillingAccount({
          invalidBillingAccount: true,
        })
      );
    }
  }, [billingAccountsRes]);

  useEffect(() => {
    if (billingAccountsError && !isFetching && !billingAccountsLoading) {
      catchBillingError(error);
      dispatch(setError(true));
      dispatch(setCart({ error }));
    } else {
      dispatch(setError(false));
    }
  }, [billingAccountsError, isFetching, billingAccountsLoading]);

  const getBillingAccountErrorMessage = () => {
    return (
      (invalidBillingAccount &&
        BILLING_ACCOUNT_CONTSTANTS.NO_BILLING_ACCOUNT_MESSAGE) ||
      BILLING_ACCOUNT_CONTSTANTS.BILLING_ACCOUNT_REQUIRED_TEXT
    );
  };

  return (
    <Select
      developmentUrl={ableDevelopmentUrl}
      events={{
        onChange: selectBillingAccount,
      }}
      helpText={
        pathname.includes(PATHS.SELECT_DEVICES)
          ? BILLING_ACCOUNT_CONTSTANTS.BILLING_ACCOUNT_SELECT_DEVICE_ASSISTIVE_TEXT
          : BILLING_ACCOUNT_CONTSTANTS.BILLING_ACCOUNT_ASSISTIVE_TEXT
      }
      id="billing-account"
      label={BILLING_ACCOUNT_CONTSTANTS.BILLING_ACCOUNT}
      options={
        billingAccountsLoading || billingAccountsError
          ? []
          : mapDropdownOptions(billingAccountsRes)
      }
      value={billingAccount}
      required={true}
      invalid={requiredBillingError || invalidBillingAccount}
      invalidMessage={getBillingAccountErrorMessage()}
      size="Long"
    />
  );
};

export default BillingAccount;
