import "./style.scss";
import { Breadcrumbs } from "@able/react";
import { useLocation, useParams } from "react-router-dom";
import { BREADCRUMB, PATHS } from "@utils/common-static-data";
import { ableDevelopmentUrl, buildPath } from "@utils/helper";
import { useAppSelector } from "@state/hooks";

export default function Breadcrumb() {
  const { cartId } = useParams();
  const { pathname } = useLocation();
  const isError = useAppSelector((state) => state.error.isError);
  const { normalizedPlans, normalizedDevicesAndAccesories } = useAppSelector(
    (state) => state.cart
  );
  const isPlansFlow = normalizedPlans && normalizedPlans.length > 0;
  const isDevicesFlow =
    normalizedDevicesAndAccesories && normalizedDevicesAndAccesories.length > 0;

  if (pathname.includes(PATHS.ORDER_CONFIRMATION) || isError) return <></>;

  const commonInitialBreadcrumbs = [
    { to: PATHS.LANDING_PAGE, label: BREADCRUMB.ACCOUNT }, // TODO change the url
    { to: PATHS.LANDING_PAGE, label: BREADCRUMB.LANDING_PAGE },
    {
      to: PATHS.NEW_ORDER_DETAILS,
      label: BREADCRUMB.NEW_ORDER_DETAILS,
    },
  ];

  const commonFinalBreadcrumbs = [
    {
      to: buildPath(PATHS.CART, cartId),
      label: BREADCRUMB.CART_PAGE,
    },
    {
      to: buildPath(PATHS.CHECKOUT, cartId),
      label: BREADCRUMB.CHECKOUT,
    },
  ];

  const newDevicesBreadcrumbs = [
    ...commonInitialBreadcrumbs,
    {
      to: buildPath(PATHS.SELECT_DEVICES, cartId),
      label: BREADCRUMB.SELECT_DEVICES,
    },
    {
      to: buildPath(PATHS.CONFIGURE_DEVICES, cartId),
      label: BREADCRUMB.CONFIGURE_DEVICES,
    },

    ...commonFinalBreadcrumbs,
  ];

  const newPlanBreadcrumbs = [
    ...commonInitialBreadcrumbs,
    {
      to: buildPath(PATHS.SELECT_PLAN, cartId),
      label: BREADCRUMB.SELECT_PLAN,
    },
    {
      to: buildPath(PATHS.BARRING_ADDONS, cartId),
      label: BREADCRUMB.BARRING_ADDONS,
    },
    {
      to: buildPath(PATHS.CONFIGURE_SIM, cartId),
      label: BREADCRUMB.CONFIGURE_SIM,
    },

    ...commonFinalBreadcrumbs,
  ];

  const dynamicBreadcrumbs = [];
  let i = 0;
  let staticBreadcrumbs;

  if (isPlansFlow) {
    staticBreadcrumbs = newPlanBreadcrumbs;
  } else if (isDevicesFlow) {
    staticBreadcrumbs = newDevicesBreadcrumbs;
  } else staticBreadcrumbs = commonInitialBreadcrumbs;

  while (i < staticBreadcrumbs.length && pathname !== staticBreadcrumbs[i].to) {
    dynamicBreadcrumbs.push(staticBreadcrumbs[i]);
    i++;
  }

  return (
    <Breadcrumbs
      data-testid="breadcrumb"
      developmentUrl={ableDevelopmentUrl}
      breadcrumbs={dynamicBreadcrumbs}
      setBackgroundToWhite
      className={`no-animation ${pathname === "/" ? "landing-page-style" : ""}`}
    />
  );
}
