/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import { useEffect, useState } from "react";
import { TabList, Tab } from "telstra-ui/components/tab/Tabs";
import PlansTableView from "./components/table-view";
import BarringSection from "./components/barring";
import AddOnsSection from "./components/add-ons";
import { MessageSection } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { useAppSelector } from "@state/hooks";

const PlansBySubcategory = ({ plans, category }) => {
  const CONSTANTS = NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS;
  const plansBySubCategory = new Map();
  const subCategories = [];

  plans.forEach((plan) => {
    const subCategory = plan.subCategory;
    if (plansBySubCategory.has(subCategory)) {
      plansBySubCategory.get(subCategory).push(plan);
    } else {
      subCategories.push(subCategory);
      plansBySubCategory.set(subCategory, [plan]);
    }
  });

  const [activeSubCategory, setActiveSubCategory] = useState("");

  const orderedSubCategories = CONSTANTS.ALL_SUBCATEGORIES.filter(
    (subCategory) => subCategories.includes(subCategory)
  );

  const [serviceError, setServiceError] = useState("");

  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );

  const setServiceErrorFn = (error) => {
    setServiceError(error);
    if (!selectedPlans.length) {
      window.scrollTo({
        top: 700,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setActiveSubCategory(orderedSubCategories[0]);
  }, [orderedSubCategories[0]]);

  return (
    <>
      {serviceError && !selectedPlans.length && (
        <MessageSection
          description={serviceError}
          variant="Error"
          developmentUrl={ableDevelopmentUrl}
        />
      )}
      <TabList className="subcategory-tabs">
        {orderedSubCategories.map((subCategory) => {
          return (
            <Tab
              key={subCategory}
              id={subCategory}
              active={activeSubCategory === subCategory}
              onClick={(...props) => setActiveSubCategory(props[0])}
            >
              {`${CONSTANTS.SUBCATEGORY_LABELS[subCategory]} (${
                plansBySubCategory.get(subCategory).length
              })`}
            </Tab>
          );
        })}
      </TabList>
      {plansBySubCategory.get(activeSubCategory) && (
        <PlansTableView
          plans={plansBySubCategory.get(activeSubCategory)}
          category={category}
          subCategory={activeSubCategory}
          setServiceError={setServiceErrorFn}
        />
      )}
      <BarringSection setServiceError={setServiceErrorFn} />
      <AddOnsSection setServiceError={setServiceErrorFn} />
    </>
  );
};

export default PlansBySubcategory;
