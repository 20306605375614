import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleSelection } from "@state/common-slice";
import { renderBoldText, renderText } from "@utils/helper";
import { NormalizedPlan } from "@state/types";

type Props = {
  item: NormalizedPlan;
  bold?: boolean;
};

export default function PlanCheckbox(props: Props) {
  const dispatch = useAppDispatch();
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );

  const { item, bold } = props;

  return (
    <Checkbox
      id={item.id}
      label={bold ? renderBoldText(item.planName) : renderText(item.planName)}
      checked={selectedPlans.includes(item.id)}
      onChange={(e) => {
        dispatch(toggleSelection(e.target.id));
      }}
    />
  );
}
