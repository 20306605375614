/* eslint-disable */
import { createSlice, current } from "@reduxjs/toolkit";
import { ICart } from "./types";
import { resetAction } from "./reset";

const initialState: ICart = {
  cartId: "",
  billingAccount: "",
  plans: {},
  normalizedPlans: [],
  normalizedDevicesAndAccesories: [],
  patchCartResponse: {
    cartId: "",
    cartItems: [],
  },
  error: null,
  deltaPlans: {},
  invalidBillingAccount: false,
  requiredBillingError: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      return { ...state, ...action.payload };
    },
    setInitialCart: () => {
      return { ...initialState };
    },
    updateCartBarrings: (state, action) => {
      const barringId = action.payload.id.slice(9);
      action.payload.selectedPlans?.forEach((planId) => {
        const selectedPlanId = planId;
        const { name, value, checked } = action.payload;
        if (state.plans[selectedPlanId].barrings[barringId] && !checked) {
          delete state.plans[selectedPlanId].barrings[barringId];
        } else {
          state.plans[selectedPlanId].barrings[barringId] = {
            category: name,
            name: value,
          };
        }
      });
    },
    updateDirectoryListing: (state, action) => {
      const barringId = action.payload.id.slice(6);
      action.payload.selectedPlans?.forEach((planId) => {
        const selectedPlanId = planId;
        const { name, value } = action.payload;
        let existingBarringId;
        Object.entries(current(state.plans[selectedPlanId].barrings)).forEach(
          (barring) => {
            if (barring[1].category === "Directory listing")
              existingBarringId = barring[0];
          }
        );
        if (state.plans[selectedPlanId].barrings[existingBarringId]) {
          delete state.plans[selectedPlanId].barrings[existingBarringId];
          state.plans[selectedPlanId].barrings[barringId] = {
            category: name,
            name: value,
          };
        }
      });
    },
    updateCartAddOns: (state, action) => {
      const {
        groupAddOn,
        addOn,
        id,
        addOnDetails,
        selectionType,
        checkedValue,
      } = action.payload;

      action.payload.selectedPlans.forEach((planId) => {
        const addOns = state.plans[planId].addOns;

        // when groupAddOn is selected for radio options
        if (selectionType == "radio" && !id) {
          if (checkedValue) {
            // add the first radio button selection
            const keyValue = Object.keys(addOnDetails)[0];
            const addOnValue = String(Object.values(addOnDetails)[0]);
            addOns[keyValue] = {
              category: groupAddOn,
              name: addOnValue,
            };
          } else {
            // remove the radio button as well as checkbox selection
            Object.entries(addOns).forEach((item) => {
              if (item[1]["category"] === groupAddOn) {
                delete addOns[item[0]];
              }
            });
          }
        } else if (selectionType == "radio" && id) {
          // check if there are addOns for the selected plan
          if (Object.entries(addOns).length) {
            Object.entries(addOns).forEach((item) => {
              if (item[1]["category"] === groupAddOn) {
                const existingId = item[0];
                delete addOns[existingId];
                addOns[id[0]] = {
                  category: groupAddOn,
                  name: addOn,
                };
              } else {
                addOns[id[0]] = {
                  category: groupAddOn,
                  name: addOn,
                };
              }
            });
          } else {
            addOns[id[0]] = {
              category: groupAddOn,
              name: addOn,
            };
          }
        } else {
          // for checkBox option selections
          Object.entries(addOnDetails).forEach((item) => {
            const addOnIndex = item[0];
            const addOnValue = String(item[1]);
            // groupAddOn is unSelected
            if (!checkedValue && selectionType === "checkbox") {
              delete addOns[addOnIndex];
              // subAddOn is unselected
            } else if (addOns[addOnIndex] && !checkedValue) {
              delete addOns[addOnIndex];
            } else {
              addOns[addOnIndex] = {
                category: groupAddOn,
                name: addOnValue,
              };
            }
          });
        }
      });
    },
    setNormalizedPlans: (state, action) => {
      state.normalizedPlans = action.payload;
    },
    setNormalizedDevicesAndAccesories: (state, action) => {
      state.normalizedDevicesAndAccesories = action.payload;
    },
    savePatchCartResponse: (state, action) => {
      state.patchCartResponse = action.payload;
    },
    updatePlansArrUsingIndex: (state, action) => {
      action.payload.planIndexes?.length &&
        action.payload.planIndexes.forEach((planIndex) => {
          state.normalizedPlans[planIndex] &&
            (state.normalizedPlans[planIndex] = Object.assign(
              state.normalizedPlans[planIndex],
              action.payload.fields
            ));
        });
      if (action.payload.sort) {
        state.normalizedPlans = state.normalizedPlans
          .sort((planA, planB) => {
            return (
              Number(planB.invalid_username) - Number(planA.invalid_username)
            );
          })
          .sort((planA, planB) => {
            return (
              Number(planB.invalid_SIM_number) -
              Number(planA.invalid_SIM_number)
            );
          })
          .sort((planA, planB) => {
            return (
              Number(planB.invalid_SIM_type) - Number(planA.invalid_SIM_type)
            );
          })
          .sort((planA, planB) => {
            return (
              Number(planB.invalid_UNMS_SIM_number) -
              Number(planA.invalid_UNMS_SIM_number)
            );
          });
      }
    },
    updateAssignedPhoneNumbers: (state, action) => {
      const { indexOfPlansToBeUpdated, selectedNumbers } = action.payload;
      indexOfPlansToBeUpdated.forEach((planIndex, index) => {
        state.normalizedPlans[planIndex].phoneNumber = selectedNumbers[index];
      });
    },
    deletePlan: (state, action) => {
      state.normalizedPlans = state.normalizedPlans.filter(
        (plan) => plan.id !== action.payload
      );
    },
    setDeltaPlans: (state, action) => {
      state.deltaPlans[action.payload.id] = action.payload;
    },
    resetDeltaPlans: (state) => {
      state.deltaPlans = {};
    },
    removeDeltaItem: (state, action) => {
      delete state.deltaPlans[action.payload];
    },
    updateDevicePayment: (state, action) => {
      const deviceId = action.payload.id.slice(12);
      const index = state.normalizedDevicesAndAccesories.findIndex(
        (item) => item.id === deviceId
      );
      state.normalizedDevicesAndAccesories[index].paymentType =
        action.payload.value;
      state.normalizedDevicesAndAccesories[index].isPaymentTypeSelected = true;
    },
    deleteDevice: (state, action) => {
      state.normalizedDevicesAndAccesories =
        state.normalizedDevicesAndAccesories.filter(
          (device) => device.id !== action.payload
        );
    },
    setRequiredForPaymentType: (state, action) => {
      action.payload.forEach((deviceId) => {
        const index = state.normalizedDevicesAndAccesories.findIndex(
          (item) => item.id === deviceId
        );
        state.normalizedDevicesAndAccesories[index].isPaymentTypeSelected =
          false;
      });
    },
    setCartId: (state, action) => {
      state.cartId = action.payload;
      return state;
    },
    updateBillingAccount: (state, action) => {
      const { billingAccount, invalidBillingAccount, requiredBillingError } =
        action.payload;
      state.billingAccount = billingAccount;
      state.invalidBillingAccount = invalidBillingAccount;
      state.requiredBillingError = requiredBillingError;
      return state;
    },
    updatePaymentTypeUsingGroupPayment: (state, action) => {
      const { selectedOption, configuredDeviceSelected } = action.payload;
      configuredDeviceSelected.forEach((itemId) => {
        const index = state.normalizedDevicesAndAccesories.findIndex(
          (item) => item.id === itemId
        );
        state.normalizedDevicesAndAccesories[index].paymentType =
          selectedOption;
        state.normalizedDevicesAndAccesories[index].isPaymentTypeSelected =
          true;
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  setCart,
  setInitialCart,
  updateCartBarrings,
  updateDirectoryListing,
  updateCartAddOns,
  setNormalizedPlans,
  setNormalizedDevicesAndAccesories,
  savePatchCartResponse,
  updatePlansArrUsingIndex,
  updateAssignedPhoneNumbers,
  deletePlan,
  setDeltaPlans,
  resetDeltaPlans,
  removeDeltaItem,
  updateDevicePayment,
  deleteDevice,
  setRequiredForPaymentType,
  setCartId,
  updateBillingAccount,
  updatePaymentTypeUsingGroupPayment,
} = cartSlice.actions;

export default cartSlice.reducer;
