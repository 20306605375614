export const CONFIGURE_BARRINGS_AND_ADDONS = {
  LIST_VIEW: {
    CONFIGURE_PLANS_BUTTON: "Configure all plans",
    REMOVE_PLAN_BUTTON: "Remove Plan",
    SPLIT: "Split",
    ADDON_HEADER: "Add-ons",
    NETWORK_FEATURE_HEADER: "Network features",
  },
  INFORMATION_MESSAGE:
    "Once you've configured all the plans with network features and add-ons select next to configure numbers and SIMs.",
};
