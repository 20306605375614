import { ButtonGroupSequence, Spacing } from "@able/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ableDevelopmentUrl,
  buildPath,
  patchCartHandler,
  simConfigPatchCartHandler,
  checkoutSubmitHandler,
  dispatchRequiredBillingError,
  cartPagePatchCartHandler,
  configureDevicesPagePatchCartHandler,
  selectDevicesPatchCartHandler,
} from "@utils/helper";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { useLazyValidateReferenceNumberQuery } from "@services/base";
import { useLazyPatchCartQuery } from "@services/cart";
import { PATHS, SIM_CONFIG_ERRORS } from "@utils/common-static-data";
import { setCart, setRequiredForPaymentType } from "@state/cart-slice";
import { ERROR_TYPES } from "@pages/new-plan-order/constant";
import { setSimConfigurationError } from "@state/error";
import { generatePatchCartOnBarringAndAddon } from "@services/templates/cart";
import { useCartAttributes } from "@utils/cart";
import { FOOTER } from "../constant";

export default function SequenceFooter() {
  const path = useLocation().pathname;
  const { cartId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const reduxStore = useAppSelector((state) => state);

  const itemsInCart = useAppSelector((state) => state.selectPlans);
  const { previousPatchCartResponse, deltaPlans, billingAccount } =
    useAppSelector((state) => ({
      previousPatchCartResponse: state.cart.patchCartResponse,
      deltaPlans: state.cart.deltaPlans,
      billingAccount: state.cart.billingAccount,
    }));

  const { selectedDevices, backOrder } = useAppSelector(
    (state) => state.selectDevices
  );

  const { paginationState } = useAppSelector((store) => store.simConfiguration);
  const {
    internalReferenceNumber,
    orderReference,
    deliveryAddress,
    contactDetails,
  } = useAppSelector((state) => state.checkout);
  const { normalizedDevicesAndAccesories, normalizedPlans: plansInfo } =
    useAppSelector((state) => state.cart);

  const isDevicesFlow = normalizedDevicesAndAccesories.length > 0;

  const requestObjForSimConfiguration = {
    plansInfo,
    previousPatchCartResponse,
  };

  const previousPatchCartAttributes = useCartAttributes().getAllAttributes();

  const requestObjForCheckout = {
    previousPatchCartResponse,
    deliveryAddress,
    contactDetails,
    internalReferenceNumber,
    orderReference,
    previousPatchCartAttributes,
  };

  const [triggerValidateRefNumberQuery] = useLazyValidateReferenceNumberQuery();

  const [patchCart] = useLazyPatchCartQuery();

  const getInternalReferenceNumber = () => {
    const { cartAttributes } = previousPatchCartResponse;
    const cartAttributesObj = cartAttributes?.find(
      (obj) => obj.name === "telstraInternalOrderReference"
    );
    return cartAttributesObj?.value;
  };
  const { SIM_CONFIG_NUMBER_PARTIAL_SUCCESS } = ERROR_TYPES;

  return (
    <Spacing top="spacing2x" bottom="spacing2x">
      <ButtonGroupSequence
        backwardButtonEvents={{
          onClick: () => {
            if (path === PATHS.NEW_ORDER_DETAILS) {
              navigate(PATHS.LANDING_PAGE);
            } else if (path.includes(PATHS.SELECT_PLAN)) {
              navigate(PATHS.NEW_ORDER_DETAILS);
            } else if (path.includes(PATHS.BARRING_ADDONS)) {
              navigate(buildPath(PATHS.SELECT_PLAN, cartId));
            } else if (path.includes(PATHS.CONFIGURE_SIM)) {
              navigate(buildPath(PATHS.BARRING_ADDONS, cartId));
            } else if (path.includes(PATHS.SELECT_DEVICES)) {
              const pathFrom = localStorage.getItem("select-devices-page-from");
              pathFrom === PATHS.CART
                ? navigate(buildPath(pathFrom, cartId))
                : navigate(pathFrom);
            } else if (path.includes(PATHS.CONFIGURE_DEVICES)) {
              navigate(buildPath(PATHS.SELECT_DEVICES, cartId));
            } else if (path.includes(PATHS.CART) && isDevicesFlow) {
              navigate(buildPath(PATHS.CONFIGURE_DEVICES, cartId));
            } else if (path.includes(PATHS.CHECKOUT)) {
              navigate(buildPath(PATHS.CART, cartId));
            } else {
              navigate(buildPath(PATHS.CART, cartId));
            }
          },
        }}
        forwardButtonEvents={{
          onClick: async () => {
            if (path.includes(PATHS.SELECT_PLAN)) {
              patchCartHandler(
                patchCart,
                itemsInCart,
                dispatch,
                setCart,
                navigate,
                PATHS.BARRING_ADDONS,
                cartId,
                billingAccount
              );
            } else if (path.includes(PATHS.BARRING_ADDONS)) {
              if (billingAccount?.length > 0) {
                const res = await patchCart({
                  payload: generatePatchCartOnBarringAndAddon({
                    previousPatchCartResponse,
                    deltaPlans,
                  }),
                  product: "plan",
                  tapasAction: "AN",
                  resetDelta: true,
                }).unwrap();
                if (res.code === 200) {
                  if (res.responseCode === "PARTIAL_SUCCESS") {
                    dispatch(
                      setSimConfigurationError({
                        errorType: SIM_CONFIG_NUMBER_PARTIAL_SUCCESS,
                        errorCode: res.code,
                        errorDescription:
                          SIM_CONFIG_ERRORS[res.code].PARTIAL_SUCCESS,
                      })
                    );
                  }
                  navigate(buildPath(PATHS.CONFIGURE_SIM, cartId));
                }
              } else dispatchRequiredBillingError(dispatch, itemsInCart);
            } else if (path.includes(PATHS.CONFIGURE_SIM)) {
              simConfigPatchCartHandler(
                dispatch,
                navigate,
                plansInfo,
                paginationState,
                requestObjForSimConfiguration,
                patchCart,
                cartId
              );
            } else if (path.includes(PATHS.CHECKOUT)) {
              checkoutSubmitHandler(
                internalReferenceNumber,
                getInternalReferenceNumber,
                dispatch,
                triggerValidateRefNumberQuery,
                patchCart,
                requestObjForCheckout,
                navigate,
                cartId
              );
            } else if (path.includes(PATHS.SELECT_DEVICES)) {
              selectDevicesPatchCartHandler({
                cartId,
                billingAccount,
                selectedDevices,
                itemsInCart,
                backOrder,
                setCart,
                dispatch,
                patchCart,
                reduxStore,
                navigate,
              });
            } else if (path.includes(PATHS.CONFIGURE_DEVICES)) {
              configureDevicesPagePatchCartHandler({
                cartId,
                deltaPlans,
                patchCart,
                setCart,
                navigate,
                dispatch,
                normalizedDevicesAndAccesories,
                setRequiredForPaymentType,
              });
            } else if (path.includes(PATHS.CART)) {
              cartPagePatchCartHandler({
                cartId,
                deltaPlans,
                patchCart,
                navigate,
                dispatch,
                setCart,
                product: "device",
              });
            }
          },
        }}
        forwardButtonLabel={
          path.includes(PATHS.CONFIGURE_SIM)
            ? FOOTER.ADD_TO_CART
            : path.includes(PATHS.CHECKOUT)
            ? FOOTER.SUBMIT
            : FOOTER.NEXT
        }
        developmentUrl={ableDevelopmentUrl}
        state={
          path.includes(PATHS.CHECKOUT) || path === PATHS.NEW_ORDER_DETAILS
            ? "End"
            : "Normal"
        }
        variant={path === PATHS.NEW_ORDER_DETAILS ? "Full" : "Form"}
      />
    </Spacing>
  );
}
