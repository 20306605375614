import { PATHS, PICTO } from "@utils/common-static-data";

export const NEW_ORDER_DETAILS = {
  INTERNAL_REFERENCE_NUMBER_ERROR:
    "Case reference numbers must be in one of these formats:" +
    "Phoenix 8 digits, Maxim TRN0000000000, Telstra Now TNC000000000, Order Express GESPO000000000" +
    " or TBAPO000000000",
  PHOENIX_ERROR:
    "Phoenix reference number not found. Please try a different case reference number.",
  HEADING: "New order details",
  REQUESTER: "Requester",
  REQUIRED_ERROR: "Required field.",
  TELSTRA_INTERNAL_REFERENCE_NUMBER: "Telstra internal reference number",
  ORDER_REFERENCE: "Order reference",
  WHAT_WOULD_YOU_LIKE_TO_ORDER: "What would you like to order?",
  ORDERING_OPTIONS: {
    NEW_MOBILE_PLANS: {
      LABEL: "Mobile plans",
      TAG: "NEW PLAN ORDER",
      PICTO: PICTO.MOBILE_56,
      ID: "newMobilePlans",
      LINK: PATHS.SELECT_PLAN,
    },
    NEW_DEVICES_AND_ACCESSORIES: {
      LABEL: "Device and accessories",
      TAG: "NEW PLAN ORDER",
      PICTO: PICTO.ACCESSORIES,
      ID: "newDevicesAndAccessories",
      LINK: PATHS.SELECT_DEVICES,
    },
    NEW_BLANK_SIMS: {
      LABEL: "Blank SIMs",
      TAG: "NEW PLAN ORDER",
      PICTO: PICTO.SIM,
      ID: "newBlankSims",
      LINK: "",
    },
  },
  PHOENIX_LENGTH: 8,
  TELSTRA_INTERNAL_REFERENCE_NUMBER_VALIDATION_API_FAILED:
    "Error 500. Case validation failed. Try again.",
};
