import "./style.scss";
import React from "react";
import SequenceFooter from "./sequence-footer";
import { useLocation } from "react-router-dom";
import CartFooter from "./cart-footer";
import { PATHS } from "@utils/common-static-data";
import { Column, Divider, Grid, Spacing, Surface } from "@able/react";
import { useAppSelector } from "@state/hooks";

export default function Footer() {
  const location = useLocation();
  const path = location.pathname;
  const isError = useAppSelector((state) => state.error.isError);
  const { normalizedPlans } = useAppSelector((state) => state.cart);
  const isPlansFlow = normalizedPlans.length > 0;

  return (
    !(
      path === PATHS.LANDING_PAGE ||
      path.includes(PATHS.ORDER_CONFIRMATION) ||
      isError
    ) && (
      <>
        {path === PATHS.NEW_ORDER_DETAILS ? (
          <Surface
            variant="SurfaceFlat"
            className="new-order-details-page-footer"
            cornerRadius="cornerRadiusNone"
          >
            <Spacing bottom="spacing10x" top="spacing4x">
              <Grid>
                <Column cols={12} bsm={12} bmd={12} blg={12}>
                  <SequenceFooter />
                </Column>
              </Grid>
            </Spacing>
          </Surface>
        ) : (
          <Spacing top="spacing4x">
            <Divider />
            <Grid>
              <Column cols={12} bsm={12} bmd={12} blg={12}>
                {path.includes(PATHS.CART) && isPlansFlow ? (
                  <CartFooter />
                ) : (
                  <SequenceFooter />
                )}
              </Column>
            </Grid>
          </Spacing>
        )}
      </>
    )
  );
}
