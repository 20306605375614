/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useState } from "react";
import { TableAccordion } from "telstra-ui/components/table-accordion/AccordionTable";
import {
  prefixWithDollarSign,
  renderBoldText,
  renderText,
} from "@utils/helper";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { CART_PLANS_TABLE, CART_CHARGES_TABLE } from "../constant";
import { PATHS } from "@utils/common-static-data";
import { useLocation } from "react-router-dom";
import { TextStyle } from "@able/react";
import { useAppSelector } from "@state/hooks";

const ReviewCartChargesTable = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [expanded, setExpanded] = useState({});
  const path = useLocation().pathname;
  const isCheckoutPage = path.includes(PATHS.CHECKOUT);
  const [chargesSummary, setChargesSummary] = useState([]);

  const patchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse
  );

  const {
    cartItems,
    cartTotalPrice: {
      recurringPrice = 0,
      onceOffPrice = 0,
      recurringPriceWithGst = 0,
      onceOffPriceWithGst = 0,
      totalCartPrice = 0,
      totalCartPriceWithGst = 0,
    } = {},
  } = patchCartResponse;

  const plans = useAppSelector((state) => state.cart.normalizedPlans);
  const formChargesSummary = () => {
    function pushItem(cartItem, type) {
      const {
        itemPrices,
        productOffering: { name = "" },
        quantity,
      } = cartItem;
      if (itemPrices?.length > 0) {
        for (const chargeItem of itemPrices) {
          const item = JSON.parse(JSON.stringify(chargeItem));
          item.name = name;
          item.quantity = quantity;
          item.type = type;
          chargesSummary.push(item);
        }
      }
    }

    for (const plan of plans) {
      const planItem = cartItems.find((cartItem) => cartItem.id === plan.id);
      /* push plan in charges summary */
      pushItem(planItem, "plan");
      const addOnsIds = Object.keys(plan.addOns);
      if (addOnsIds.length > 0) {
        for (const addOnId of addOnsIds) {
          const addOnItem = cartItems.find(
            (cartItem) => cartItem.id === addOnId
          );
          /* push addOn in charges summary */
          pushItem(addOnItem, "addOn");
        }
      }
    }
    /* hardcoded rows for total charges */
    if (chargesSummary.length > 0) {
      chargesSummary.push(
        {
          name: CART_CHARGES_TABLE.RECURRING,
          priceType: CART_CHARGES_TABLE.TOTAL_CHARGE,
          totalPriceWithGst: recurringPriceWithGst,
          unitPriceWithGst: "",
          unitPrice: recurringPrice,
          type: "plan",
          quantity: 0,
        },
        {
          name: CART_CHARGES_TABLE.ONCE_OFF,
          priceType: CART_CHARGES_TABLE.TOTAL_CHARGE,
          totalPriceWithGst: onceOffPriceWithGst,
          unitPriceWithGst: "",
          unitPrice: onceOffPrice,
          type: "plan",
          quantity: 0,
        }
      );
      setChargesSummary(chargesSummary);
    }
  };

  const onExpandedChange = (expandedObj) => {
    setExpanded(expandedObj);
  };

  const headerHandlerClick = (columnName) => {
    if (columnName === "id") {
      const expandRow = pageSize;
      const obj = {};
      for (let i = 0; i < expandRow; i++) {
        obj[i] = {};
      }
      Object.keys(expanded).length === 0
        ? onExpandedChange(obj)
        : onExpandedChange({});
    }
  };
  useEffect(() => {
    formChargesSummary();
    if (!isCheckoutPage) headerHandlerClick("id");
  }, [plans, patchCartResponse]);

  const renderTitle = (accessor: string) => {
    const onClick = (e) => {
      e.stopPropagation();
      headerHandlerClick(accessor);
    };
    const iconName =
      Object.keys(expanded).length === 0
        ? "icon-system-chevron-down"
        : "icon-system-chevron-up";
    return (
      <div className="tl-table-header-icon-wrapper" onClick={onClick}>
        <SvgIcon name={iconName} />
      </div>
    );
  };

  const onPageChange = (tabName, pageIndex) => {
    setPage(pageIndex);
  };
  const onPageSizeChange = (tabName, currentPageSize) => {
    setPageSize(currentPageSize);
  };

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-center border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderTitle(CART_PLANS_TABLE.ID),
      sortable: true,
      resizable: false,
      minWidth: 56,
      Cell: () => null,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right remove-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.QUANTITY),
      accessor: "quantity",
      sortable: true,
      minWidth: 86,
      Cell: (cellInfo) => {
        if (
          cellInfo.original.type !== "addOn" &&
          cellInfo.original.priceType !== CART_CHARGES_TABLE.TOTAL_CHARGE
        ) {
          return (
            <div className="quntity-wrapper">{renderText(cellInfo.value)}</div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_CHARGES_TABLE.ITEM),
      accessor: "name",
      sortable: true,
      minWidth: 373,
      Cell: (cellInfo) => renderText(cellInfo.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_CHARGES_TABLE.PAYMENT_TYPE),
      sortable: true,
      minWidth: 300,
      accessor: "priceTypeUnit",
      Cell: (cellInfo) => renderText(cellInfo.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_CHARGES_TABLE.UNIT_CHARGES),
      sortable: true,
      minWidth: 300,
      accessor: "unitPriceWithGst",
      Cell: (cellInfo) =>
        cellInfo.row.priceType !== CART_CHARGES_TABLE.TOTAL_CHARGE &&
        renderText(prefixWithDollarSign(cellInfo.value)),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_CHARGES_TABLE.CHARGES_EX_GST),
      accessor: "unitPrice",
      sortable: true,
      minWidth: 300,
      Cell: (cellInfo) => renderText(prefixWithDollarSign(cellInfo.value)),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_CHARGES_TABLE.CHARGES_INC_GST),
      accessor: "totalPriceWithGst",
      sortable: true,
      minWidth: 300,
      Cell: (cellInfo) => renderText(prefixWithDollarSign(cellInfo.value)),
    },
    {
      Header: renderBoldText(CART_PLANS_TABLE.GROUP_BY_CATEGORY),
      accessor: "priceType",
      sortable: false,
      expander: true,
      pivot: true,
      Pivot: (cellInfo: any) => {
        const className = ["rt-flex", "rt-flex-subheader"].join(" ");
        const totalQuantity = cellInfo.subRows.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.quantity;
          },
          0
        );

        const totalPrice =
          (cellInfo.row.priceType === CART_CHARGES_TABLE.TOTAL_CHARGE &&
            prefixWithDollarSign(totalCartPrice)) ||
          (cellInfo.row.priceType === CART_CHARGES_TABLE.ONCE_OFF &&
            prefixWithDollarSign(onceOffPrice)) ||
          (cellInfo.row.priceType === CART_CHARGES_TABLE.RECURRING &&
            prefixWithDollarSign(recurringPrice));

        const totalPriceWithGst =
          (cellInfo.row.priceType === CART_CHARGES_TABLE.TOTAL_CHARGE &&
            prefixWithDollarSign(totalCartPriceWithGst)) ||
          (cellInfo.row.priceType === CART_CHARGES_TABLE.ONCE_OFF &&
            prefixWithDollarSign(onceOffPriceWithGst)) ||
          (cellInfo.row.priceType === CART_CHARGES_TABLE.RECURRING &&
            prefixWithDollarSign(recurringPriceWithGst));

        return (
          <div
            className={`tl-row-expander ${
              cellInfo.isExpanded ? "tl-row-expanded" : ""
            }`}
          >
            <div className={className}>
              <div className="tl-border-override icon-container">
                <div className="tl-accordion-icon-wrapper">
                  {cellInfo.isExpanded ? (
                    <SvgIcon name="icon-system-chevron-up" />
                  ) : (
                    <SvgIcon name="icon-system-chevron-down" />
                  )}
                </div>
              </div>

              <div
                className="tl-border-override"
                style={{ width: 86 }}
                onClick={(e) => e.stopPropagation()}
              >
                <TextStyle alias="Label" className="tl-accordion-icon-wrapper">
                  {cellInfo.row.priceType !== CART_CHARGES_TABLE.TOTAL_CHARGE &&
                    totalQuantity}
                </TextStyle>
              </div>
              <div
                className={"pivot-text-holder tl-border-override"}
                style={{ width: 373 }}
              >
                <TextStyle alias="Label">{cellInfo.row.priceType}</TextStyle>
              </div>
              <div
                className="pivot-text-holder tl-border-override"
                style={{ width: 300 }}
              />
              <div
                className="pivot-text-holder tl-border-override"
                style={{ width: 300 }}
              />
              <div
                className="pivot-text-holder tl-border-override"
                style={{ width: 300 }}
              >
                <TextStyle alias="Label">{totalPrice}</TextStyle>
              </div>
              <div
                className="pivot-text-holder tl-border-override"
                style={{ width: 300 }}
              >
                <TextStyle alias="Label">{totalPriceWithGst}</TextStyle>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="chargesTable">
      <TableAccordion
        data={chargesSummary}
        columns={columnData}
        showPagination={false}
        resizable={true}
        page={page}
        defaultPageSize={pageSize}
        totalRecordLength={chargesSummary.length}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        isStickyHeader={true}
        sortable={false}
        pivotBy={["priceType"]}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        freezWhenExpanded={false}
        getTdProps={(state, rowInfo, column, instance) => {
          return rowInfo.original?.type === "addOn" &&
            !rowInfo.original?.lastRow
            ? { className: "addOns-barrings-row" }
            : {};
        }}
      />
    </div>
  );
};

export default ReviewCartChargesTable;
