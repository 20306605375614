import "./style.scss";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Spacing,
  Column,
  MessageSection,
  TextStyle,
  ModalDialogCriticalDecision,
} from "@able/react";
import BillingAccount from "@components/billing-account";
import ErrorScreen from "@components/error-screen";
import { useGetDeviceOffersQuery } from "@services/catalog";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import TableView from "./table-view";
import SelectedDevicesTable from "./components/selected-devices-table";
import Category from "./components/category-dropdown";
import DeviceFilters from "./components/device-filter/device-filter";
import {
  BACK_ORDER_MODAL,
  DEVICES_AND_ACCESSORIES,
  SELECT_DEVICES,
} from "@pages/devices-and-accessories/constant";
import {
  ableDevelopmentUrl,
  selectDevicesPatchCartHandler,
} from "@utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyPatchCartQuery, useCommonGetCartQuery } from "@services/cart";
import {
  updateBackOrderStatus,
  updateMultipleDevices,
} from "@state/select-devices-slice";
import { DeviceProduct, PatchCart } from "@state/types";
import { CART } from "@utils/common-static-data";
import { useCartAttributes, useShouldEnableGetCartHook } from "@utils/cart";
import { setCart, setCartId, updateBillingAccount } from "@state/cart-slice";
import { setError } from "@state/error";
import { customErrorMessages } from "@components/error-screen/constant";
import { GridView } from "./grid-view";
import { ToggleButton } from "telstra-ui/components/toggle/ToggleButton";

function SelectDevices() {
  const [selectedView, setSelectedView] = useState<"table" | "grid">("table");
  const [billingError, setBillingError] = useState(null);
  const dispatch = useAppDispatch();
  const { cartId } = useParams();
  const skipGetCart = useShouldEnableGetCartHook();
  const navigate = useNavigate();
  const [patchCart] = useLazyPatchCartQuery();
  const reduxStore = useAppSelector((state) => state);
  const customError = useAppSelector((state) => state.error.isError);
  const { selectedDevices, backOrder: showBackOrderModal } = useAppSelector(
    (state) => state.selectDevices
  );
  const itemsInCart = useAppSelector((state) => state.selectPlans);
  const { error: cartError, billingAccount } = useAppSelector(
    (state) => state.cart
  );
  const selectedDeviceFilters = useAppSelector(
    (state) => state.commonStore.selectedDeviceFilters
  );

  const {
    data: devices = [],
    isLoading: isDevicesLoading,
    isFetching: isDevicesFetching,
    error: deviceOffersError,
  } = useGetDeviceOffersQuery();

  const {
    data: cartData,
    isFetching: isCartDataFetching,
    isError: isGetCartError,
    error: getCartError,
  } = useCommonGetCartQuery({ cartId }, { skip: !skipGetCart });

  useEffect(() => {
    if (isGetCartError) {
      dispatch(setError(true));
      dispatch(setCart({ error: getCartError }));
    } else {
      dispatch(setError(false));
      dispatch(setCart({ error: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetCartError, getCartError]);

  const billingAccountNumber = useCartAttributes().getItemAttribute(
    CART.ATTRIBUTES.BILLING_ACCOUNT_NUMBER
  );

  const catchBillingError = (error) => {
    setBillingError(error);
  };

  const getFilteredOffers = () => {
    const filteredOffers = [];
    for (const filter of selectedDeviceFilters) {
      for (const plan of devices[0].products) {
        for (const tag of plan.tags) {
          if (
            (filter.id.toLowerCase() === tag.toLowerCase() &&
              filteredOffers.indexOf(plan) === -1) ||
            filter.id === ""
          ) {
            filteredOffers.push(plan);
          }
        }
      }
    }
    return filteredOffers;
  };

  const updateSelectedDevices = (
    data: PatchCart,
    products: DeviceProduct[]
  ) => {
    const devicesObj = {};
    products.forEach((offer) => {
      devicesObj[`${offer.id}`] = offer;
    });
    const allSelectedDevices = [];
    const { cartItems } = data;
    for (const cartItem of cartItems) {
      const { itemRelationships, quantity } = cartItem;
      if (!itemRelationships.length) continue;
      const deviceId = cartItem.productOffering.id;
      allSelectedDevices.push({ ...devicesObj[deviceId], quantity });
    }
    dispatch(updateMultipleDevices(allSelectedDevices));
  };

  const toggleButton = (
    <div>
      <ToggleButton
        className="tl-toggle-variant-modern"
        size="large"
        options={SELECT_DEVICES.VIEWS}
        value={selectedView}
        selectionMode="single"
        onChange={(option) => setSelectedView(option.value)}
      />
    </div>
  );

  const renderTable = () => {
    const filteredOffers =
      selectedDeviceFilters.length > 0
        ? getFilteredOffers()
        : devices[0]?.products;
    return <TableView devices={filteredOffers} toggleButton={toggleButton} />;
  };

  useEffect(() => {
    dispatch(setCartId(cartId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isDevicesFetching &&
      !isCartDataFetching &&
      cartData &&
      devices[0].products
    ) {
      if (cartData.cartItems) {
        updateSelectedDevices(cartData, devices[0].products);
      }
    }
    if (!isDevicesFetching && !isCartDataFetching && deviceOffersError) {
      dispatch(setError(true));
      const errData: any = { ...deviceOffersError };
      if ("status" in deviceOffersError) {
        errData.customErrorMsg =
          customErrorMessages.offersAPI[deviceOffersError.status];
      }
      dispatch(setCart({ error: errData }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartDataFetching, isDevicesFetching, deviceOffersError]);

  useEffect(() => {
    if (billingAccountNumber) {
      dispatch(
        updateBillingAccount({
          billingAccount: billingAccountNumber,
          invalidBillingAccount: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAccountNumber]);

  if (customError && billingError) {
    return <ErrorScreen error={billingError} />;
  }

  const toggleModal = () => {
    dispatch(updateBackOrderStatus(!showBackOrderModal));
  };

  const backOrderConfirmationModal = (
    <ModalDialogCriticalDecision
      title={BACK_ORDER_MODAL.TITLE}
      description={BACK_ORDER_MODAL.DESCRIPTION}
      stackButtonOnVXS={false}
      developmentUrl={ableDevelopmentUrl}
      secondaryButtonEvents={{
        onClick: () => {
          toggleModal();
        },
      }}
      secondaryButtonLabel={BACK_ORDER_MODAL.SECONDARY_BTN_LABEL}
      preferredButtonEvents={{
        onClick: async () => {
          selectDevicesPatchCartHandler({
            cartId,
            billingAccount,
            selectedDevices,
            itemsInCart,
            backOrder: showBackOrderModal,
            setCart,
            dispatch,
            patchCart,
            reduxStore,
            navigate,
          });
        },
      }}
      preferredButtonLabel={BACK_ORDER_MODAL.PRIMARY_BTN_LABEL}
      isShowing={showBackOrderModal}
      setHideDialog={toggleModal}
    />
  );

  return (
    <>
      <Grid>
        <Column cols={12} bsm={12} bmd={12} blg={12}>
          <Spacing top="spacing4x">
            <BillingAccount catchBillingError={catchBillingError} />
          </Spacing>
          {devices.length > 0 && (
            <>
              <Spacing top="spacing4x">
                <Category category={devices[0]?.categories} />
              </Spacing>
              <Spacing top="spacing4x">
                <TextStyle alias="HeadingD">
                  {DEVICES_AND_ACCESSORIES.FILTER_LABEL}
                </TextStyle>
              </Spacing>
              <Spacing top="spacing2x">
                <DeviceFilters offers={devices[0]?.categories} />
              </Spacing>
            </>
          )}
        </Column>
        <Column>
          <div className="add-devices-error">
            {cartError?.errorCode === 1000 && (
              <Spacing top="spacing4x">
                <Column cols={12} bsm={12} bmd={12} blg={12}>
                  <MessageSection
                    variant="Error"
                    developmentUrl={ableDevelopmentUrl}
                    description={cartError?.errorDescription}
                    className="message-section"
                  />
                </Column>
              </Spacing>
            )}
          </div>
          <Spacing top="spacing5x" bottom="spacing5x">
            {isDevicesLoading ? (
              "Loading"
            ) : selectedView === "table" ? (
              renderTable()
            ) : (
              <GridView
                devices={devices[0].products}
                toggleButton={toggleButton}
              />
            )}
          </Spacing>
          {selectedDevices.length != 0 && (
            <Spacing bottom="spacing5x">
              <TextStyle alias="Label">{`${selectedDevices.length} ${SELECT_DEVICES.ITEMS_ADDED}`}</TextStyle>
            </Spacing>
          )}
        </Column>
      </Grid>
      {selectedDevices.length !== 0 && <SelectedDevicesTable />}
      {backOrderConfirmationModal}
    </>
  );
}

export default SelectDevices;
