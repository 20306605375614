/* eslint-disable */
import "./style.scss";
import { Grid, TextStyle, MessageInline } from "@able/react";
import { TableAccordion } from "telstra-ui/components/table-accordion/AccordionTable";
import { Accordion } from "telstra-ui/components/accordion/Accordion";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import { RadioButton } from "telstra-ui/components/radio-button/RadioButton";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import { useState, useEffect } from "react";
import { updateCartAddOns } from "@state/cart-slice";
import { renderBoldText } from "@utils/helper";
import { useGetBarringsAddonsQuery } from "@services/catalog";
import ableIconUrl from "@able/web/dist/symbol/able-sprites.svg";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";

const AddOnsSection = (props) => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.cart.plans);
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );
  const { data } = useGetBarringsAddonsQuery();

  const [addOnsSelected, setaddOnValues] = useState({});
  const [expanded, setExpanded] = useState({});
  const onExpandedChange = (expandedObj) => {
    setExpanded(expandedObj);
  };
  const getAddOns = () => {
    let addOns = {};
    selectedPlans.forEach((element) => {
      addOns = Object.assign(addOns, plans[element]?.addOns);
    });
    setaddOnValues(addOns);
  };

  const getcheckedValue = (id) => {
    let isChecked = true;
    if (selectedPlans.length === 0) {
      isChecked = false;
    } else {
      for (const planId of selectedPlans) {
        if (!plans[planId].addOns[id]) {
          isChecked = false;
          break;
        }
      }
    }
    return isChecked;
  };
  useEffect(() => {
    getAddOns();
  }, [selectedPlans, plans]);

  const handleGroupCheckbox = (event, groupAddOn, rows) => {
    if (selectedPlans.length === 0) {
      props.setServiceError(
        NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS.SERVICE_ERRORS.ADD_ON
      );
    } else {
      const addOnDetails = {};
      let selectionType;
      const checkedValue = event.target.checked;
      rows.forEach((rowId) => {
        if (!rowId["_original"].hasSubAddOns) {
          addOnDetails[rowId["_original"].id] = rowId["_original"].addOn;
          selectionType = rowId["_original"].selectionType;
        }
      });
      dispatch(
        updateCartAddOns({
          groupAddOn,
          addOnDetails,
          selectionType,
          checkedValue,
          selectedPlans,
        })
      );
    }
  };
  const handleCheckBox = (event, groupAddOn, addOnDetails) => {
    if (selectedPlans.length === 0) {
      props.setServiceError(
        NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS.SERVICE_ERRORS.ADD_ON
      );
    } else {
      const checkedValue = event.target.checked;
      dispatch(
        updateCartAddOns({
          groupAddOn,
          addOnDetails,
          selectedPlans,
          checkedValue,
        })
      );
    }
  };
  const handleRadioButton = (groupAddOn, addOn, id, selectionType) => {
    if (selectedPlans.length === 0) {
      props.setServiceError(
        NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS.SERVICE_ERRORS.ADD_ON
      );
    } else {
      dispatch(
        updateCartAddOns({
          groupAddOn,
          addOn,
          id,
          selectionType,
          selectedPlans,
        })
      );
    }
  };

  // add dynamic class to make sub-addOns row highlight.
  const addDynamicClass = (dynamicId, cellInfo) => {
    const hasSubAddOns = cellInfo.original.hasSubAddOns;

    if (hasSubAddOns) {
      const subRowElement = document.getElementById(dynamicId);
      subRowElement &&
        subRowElement.parentElement &&
        subRowElement.parentElement.classList.add("addons-subRow");
    }
  };
  const columnData = [
    {
      headerClassName: "table-align-right tl-no-hover",
      className: "td-align-center border-right no-border-bottom",
      accessor: "icon",
      minWidth: 70,
    },
    {
      headerClassName: "table-align-center",
      className: "td-align-center border-right no-border-bottom",
      accessor: "selector",
      minWidth: 70,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      style: { paddingLeft: "20px" },
      Header: renderBoldText("Add-on Name"),
      accessor: "groupAddOn",
      pivot: true,
      Pivot: (cellInfo) => {
        const groupAddOnName = cellInfo.value;
        const dynamicId = groupAddOnName.split(" ").join("-");
        const rows = cellInfo.subRows;
        const rowsLength = rows.length;
        let preSelectCheckbox = false;
        let defaultSelected = false;
        let count = 0;
        if (
          rowsLength === 1 &&
          rows[0]["_original"].defaultSelected &&
          selectedPlans.length !== 0
        ) {
          defaultSelected = true;
        }
        const selectionType = rows[0]["_original"].selectionType;

        /* select groupAddOn checkbox only if both subAddOns are selected.
        In case of radio options, check groupAddOn checkbox even if one subAddOn is selected.*/
        if (selectionType === "checkbox") {
          Object.values(addOnsSelected).some((item) => {
            if (item && item["category"] === groupAddOnName) {
              count++;
            }
          });
          if (count) {
            preSelectCheckbox = true;
          }
        } else {
          Object.values(addOnsSelected).some(
            (item) =>
              (preSelectCheckbox = item && item["category"] === groupAddOnName)
          );
        }

        return (
          <div
            className={`tl-row-expander ${
              cellInfo.isExpanded ? "tl-row-expanded" : ""
            }`}
          >
            <div className="rt-flex">
              <div
                className="icon-container tl-border-override"
                style={{ minWidth: 70 }}
              >
                <div className="tl-accordion-icon-wrapper">
                  {cellInfo.isExpanded ? (
                    <SvgIcon
                      name="icon-system-chevron-up"
                      className="group-header-add-ons"
                    />
                  ) : (
                    <SvgIcon
                      name="icon-system-chevron-down"
                      className="group-header-add-ons"
                    />
                  )}
                </div>
              </div>
              <div
                className="tl-border-override"
                onClick={(e) => e.stopPropagation()}
                style={{ minWidth: 70 }}
              >
                <div className="tl-accordion-icon-wrapper">
                  <Checkbox
                    id={dynamicId}
                    label={""}
                    name={dynamicId}
                    checked={defaultSelected ? true : preSelectCheckbox}
                    onChange={(event) =>
                      handleGroupCheckbox(event, groupAddOnName, rows)
                    }
                    disabled={defaultSelected}
                  />
                </div>
              </div>
              <div
                className="pivot-text-holder tl-border-override"
                style={{ minWidth: 425 }}
              >
                <span className="pivot-text-title">{groupAddOnName}</span>
              </div>
              <div
                className="pivot-text-holder tl-border-override"
                style={{ minWidth: 425 }}
              ></div>
              <div
                className="pivot-text-holder"
                style={{ minWidth: 436 }}
              ></div>
            </div>
          </div>
        );
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left tl-border-override",
      style: { paddingLeft: "20px" },
      Header: renderBoldText("Add-on Name"),
      accessor: "addOn",
      minWidth: 425,
      Cell: (cellInfo) => {
        const addOnDetails = {};
        const groupAddOnName = cellInfo.original.groupAddOn;
        const selectionType = cellInfo.original.selectionType;
        const addOnName = cellInfo.value;
        const dynamicId = addOnName.split(" ").join("-");
        const id = [cellInfo.original.id];
        addOnDetails[`${id}`] = addOnName;
        let defaultSelected =
          selectedPlans.length !== 0 && cellInfo.original.defaultSelected;

        addDynamicClass(dynamicId, cellInfo);
        return (
          <div className="rt-flex" id={dynamicId}>
            {cellInfo.original.hasSubAddOns ? (
              <TextStyle alias="Label">{addOnName}</TextStyle>
            ) : selectionType === "checkbox" ? (
              <Checkbox
                id={"checkbox" + "-" + dynamicId}
                label={addOnName}
                name={addOnName}
                onChange={() =>
                  handleCheckBox(event, groupAddOnName, addOnDetails)
                }
                checked={defaultSelected ? true : getcheckedValue(id[0])}
                disabled={defaultSelected}
              />
            ) : (
              <RadioButton
                id={"radioButton" + "-" + dynamicId}
                label={addOnName}
                name={addOnName}
                checked={getcheckedValue(id[0])}
                onChange={() =>
                  handleRadioButton(
                    groupAddOnName,
                    addOnName,
                    id,
                    selectionType
                  )
                }
              />
            )}
          </div>
        );
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left tl-border-override",
      style: { paddingLeft: "20px" },
      Header: renderBoldText("Description"),
      accessor: "description",
      minWidth: 425,
      Cell: (cellInfo) => {
        const description = cellInfo.value;
        const note = cellInfo.original.note;
        const dynamicStyleClass = note && "-info";
        const dynamicId = cellInfo.original.id;

        addDynamicClass(dynamicId, cellInfo);
        return (
          <div
            className={`description-section${dynamicStyleClass} `}
            id={dynamicId}
          >
            {!cellInfo.original.hasSubAddOns && (
              <div className="description">{description}</div>
            )}
            {note && (
              <MessageInline variant="Information" developmentUrl={ableIconUrl}>
                {note}
              </MessageInline>
            )}
          </div>
        );
      },
    },
    {
      headerClassName: "table-align-right td-align-right",
      className: "td-align-right",
      style: { paddingRight: "20px" },
      Header: renderBoldText("Charge (ex GST)"),
      accessor: "charge",
      minWidth: 436,
      Cell: (cellInfo) => {
        const charge = cellInfo.value;
        const chargeToolTip = cellInfo.original.chargeToolTip;
        const dynamicId = "charge-" + cellInfo.original.id;

        addDynamicClass(dynamicId, cellInfo);
        return (
          <div className="charges-section" id={dynamicId}>
            {chargeToolTip && (
              <ToolTip
                description={chargeToolTip}
                className={"charge-toolTip"}
                position="left"
                iconName="icon-system-info"
              />
            )}
            <span className="charge">{charge}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="addOnsSection">
      <Accordion
        exclusive={false}
        items={[
          {
            header: <TextStyle alias="HeadingD">Add-ons (optional)</TextStyle>,
            contentId: "add-ons",
            startOpen: true,
            content: (
              <Grid>
                <>
                  <TableAccordion
                    columns={columnData}
                    data={
                      data?.addOnsDetails?.addOns &&
                      Object.values(data.addOnsDetails.addOns)
                    }
                    pivotBy={["groupAddOn"]}
                    expanded={expanded}
                    onExpandedChange={onExpandedChange}
                  />
                </>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
};
export default AddOnsSection;
