import { createSlice } from "@reduxjs/toolkit";
import { IGetFeatureFlagsAEM } from "./types";

const initialState: IGetFeatureFlagsAEM = {
  featureFlags: {},
};

export const aemDynamicContentSlice = createSlice({
  name: "aemDynamicContent",
  initialState,
  reducers: {
    setFlags: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFlags } = aemDynamicContentSlice.actions;

export default aemDynamicContentSlice.reducer;
