import "./style.scss";
import { TextField } from "@able/react";
import { ableDevelopmentUrl, REGULAR_EXPRESSIONS } from "@utils/helper";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { updatePlansArrUsingIndex } from "@state/cart-slice";

export default function AddUsername({ planId }) {
  const dispatch = useAppDispatch();
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlans);
  const planIndex = plansInfo.findIndex((plan) => plan.id === planId);

  const validateUsername = (e) => {
    const validUsername = REGULAR_EXPRESSIONS.USERNAME.test(e.target.value);
    const payload = {
      planIndexes: [planIndex],
      fields: {
        invalid_username: !validUsername,
        planUpdated: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  };

  const updateUsername = (e) => {
    const payload = {
      planIndexes: [planIndex],
      fields: {
        username: e.target.value,
        invalid_username: false,
        planUpdated: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));
  };

  return (
    <>
      <TextField
        key={"userName" + planIndex}
        id={"userName" + planIndex}
        name={"userName" + planIndex}
        className="username"
        developmentUrl={ableDevelopmentUrl}
        invalid={plansInfo[planIndex].invalid_username}
        invalidInputText={SIM_CONFIG_TABLE.INVALID_USERNAME}
        value={plansInfo[planIndex].username}
        events={{
          onBlur: (e) => {
            validateUsername(e);
          },
          onChange: (e) => {
            updateUsername(e);
          },
        }}
      />
    </>
  );
}
