import { createSlice } from "@reduxjs/toolkit";
import { resetAction } from "./reset";
interface IPlan {
  id: string;
  planType: string;
  phoneNumber: string;
  barring: string[];
  addOns: string[];
  simType: string;
  simSerialNumber: string;
  username: string;
  invalid_UNMS_SIM_number: boolean;
  invalid_SIM_number: boolean;
  invalid_username: boolean;
  invalid_SIM_type: boolean;
  refreshCount: number;
}

interface IPaginationState {
  data: IPlan[];
  pageSize: number;
  totalRecordLength: number;
  pages: number;
  page: number;
  recordsPerApi: number;
}
interface INumber {
  // eslint-disable-next-line id-denylist
  number: string;
  numberType: string;
  premiumType: string;
  status: string;
}
export interface ISIMConfigurationCartState {
  selectedNumbers: string[];
  availableNumbers: INumber[];
  paginationState: IPaginationState;
  plans: IPlan[];
  refreshedPhonePlanId: string;
}

const initialState: ISIMConfigurationCartState = {
  selectedNumbers: [],
  availableNumbers: [],
  paginationState: {
    data: [],
    pageSize: 10,
    totalRecordLength: 0,
    pages: 0,
    page: 1,
    recordsPerApi: 10,
  },
  plans: [],
  refreshedPhonePlanId: "",
};

export const simConfigurationSlice = createSlice({
  name: "sim-configuration",
  initialState,
  reducers: {
    toggleSelectedNumbers: (state, action) => {
      const id = action.payload;
      const position = state.selectedNumbers.indexOf(id);
      if (position === -1) {
        state.selectedNumbers.push(id);
      } else {
        state.selectedNumbers.splice(position, 1);
      }
    },
    toggleAllNumbers: (state, action) => {
      if (action.payload.length === state.selectedNumbers.length) {
        return { ...state, selectedNumbers: [] };
      }
      return { ...state, selectedNumbers: [...action.payload] };
    },
    deleteSelectedNumbers: (state) => {
      return { ...state, selectedNumbers: [] };
    },
    setAvailableNumbers: (state, action) => {
      return { ...state, availableNumbers: [...action.payload] };
    },
    setPagination: (state, action) => {
      return { ...state, paginationState: { ...action.payload } };
    },
    setPlans: (state, action) => {
      return { ...state, plans: action.payload };
    },
    setRefreshedPhonePlanId: (state, action) => {
      return { ...state, refreshedPhonePlanId: action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  toggleSelectedNumbers,
  toggleAllNumbers,
  deleteSelectedNumbers,
  setAvailableNumbers,
  setPagination,
  setPlans,
  setRefreshedPhonePlanId,
} = simConfigurationSlice.actions;

export default simConfigurationSlice.reducer;
