import { IconButton } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";

const ActionColumn = ({ cellInfo, setPlansToBeRemove, toggleModal }) => {
  return (
    <div className={`action-wrapper`}>
      <IconButton
        icon="Calendar"
        screenReaderContent="Remove Plan"
        variant="Destructive"
        developmentUrl={ableDevelopmentUrl}
        size="Big"
        events={{
          onClick: (e) => {
            e.stopPropagation();
            const toDelete = new Set();
            if (cellInfo.original) {
              toDelete.add(cellInfo.original.id);
            } else {
              cellInfo.subRows.forEach((plan) => {
                toDelete.add(plan.id);
              });
            }
            setPlansToBeRemove(toDelete);
            toggleModal();
          },
        }}
      />
    </div>
  );
};

export default ActionColumn;
