import { Spacing } from "@able/react";
import { useAppSelector } from "@state/hooks";
import { renderText } from "@utils/helper";

export default function SelectedPlansCount() {
  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );

  return (
    <Spacing bottom="spacing1x" top="spacing2x">
      {renderText(`${selectedPlans.length} item(s) selected`)}
    </Spacing>
  );
}
