import { TextStyle } from "@able/react";
import { IPlan } from "@state/types";
import { renderText } from "@utils/helper";

const generateList = (list, field) => {
  return (
    <TextStyle element="ul">
      {list.map((item) => {
        return (
          <>
            {field === "addOns" &&
            (!item.name || item.category === item.name) ? (
              <li>{item.category}</li>
            ) : (
              <li>{`${item.category} - ${item.name}`}</li>
            )}
          </>
        );
      })}
    </TextStyle>
  );
};

interface IProps {
  plan: IPlan;
  field: "barrings" | "addOns";
}

export default function Selections(props: IProps) {
  const { plan, field } = props;
  const selectionsObject = plan[field] || {};

  const selections = Object.values(selectionsObject);
  if (selections.length === 0) {
    return <>- -</>;
  } else if (selections.length > 1) {
    return generateList(selections, field);
  } else {
    return (
      <div>
        {field === "addOns" &&
        (!selections[0].name ||
          selections[0].category === selections[0].name) ? (
          <div className="selection">{renderText(selections[0].category)}</div>
        ) : (
          <div className="selection">
            {renderText(`${selections[0].category} - ${selections[0].name}`)}
          </div>
        )}
      </div>
    );
  }
}
