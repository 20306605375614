import "./style.scss";
import { Spinner } from "telstra-ui/components/spinner/spinner";
import { IconButton } from "@able/react";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import {
  ableDevelopmentUrl,
  renderText,
  refreshPhoneNumber,
} from "@utils/helper";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { useLazyRefreshNumberQuery } from "@services/numbers";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { setRefreshedPhonePlanId } from "@state/sim-configuration-slice";
import { SIM_CONFIG_ERRORS } from "@utils/common-static-data";

export default function AssignedPhoneNumber({ planId }) {
  const dispatch = useAppDispatch();
  const CONSTANTS = SIM_CONFIG_TABLE.ASSIGNED_PHONE_NUMBER.TOOLTIP;
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlans);
  const planIndex = plansInfo.findIndex(
    (currentPlan) => currentPlan.id === planId
  );
  const plan = plansInfo[planIndex];

  const [refreshNumber, refreshNumberResult] = useLazyRefreshNumberQuery();
  const { isFetching } = refreshNumberResult;
  return (
    <div className="assigned-phone-number">
      {isFetching ? (
        <Spinner size="tl-spinner-medium" />
      ) : (
        <>
          {renderText(plan.phoneNumber)}
          {plan.refreshCount === 2 ? (
            <ToolTip
              title={CONSTANTS.TITLE}
              description={CONSTANTS.DESCRIPTION}
              containerClass="refresh-limit-tooltip"
            >
              <IconButton
                icon="Refresh"
                size="Big"
                developmentUrl={ableDevelopmentUrl}
              />
            </ToolTip>
          ) : (
            <IconButton
              icon="Refresh"
              size="Big"
              developmentUrl={ableDevelopmentUrl}
              events={{
                onClick: () =>
                  refreshPhoneNumber(
                    dispatch,
                    setRefreshedPhonePlanId,
                    planId,
                    refreshNumber,
                    plan,
                    planIndex,
                    SIM_CONFIG_ERRORS
                  ),
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
