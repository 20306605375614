import { Spacing, SectionHeader, TextStyle } from "@able/react";
import ReviewCartChargesTable from "@pages/cart/charges-table-view";
import ReviewCartPlansTable from "@pages/cart/plans-table-view";
import { CHECKOUT } from "@pages/checkout/constant";
import ReviewCartDevicesTable from "@pages/cart/devices-table-view";
import { useAppSelector } from "@state/hooks";

export const YourItems = () => {
  const { normalizedDevicesAndAccesories, normalizedPlans } = useAppSelector(
    (state) => state.cart
  );
  const isDevicesFlow = normalizedDevicesAndAccesories.length > 0;
  const isPlansFlow = normalizedPlans.length > 0;

  return (
    <>
      <SectionHeader title={CHECKOUT.YOUR_ITEMS} variantLevel="Level1" />
      {isPlansFlow && (
        <>
          <TextStyle alias="HeadingC" element="h3">
            {CHECKOUT.PLANS}
          </TextStyle>
          <Spacing top="spacing4x" bottom="spacing10x">
            <ReviewCartPlansTable />
          </Spacing>
          {/* moved this here as we dont have charges table yet in devices flow*/}
          <TextStyle alias="HeadingC" element="h3">
            {CHECKOUT.CHARGES}
          </TextStyle>
        </>
      )}
      {isDevicesFlow && (
        <>
          <TextStyle alias="HeadingC" element="h3">
            {CHECKOUT.DEVICES}
          </TextStyle>
          <Spacing top="spacing4x" bottom="spacing10x">
            <ReviewCartDevicesTable />
          </Spacing>
        </>
      )}
      {isPlansFlow && (
        <Spacing top="spacing4x" bottom="spacing7x">
          <ReviewCartChargesTable />
        </Spacing>
      )}
    </>
  );
};
