import "./style.scss";
import {
  Column,
  Grid,
  MessageSection,
  PageStepper,
  Spacing,
  TextStyle,
  useToggle,
} from "@able/react";
import {
  ableDevelopmentUrl,
  patchCartHandler,
  simConfigPatchCartHandler,
  useReloadHook,
  useGetFeatureFlagHook,
} from "@utils/helper";
import { ERROR_TYPES, NEW_PLAN_ORDER } from "./constant";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AUTOSAVE_MESSAGE,
  CANCEL_DRAFT_ORDER,
  ERRORS,
  PATHS,
  TRY_AGAIN,
} from "@utils/common-static-data";
import CancelDraftOrder from "@components/cancel-draft-order";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import { useLazyPatchCartQuery } from "@services/cart";
import { setCart } from "@state/cart-slice";
import ErrorScreen from "@components/error-screen";
import { useEffect } from "react";
import { setError } from "@state/error";
import AutosaveMessage from "@components/autosave-message";
import { MessageSectionProps } from "@able/react/dist/MessageSection";

export default function NewPlanOrder() {
  const dispatch = useAppDispatch();
  const { SIM_CONFIG_PATCH_CART } = ERROR_TYPES;
  const path = useLocation().pathname;
  const [modalIsOpen, toggleModal] = useToggle();
  const error = useAppSelector((state) => state.cart.error);
  const simConfigError = useAppSelector(
    (state) => state.error.simConfigurationError
  );
  const { enableCancelDraft } = useGetFeatureFlagHook();
  const { errorCode, errorDescription } = error || {};
  const {
    errorType,
    errorCode: simConfigErrorCode,
    errorDescription: simConfigErrorDescription,
  } = simConfigError || {};
  const customError = useAppSelector((state) => state.error.isError);
  const previousPatchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse
  );
  const billingAccount = useAppSelector((state) => state.cart.billingAccount);
  const { paginationState } = useAppSelector((store) => store.simConfiguration);

  const plansInfo = useAppSelector((state) => state.cart.normalizedPlans);
  const requestObjForSimConfiguration = {
    plansInfo,
    previousPatchCartResponse,
  };

  const [patchCart] = useLazyPatchCartQuery();

  const currentStep = path.includes(PATHS.SELECT_PLAN)
    ? 1
    : path.includes(PATHS.BARRING_ADDONS)
    ? 2
    : path.includes(PATHS.CONFIGURE_SIM)
    ? 3
    : 0;
  const itemsInCart = useAppSelector((state) => state.selectPlans);
  const { cartId } = useParams();
  const navigate = useNavigate();

  useReloadHook();

  const getPageTitle = () => {
    switch (currentStep) {
      case 1:
        return NEW_PLAN_ORDER.SELECT_PLAN_TAGLINE;
      case 2:
        return NEW_PLAN_ORDER.BARRING_ADDONS_TAGLINE;
      case 3:
        return NEW_PLAN_ORDER.SIM_CONFIGURATION_TAGLINE;
      default:
        return "";
    }
  };

  const cancelDraftOrderProps: Partial<MessageSectionProps> = enableCancelDraft
    ? {
        actionText: CANCEL_DRAFT_ORDER.CANCEL_DRAFT_ORDER_BUTTON,
        actionElement: "button",
        actionCallback: () => toggleModal(),
      }
    : {};

  const cardActionHandler = async (e) => {
    e.preventDefault();
    if (path.includes(PATHS.SELECT_PLAN)) {
      patchCartHandler(
        patchCart,
        itemsInCart,
        dispatch,
        setCart,
        navigate,
        PATHS.BARRING_ADDONS,
        cartId,
        billingAccount
      );
    } else if (path.includes(PATHS.CONFIGURE_SIM)) {
      simConfigPatchCartHandler(
        dispatch,
        navigate,
        plansInfo,
        paginationState,
        requestObjForSimConfiguration,
        patchCart,
        cartId
      );
    }
  };

  // If error code is 500, show MessageSection and don't hide Header, Footer
  useEffect(() => {
    if (
      errorCode === 500 ||
      (simConfigErrorCode === 500 && errorType === SIM_CONFIG_PATCH_CART)
    ) {
      dispatch(setError(false));
    } else if (errorCode === 400 || simConfigErrorCode === 400) {
      dispatch(setError(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, simConfigError]);

  if (errorCode === 400 || simConfigErrorCode === 400) {
    return (
      <ErrorScreen
        error={errorCode === 400 ? error : simConfigError}
        customErrorMsg={
          ERRORS[errorCode === 400 ? errorCode : simConfigErrorCode]
        }
        showTryAgain={false}
      />
    );
  }

  return (
    <div className="new-plan-order">
      {(errorCode === 500 ||
        (simConfigErrorCode === 500 &&
          errorType === SIM_CONFIG_PATCH_CART)) && (
        <Spacing top="spacing2x">
          <Grid>
            <Column cols={12} bsm={12} bmd={12} blg={12}>
              <MessageSection
                variant="Error"
                developmentUrl={ableDevelopmentUrl}
                description={
                  errorCode === 500
                    ? errorDescription
                    : simConfigErrorDescription
                }
                actionText={TRY_AGAIN}
                actionLink={path}
                className="message-section"
                actionCallback={cardActionHandler}
              />
            </Column>
          </Grid>
        </Spacing>
      )}
      {!customError && (
        <>
          <Spacing top="spacing2x">
            <AutosaveMessage
              columnProps={{ bmd: 4 }}
              messageSectionProps={{
                description: AUTOSAVE_MESSAGE,
                variant: "Success",
                ...cancelDraftOrderProps,
              }}
            />
          </Spacing>
          <Spacing top="spacing4x">
            <PageStepper
              variant="Fixed"
              numberOfSteps={3}
              currentStep={currentStep}
              pageTitle={NEW_PLAN_ORDER.PAGE_TITLE}
              pageTagline={getPageTitle()}
            />
            <Grid>
              {currentStep === 3 && (
                <Spacing top="spacing2x" bottom="spacing2x">
                  <TextStyle alias="BaseBig">
                    {NEW_PLAN_ORDER.SIM_CONFIGURATION_ASSISTIVE_TEXT}
                  </TextStyle>
                </Spacing>
              )}
            </Grid>
            {enableCancelDraft && (
              <CancelDraftOrder
                modalIsOpen={modalIsOpen}
                toggleModal={toggleModal}
              />
            )}
          </Spacing>
        </>
      )}
      <Outlet />
    </div>
  );
}
