export const BREADCRUMB = {
  ACCOUNT: "Account",
  LANDING_PAGE: "Landing page",
  NEW_ORDER_DETAILS: "New order details",
  SELECT_PLAN: "Select plans",
  BARRING_ADDONS: "Barring & add-ons",
  CONFIGURE_SIM: "SIM configuration",
  CART_PAGE: "Cart",
  CHECKOUT: "Checkout",
  SELECT_DEVICES: "Select devices and accessories",
  CONFIGURE_DEVICES: "Configure devices and accessories",
};

export const CHARGE_SUMMARY = {
  CHARGE_SUMMARY_TITLE: "Charge summary",
  RECURING_CHARGE_TITLE: "Recurring charge",
  ONE_OFF_CHARGE_TITLE: "One-off charge",
  TOTAL_CHARGE_TITLE: "Total charge(First month)",
};

export const AUTOSAVE_MESSAGE = "All changes saved at 12:30pm";

export const PATHS = {
  LANDING_PAGE: "/",
  NEW_ORDER_DETAILS: "/new-order-details",
  SELECT_PLAN: "/select-plan",
  BARRING_ADDONS: "/configure-barring-addons",
  CONFIGURE_SIM: "/configure-sim",
  CART: "/cart",
  CHECKOUT: "/checkout",
  ORDER_CONFIRMATION: "/order-confirmation",
  SELECT_DEVICES: "/select-devices",
  CONFIGURE_DEVICES: "/configure-devices-and-accessories",
};

export const CANCEL_DRAFT_ORDER = {
  CANCEL_DRAFT_ORDER_BUTTON: "Cancel draft order",
  MODAL_TITLE: "Cancel draft order",
  CANCELLATION_REASON_GROUP_LABEL: "Cancellation reason",
  DUPLICATE_ENTRY: "Duplicate entry",
  OTHER: "Other",
  OTHER_REASON_TEXT_AREA: "Other reason",
  CANCEL_BUTTON: "Cancel",
};

export const CANCEL_DRAFT_ORDER_CANCELLATION_REASONS = [
  { ID: "duplicateEntry", LABEL: "Duplicate entry" },
  { ID: "technicalSystemIssue", LABEL: "Technical/system issue" },
  { ID: "noLongerRequired", LABEL: "No longer required" },
  { ID: "partnerOpportunityDeclined", LABEL: "Partner opportunity declined" },
  { ID: "other", LABEL: "Other" },
];

export const PICTO = {
  ACCESSORIES: "Accessories",
  SIM: "SIM",
  MOBILE_56: "Mobile56",
};

export const CART = {
  ATTRIBUTES: {
    SUBMISSION_DATE: "submissionDate",
    TELSTRA_INTERNAL_REFERENCE: "telstraInternalOrderReference",
    ORDER_REFERENCE: "orderReference",
    BILLING_ACCOUNT_NUMBER: "BillingAccount",
    CUSTOMER: "Customer",
    REQUESTER: "Requester",
  },
  ITEM_PLACE_ATTRIBUTES: {
    TYPE: "delivery",
    ID_TYPE: "adbor",
    CONTACT_ID: "ContactId",
  },
  PRODUCTS: {
    PAYMENT_24_MONTHS: "twentyfourMonthPayment",
    PAYMENT_12_MONTHS: "twelveMonthPayment",
  },
};

export const TRY_AGAIN = "Try again";

export const ERRORS = {
  400: "Our server could not complete your request. Please submit a support enquiry and quote error code 400.",
  500: "Unable to save cart. Try again. If this error persists, submit a support enquiry and quote error code 500.",
};

export const SIM_CONFIG_ERRORS = {
  500: {
    FETCH_NUMBERS:
      "Unable to fetch numbers. Try again. If that does not fix it, submit a support enquiry and quote error code 500.",
    ASSIGN_NUMBERS:
      "Number reservation failed. Try again. If that does not fix it, submit a support enquiry and quote error code 500.",
  },
  200: {
    PARTIAL_SUCCESS:
      "Number reservation failed. Try refreshing or changing the number. If that does not fix it, submit a support enquiry and quote error code 200. Or remove the item to proceed.",
  },
};

export const CUSTOM_ERRORS = {
  1000: {
    errorCode: 1000,
    errorDescription: "This cart is empty. Please add items to proceed.",
  },
};
