import "../style.scss";
import { Spacing, TextStyle, Grid, Column, InlineLink } from "@able/react";
import { renderText } from "@utils/helper";
import { useLocation } from "react-router-dom";
import { FIELDS } from "../constants";
import { useCartAttributes } from "@utils/cart";

export default function OrderDetails() {
  const path = useLocation().pathname;
  const { LABELS } = FIELDS;
  const {
    cartId,
    submissionDate,
    orderConfirmationEmailedTo,
    customerName,
    cidn,
    billingAccount,
    requester,
    internalReferenceNumber,
    orderReferenceNumber,
  } = useCartAttributes().getAllAttributes();

  const ORDER_CONFIRMATION_PAGE_DETAILS: {
    header: string;
    value: string;
    inLineLink?: boolean;
  }[] = [
    {
      header: LABELS.ORDER_NUMBER,
      value: cartId,
      inLineLink: true,
    },
    {
      header: LABELS.SUBMISSION_DATE,
      value: submissionDate,
    },
    {
      header: LABELS.ORDER_CONFIRMATION,
      value: orderConfirmationEmailedTo,
    },
    {
      header: LABELS.NAME,
      value: customerName,
    },
    {
      header: LABELS.CIDN,
      value: cidn,
    },
    {
      header: LABELS.BILLING_ACCOUNT,
      value: billingAccount,
    },
    {
      header: LABELS.REQUESTER,
      value: requester,
    },
    {
      header: LABELS.INTERNAL_REFERENCE_NUMBER,
      value: internalReferenceNumber,
    },
    {
      header: LABELS.ORDER_REFERENCE,
      value: orderReferenceNumber,
    },
  ];

  return (
    <div className="customer-details">
      <Spacing top="spacing5x" left="spacing5x" right="spacing5x">
        <Grid>
          {ORDER_CONFIRMATION_PAGE_DETAILS.map((props) => (
            <Column cols={12} bsm={4} bmd={4} blg={4} key={props.header}>
              <Spacing bottom="spacing5x">
                <TextStyle alias="Label">{props.header}</TextStyle>
                <Spacing top="spacing1x">
                  {props.inLineLink ? (
                    <InlineLink>
                      <TextStyle alias="Base">
                        <a href={path}>{props.value}</a>
                      </TextStyle>
                    </InlineLink>
                  ) : (
                    renderText(props.value)
                  )}
                </Spacing>
              </Spacing>
            </Column>
          ))}
        </Grid>
      </Spacing>
    </div>
  );
}
