import { DeviceProduct } from "@state/types";
import GridItem from "./grid-item";
import { Grid, Spacing, TextStyle } from "@able/react";
import { ReactElement } from "react";

type Props = {
  devices: DeviceProduct[];
  toggleButton: ReactElement;
};

export function GridView({ devices, toggleButton }: Props) {
  return (
    <Grid>
      <Spacing bottom="spacing5x" className={["toggle-button-section"]}>
        <TextStyle alias="HeadingD">
          {`Showing ${devices.length} of ${devices.length} Mobile devices in Mobile category`}
        </TextStyle>
        {toggleButton}
      </Spacing>
      {devices.map((device) => (
        <GridItem key={device.id} device={device} />
      ))}
    </Grid>
  );
}
