import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  deleteSelectedNumbers,
  setAvailableNumbers,
} from "@state/sim-configuration-slice";
import { MessageInline } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);
  const minute = minutes < 10 ? "0" + minutes : minutes;
  const second = seconds < 10 ? "0" + seconds : seconds;
  return minute + ":" + second;
};

export default function Countdown({
  seconds,
  setSearchFormState,
  resetCounter,
}) {
  const dispatch = useAppDispatch();
  const [countdown, setCountdown] = useState(seconds);
  const timerId = useRef(null);
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );
  const totalPlansSelected = selectedPlans.length.toString();

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(timerId.current);
      setSearchFormState({
        quantity: totalPlansSelected,
        patternType: "",
        pattern: "",
      });
      dispatch(setAvailableNumbers([]));
      dispatch(deleteSelectedNumbers());
    }
  });

  useEffect(() => {
    setCountdown(seconds);
  }, [resetCounter, seconds]);

  return (
    <MessageInline
      variant="Information"
      developmentUrl={ableDevelopmentUrl}
      className="messagebox"
    >
      {NEW_PLAN_ORDER.CUSTOM_NUMBER.COUNTDOWN_1}
      {formatTime(countdown)}
      {NEW_PLAN_ORDER.CUSTOM_NUMBER.COUNTDOWN_2}
    </MessageInline>
  );
}
