import React, { useEffect, useState } from "react";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import AvailableNumberCheckbox from "./available-number-checkbox";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleAllNumbers } from "@state/sim-configuration-slice";
import { Spacing, TextStyle } from "@able/react";
import { NEW_PLAN_ORDER } from "../../../../constant";
import { renderText } from "@utils/helper";

const AvailableNumberTable = ({ quantity }) => {
  const dispatch = useAppDispatch();
  const selectedPlans = useAppSelector(
    (store) => store?.commonStore?.selectedPlans
  );
  const { selectedNumbers, availableNumbers } = useAppSelector(
    (store) => store?.simConfiguration
  );
  const [paginationState, setPaginationState] = useState(
    NEW_PLAN_ORDER.CUSTOM_NUMBER.DEFAULT_PAGINATION_STATE
  );

  const [pageSizeOptions, setPageSizeOptions] = useState(
    NEW_PLAN_ORDER.CUSTOM_NUMBER.PAGE_SIZE_OPTIONS
  );

  useEffect(() => {
    if (availableNumbers?.length) {
      getTableRecord(1, []);
      const maxPageSizeIndex =
        NEW_PLAN_ORDER.CUSTOM_NUMBER.PAGE_SIZE_OPTIONS.indexOf(
          NEW_PLAN_ORDER.CUSTOM_NUMBER.PAGE_SIZE_OPTIONS.filter((pageSize) => {
            return Number(pageSize) > availableNumbers.length;
          })[0] + ""
        );
      setPageSizeOptions(
        NEW_PLAN_ORDER.CUSTOM_NUMBER.PAGE_SIZE_OPTIONS.slice(
          0,
          maxPageSizeIndex
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableNumbers]);

  const handleToggleAll = () => {
    const numberIds = availableNumbers.map((item) => {
      return item.number;
    });
    dispatch(toggleAllNumbers(numberIds));
  };

  const getTableRecord = (
    pageNum,
    sorted = [{}],
    pageSizeVal = 0,
    event = ""
  ) => {
    const totalNumbers = availableNumbers?.length;
    if (totalNumbers) {
      let pSize = paginationState.pageSize;
      const resultData = [];
      let lastIndex, startIndex;

      pSize = pageSizeVal > 0 ? pageSizeVal : pSize;

      lastIndex = pageNum * pSize;

      if (lastIndex > totalNumbers) {
        lastIndex = totalNumbers;
      }

      startIndex = lastIndex - pSize;

      if (pSize * pageNum > totalNumbers) {
        startIndex = pSize * (pageNum - 1);
      }

      if (pageNum === 1 && pSize > totalNumbers) {
        lastIndex = totalNumbers;
        startIndex = 0;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        resultData.push(availableNumbers[i]);
      }

      setPaginationState({
        data: resultData,
        pageSize: pSize,
        totalRecordLength: totalNumbers,
        pages: Math.ceil(totalNumbers / pSize),
        page: pageNum,
        recordsPerApi: pSize,
      });
    }
  };

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-center",
      Header: (
        <Checkbox
          id="toggle-all-numbers"
          checked={selectedNumbers?.length === availableNumbers?.length}
          onChange={() => {
            handleToggleAll();
          }}
          disabled={quantity > selectedPlans?.length}
        />
      ),
      accessor: "checked",
      resizable: false,
      minWidth: 50,
      minResizeWidth: 150,
      pinned: true,
      Cell: (rowData) => (
        <AvailableNumberCheckbox item={rowData?.original} quantity={quantity} />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: <div className="text-icon-wrapper">Available phone numbers</div>,
      accessor: "availableNumbers",
      resizable: true,
      minWidth: 300,
      minResizeWidth: 250,
      Cell: (rowData) => renderText(rowData?.original?.number),
    },
  ];

  return (
    <>
      {paginationState.data.length > 0 && (
        <Spacing bottom="spacing1x">
          <TextStyle element="div">
            {`${selectedNumbers?.length} of ${selectedPlans?.length} item(s) selected`}
          </TextStyle>
        </Spacing>
      )}
      <AdvanceTableV3
        columns={columnData}
        data={paginationState.data}
        totalRecordLength={paginationState.totalRecordLength}
        pages={paginationState.pages}
        pageSize={paginationState.pageSize}
        defaultPageSize={paginationState.pageSize}
        sendApi={getTableRecord}
        page={paginationState.page}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};

export default AvailableNumberTable;
