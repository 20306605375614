/* eslint-disable react-hooks/exhaustive-deps */
import { Column, Spacing } from "@able/react";
import { ToggleButton } from "telstra-ui/components/toggle/ToggleButton";
import { useEffect, useState } from "react";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import MobilePlans from "./plans-by-subcategory";
import { useAppSelector } from "@state/hooks";
import { IPlan } from "@state/types";

const PlansByCategory = () => {
  const CONSTANTS = NEW_PLAN_ORDER.CONFIGURE_PLAN_BARRING_ADD_ONS;
  const plans: {
    [index: string]: IPlan;
  } = useAppSelector((store) => store.cart.plans);

  const [activeCategory, setActiveCategory] = useState("");

  const plansByCategory = new Map();
  const categories = [];

  Object.values(plans).forEach((currentPlan) => {
    const category = currentPlan.category;
    if (plansByCategory.has(category)) {
      plansByCategory.get(category).push(currentPlan);
    } else {
      categories.push(category);
      plansByCategory.set(category, [currentPlan]);
    }
  });

  const orderedCategories = CONSTANTS.ALL_CATEGORIES.filter((item) =>
    categories.includes(item)
  );

  useEffect(() => {
    setActiveCategory(orderedCategories[0]);
  }, [orderedCategories[0]]);

  const options = orderedCategories.map((category) => {
    return {
      id: category,
      label: `${CONSTANTS.CATEGORY_LABELS[category]} (${
        (plansByCategory.get(category) || []).length
      })`,
      value: category,
      active: activeCategory === category,
      width: 500,
    };
  });

  return (
    <>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <Spacing top="spacing5x" bottom="spacing3x">
          <ToggleButton
            id="categoryTab"
            size="large"
            options={options}
            value={activeCategory}
            selectionMode="single"
            onChange={(option) => {
              setActiveCategory(option.value);
            }}
          />
          <Spacing top="spacing3x">
            {plansByCategory.get(activeCategory) && (
              <MobilePlans
                plans={plansByCategory.get(activeCategory)}
                category={activeCategory}
              />
            )}
          </Spacing>
        </Spacing>
      </Column>
    </>
  );
};

export default PlansByCategory;
