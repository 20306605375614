import "./main.scss";
import "../node_modules/@able/web/src/able-web.scss";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NewOrderDetails from "@pages/new-order-details";
import LandingPageComponent from "@pages/landing-page";
import App from "./app";
import ErrorComponent from "@components/error-element";
import Cart from "@pages/cart";
import NewPlanOrder from "@pages/new-plan-order";
import { PATHS } from "@utils/common-static-data";
import Checkout from "@pages/checkout";
import OrderConfirmation from "@pages/order-confirmation";
import SelectPlan from "@pages/new-plan-order/steps/select-plan";
import ConfigurePlanBarringAddOns from "@pages/new-plan-order/steps/configure-plan-barring-add-ons";
import SIMConfiguration from "@pages/new-plan-order/steps/sim-configuration";
import { buildPath } from "@utils/helper";
import { Provider } from "react-redux";
import { store } from "./state/store";
import DevicesAndAccessories from "@pages/devices-and-accessories";
import SelectDevices from "@pages/devices-and-accessories/steps/select-devices";
import ConfigureDeviceAndAccessories from "@pages/devices-and-accessories/steps/configure-device-and-accessories";

export default function Main(props) {
  const param = ":cartId";
  const router = createBrowserRouter([
    {
      path: PATHS.LANDING_PAGE,
      element: (
        <Provider store={store}>
          <App />
        </Provider>
      ),
      errorElement: (
        <Provider store={store}>
          <ErrorComponent />
        </Provider>
      ),
      children: [
        {
          index: true,
          element: <LandingPageComponent {...props} />,
        },
        {
          path: PATHS.NEW_ORDER_DETAILS,
          element: <NewOrderDetails />,
        },
        {
          element: <NewPlanOrder />,
          children: [
            {
              path: buildPath(PATHS.SELECT_PLAN, param),
              element: <SelectPlan />,
            },
            {
              path: buildPath(PATHS.BARRING_ADDONS, param),
              element: <ConfigurePlanBarringAddOns />,
            },
            {
              path: buildPath(PATHS.CONFIGURE_SIM, param),
              element: <SIMConfiguration />,
            },
          ],
        },
        {
          path: buildPath(PATHS.CART, param),
          element: <Cart />,
        },
        {
          element: <DevicesAndAccessories />,
          children: [
            {
              path: buildPath(PATHS.SELECT_DEVICES, param),
              element: <SelectDevices />,
            },
            {
              path: buildPath(PATHS.CONFIGURE_DEVICES, param),
              element: <ConfigureDeviceAndAccessories />,
            },
          ],
        },
        {
          path: buildPath(PATHS.CHECKOUT, param),
          element: <Checkout />,
        },
        {
          path: buildPath(PATHS.ORDER_CONFIRMATION, param),
          element: <OrderConfirmation />,
        },
      ],
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
