import { useAppSelector } from "@state/hooks";
import { useGetFeatureFlagHook } from "@utils/helper";
import { NormalizedDevice, PatchCart, NormalizedPlan } from "@state/types";
import { CART } from "./common-static-data";
import { CONSTANTS } from "@services/constant";
import { CART_PLANS_TABLE } from "@pages/cart/constant";

export const useShouldEnableGetCartHook = () => {
  const patchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse.cartItems
  );
  const isPatchCartResponseAvailable =
    patchCartResponse && patchCartResponse.length > 0;
  const { getCartEnabled } = useGetFeatureFlagHook();
  return getCartEnabled && !isPatchCartResponseAvailable;
};

export function getNormalizedPlans(data: PatchCart) {
  const plans: NormalizedPlan[] = [];
  const addonsArray: NormalizedPlan[] = [];
  const index = {};
  const { cartItems } = data;

  for (const cartItem of cartItems) {
    const {
      id,
      action,
      productOffering,
      itemRelationships,
      itemAttributes,
      quantity,
    } = cartItem;
    const plan: NormalizedPlan = {
      id,
      action,
      quantity,
      itemRelationships,
      planName: productOffering.name,
      addOns: {},
      category: null,
      subCategory: null,
      phoneNumber: "",
      simType: "",
      simSerialNumber: "",
      username: "",
      invalid_UNMS_SIM_number: false,
      invalid_SIM_number: false,
      invalid_username: false,
      invalid_SIM_type: false,
      refreshCount: 0,
      planUpdated: false,
      marketSegments: productOffering.marketSegments,
      productOfferingId: productOffering.id,
    };

    if (
      productOffering.id === CONSTANTS.PRODUCT_IDS.AM_PLAN ||
      itemRelationships.filter((v) => v.relationshipType === "Bundled").length >
        0
    ) {
      for (const itemAttribute of itemAttributes) {
        if (itemAttribute.name === "Alias") {
          plan.username = itemAttribute.value;
        }
      }
      const { characteristics } = productOffering;
      for (const characteristic of characteristics) {
        if (characteristic.name === "PlanType") {
          plan.category = characteristic.value;
        }
        if (characteristic.name === "MobilityPlan") {
          plan.subCategory = characteristic.value;
        }
        if (characteristic.name === "CustomerFacingServiceId") {
          plan.phoneNumber = characteristic.value;
        }
        if (characteristic.name === "SIMSerialNumber") {
          plan.simSerialNumber = characteristic.value;
          plan.simType = CART_PLANS_TABLE.EXISTING_SIM;
        }
        if (characteristic.name === "SIMType" && !plan.simSerialNumber) {
          plan.simType = characteristic.value;
        }
      }
      if (plan.category) {
        plans.push(plan);
        index[plan.id] = plans.length - 1;
      } else {
        addonsArray.push(plan);
      }
    }
  }

  for (const addon of addonsArray) {
    let planId;
    for (const relationship of addon.itemRelationships) {
      if (relationship.relationshipType === "Bundled") {
        planId = relationship.id;
      }
    }
    if (plans[index[planId]]) {
      plans[index[planId]].addOns[addon.id] = {
        category: addon.category,
        name: addon.planName,
      };
    }
  }

  return plans;
}

export function useCartAttributes() {
  const { ATTRIBUTES } = CART;
  const {
    cartId,
    cartItems = [],
    relatedParties = [],
    cartAttributes = [],
  } = useAppSelector((state) => state.cart.patchCartResponse);

  function getCartId() {
    return cartId;
  }

  function getAllCartAttributes() {
    let submissionDate;
    let internalReferenceNumber;
    let orderReferenceNumber;
    for (const cartAttribute of cartAttributes) {
      if (cartAttribute.name === ATTRIBUTES.SUBMISSION_DATE) {
        submissionDate = cartAttribute.value;
      }
      if (cartAttribute.name === ATTRIBUTES.TELSTRA_INTERNAL_REFERENCE) {
        internalReferenceNumber = cartAttribute.value;
      }
      if (cartAttribute.name === ATTRIBUTES.ORDER_REFERENCE) {
        orderReferenceNumber = cartAttribute.value;
      }
    }
    return { submissionDate, internalReferenceNumber, orderReferenceNumber };
  }

  function getCartAttribute(attribute) {
    for (const cartAttribute of cartAttributes) {
      if (cartAttribute.name === attribute) {
        return cartAttribute.value;
      }
    }
    return undefined;
  }

  function getAllItemAttributes() {
    let billingAccount;
    if (cartItems.length) {
      for (const itemAttribute of cartItems[0].itemAttributes) {
        if (itemAttribute.name === ATTRIBUTES.BILLING_ACCOUNT_NUMBER) {
          billingAccount = itemAttribute.value;
          break;
        }
      }
    }
    return { billingAccount };
  }

  function getItemAttribute(attribute) {
    if (cartItems.length) {
      for (const itemAttribute of cartItems[0].itemAttributes) {
        if (itemAttribute.name === attribute) {
          return itemAttribute.value;
        }
      }
    }
    return undefined;
  }

  function getAllRelatedParties() {
    let cidn;
    let customerName;
    let requester;
    let requesterId;
    let orderConfirmationEmailedTo;
    for (const relatedParty of relatedParties) {
      const { id, name } = relatedParty;
      if (relatedParty["@referredType"] === ATTRIBUTES.CUSTOMER) {
        cidn = id;
        customerName = name;
      }
      if (relatedParty["@referredType"] === ATTRIBUTES.REQUESTER) {
        requester = name;
        requesterId = id;
        orderConfirmationEmailedTo = name;
      }
    }
    return {
      cidn,
      customerName,
      requester,
      requesterId,
      orderConfirmationEmailedTo,
    };
  }

  function getRelatedParty(referredType) {
    for (const relatedParty of relatedParties) {
      if (relatedParty["@referredType"] === referredType) {
        return relatedParty;
      }
    }
    return undefined;
  }

  function getAllAttributes() {
    return {
      cartId,
      ...getAllCartAttributes(),
      ...getAllRelatedParties(),
      ...getAllItemAttributes(),
    };
  }

  return {
    getCartId,
    getAllAttributes,
    getAllCartAttributes,
    getCartAttribute,
    getAllItemAttributes,
    getItemAttribute,
    getAllRelatedParties,
    getRelatedParty,
  };
}

export function getNormalizedDevicesAndAccessories(data) {
  const devices: NormalizedDevice[] = [];

  const { cartItems } = data;

  for (const cartItem of cartItems) {
    const {
      id,
      quantity,
      productOffering,
      itemRelationships,
      itemAttributes,
      itemPrices,
    } = cartItem;
    const device: NormalizedDevice = {
      id,
      quantity,
      name: "",
      category: "",
      sku: "",
      technologyType: "",
      color: "",
      manufacturer: "",
      outright: 0,
      twelveMonthPayment: 0,
      twentyfourMonthPayment: 0,
      paymentType: CONSTANTS.PAYMENT_TYPE.OUTRIGHT,
      isPaymentTypeSelected: true,
      itemRelationships,
      marketSegments: productOffering.marketSegments,
      productOfferingId: productOffering.id,
      initialValues: {
        paymentType: "",
      },
    };

    if (
      productOffering.id === CONSTANTS.PRODUCT_IDS.DEVICES ||
      productOffering.id === CONSTANTS.PRODUCT_IDS.ACCESSORIES
    ) {
      device.name = productOffering.name;

      for (const itemAttribute of itemAttributes) {
        if (itemAttribute.name === "category") {
          device.category = itemAttribute.value;
        }
      }
      for (const itemPrice of itemPrices) {
        if (itemPrice?.priceType === "Once-Off") {
          if (CART.PRODUCTS.PAYMENT_12_MONTHS in itemPrice) {
            device.twelveMonthPayment =
              itemPrice[CART.PRODUCTS.PAYMENT_12_MONTHS];
          }
          if (CART.PRODUCTS.PAYMENT_24_MONTHS in itemPrice) {
            device.twentyfourMonthPayment =
              itemPrice[CART.PRODUCTS.PAYMENT_24_MONTHS];
          }
          if ("unitPrice" in itemPrice) {
            device.outright = itemPrice?.unitPrice;
          }
        }
      }

      const { characteristics } = productOffering;
      for (const characteristic of characteristics) {
        if (characteristic.name === CONSTANTS.CHARACTERISTICS.TECHNOLOGY_TYPE) {
          device.technologyType = characteristic.value;
        }

        if (productOffering.id === CONSTANTS.PRODUCT_IDS.DEVICES) {
          if (characteristic.name === CONSTANTS.CHARACTERISTICS.DEVICE_COLOR) {
            device.color = characteristic.value;
          }
          if (
            characteristic.name ===
            CONSTANTS.CHARACTERISTICS.DEVICE_MANUFACTURER
          ) {
            device.manufacturer = characteristic.value;
          }
        }

        if (productOffering.id === CONSTANTS.PRODUCT_IDS.ACCESSORIES) {
          if (
            characteristic.name === CONSTANTS.CHARACTERISTICS.ACCESSORIES_COLOR
          ) {
            device.color = characteristic.value;
          }
          if (
            characteristic.name ===
            CONSTANTS.CHARACTERISTICS.ACCESSORIES_MANUFACTURER
          ) {
            device.manufacturer = characteristic.value;
          }
        }
        if (characteristic.name === CONSTANTS.CHARACTERISTICS.SKU) {
          device.sku = characteristic.value;
        }
        if (characteristic.name === CONSTANTS.CHARACTERISTICS.CONTRACT_TYPE) {
          if (characteristic.value === CONSTANTS.PAYMENT_TYPE.PURCHASE) {
            device.paymentType = CONSTANTS.PAYMENT_TYPE.OUTRIGHT;
          } else if (
            characteristic.value === CONSTANTS.PAYMENT_TYPE.HARDWARE_REPAYMENT
          ) {
            const contactTerm = characteristics.find(
              (char) => char.name === CONSTANTS.CHARACTERISTICS.CONTRACT_TERM
            );
            if (contactTerm) {
              device.paymentType =
                +contactTerm.value === 12
                  ? CART.PRODUCTS.PAYMENT_12_MONTHS
                  : CART.PRODUCTS.PAYMENT_24_MONTHS;
            }
          }
        }
      }
      device.initialValues.paymentType = device.paymentType;
      devices.push(device);
    }
  }

  return devices;
}
