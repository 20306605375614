import "./style.scss";
import { Column, Grid, IconButton, TextStyle } from "@able/react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";
import {
  ableDevelopmentUrl,
  changeColorCase,
  prefixWithDollarSign,
  renderBoldText,
  useIntersectionObserver,
} from "@utils/helper";
import { deleteSelectedDevice } from "@state/select-devices-slice";
import { useEffect, useRef, useState } from "react";
import ViewItemsFloater from "../view-items-floater";
import StockStatus from "../stock-status";

const CONSTANTS = SELECT_DEVICES.TABLE_COLUMN_HEADERS;

const columns = [
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.MODEL),
    resizable: false,
    minWidth: 337,
    maxWidth: 337,
    Cell: (rowData) => {
      return (
        <div>
          <TextStyle alias="Tag">
            {rowData?.original?.category?.toUpperCase()}
          </TextStyle>
          <TextStyle alias="HeadingD">{rowData?.original?.name}</TextStyle>
        </div>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.STORAGE),
    resizable: false,
    minWidth: 88,
    maxWidth: 88,
    Cell: (rowData) => {
      return <TextStyle alias="Base">--</TextStyle>;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.COLOUR),
    resizable: false,
    minWidth: 160,
    maxWidth: 160,
    Cell: (rowData) => {
      return (
        <TextStyle alias="Base">
          {changeColorCase(rowData?.original?.color)}
        </TextStyle>
      );
    },
  },

  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.AVAILABILITY),
    resizable: false,
    minWidth: 130,
    maxWidth: 130,
    Cell: (rowData) => {
      return <StockStatus product={rowData?.original} showMessage={false} />;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-right ",
    Header: renderBoldText(CONSTANTS.UNIT_CHARGE),
    resizable: false,
    minWidth: 220,
    maxWidth: 220,
    Cell: (rowData) => {
      return (
        <TextStyle alias="Label">
          {prefixWithDollarSign(rowData?.original?.price)}
        </TextStyle>
      );
    },
  },
  {
    headerClassName: "table-align-left quantity-header",
    className: "td-align-center",
    Header: renderBoldText(CONSTANTS.QUANTITY),
    resizable: false,
    minWidth: 116,
    maxWidth: 116,
    Cell: (rowData) => {
      return <TextStyle alias="Base">{rowData?.original?.quantity}</TextStyle>;
    },
  },
];

const SelectedDevicesTable = () => {
  const dispatch = useAppDispatch();

  const { selectedDevices } = useAppSelector((state) => state.selectDevices);

  const tableRef = useRef(null);
  const isTableVisible = useIntersectionObserver(tableRef, { threshold: 0 });

  const [paginationDetails, setPaginationDetails] = useState({
    data: [],
    pageSize: 10,
    totalRecordLength: 0,
    pages: 0,
    page: 1,
    recordsPerApi: 10,
  });

  const generateTableData = (
    pageNum,
    sorted = [{}],
    pageSizeVal = 0,
    event = ""
  ) => {
    const totalDevices = selectedDevices?.length;

    if (totalDevices) {
      let pSize = paginationDetails.pageSize;
      const resultData = [];
      let lastIndex, startIndex;

      pSize = pageSizeVal > 0 ? pageSizeVal : pSize;

      lastIndex = pageNum * pSize;

      if (lastIndex > totalDevices) {
        lastIndex = totalDevices;
      }

      startIndex = lastIndex - pSize;

      if (pSize * pageNum > totalDevices) {
        startIndex = pSize * (pageNum - 1);
        if (startIndex === lastIndex) {
          startIndex -= pSize;
          pageNum--;
        }
      }

      if (pageNum === 1 && pSize > totalDevices) {
        lastIndex = totalDevices;
        startIndex = 0;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        resultData.push(selectedDevices[i]);
      }

      setPaginationDetails({
        data: resultData,
        pageSize: pSize,
        totalRecordLength: totalDevices,
        pages: Math.ceil(totalDevices / pSize),
        page: pageNum,
        recordsPerApi: pSize,
      });
    }
  };

  useEffect(() => {
    generateTableData(paginationDetails.page, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevices]);

  return (
    <div className="selected-devices-table" ref={tableRef}>
      <Grid>
        <Column>
          <AdvanceTableV3
            columns={columns}
            resizable={true}
            data={paginationDetails.data}
            totalRecordLength={paginationDetails.totalRecordLength}
            pages={paginationDetails.pages}
            page={paginationDetails.page}
            pageSize={paginationDetails.pageSize}
            defaultPageSize={paginationDetails.pageSize}
            className="td-align-left"
            pageHeaderClassName={"header"}
            isStickyHeader={true}
            sendApi={generateTableData}
            action={{
              sticky: true,
              column: {
                headerClassName: "table-align-left",
                className: "td-align-left ",
                Header: renderBoldText(CONSTANTS.REMOVE),
                resizable: false,
                minWidth: 95,
                maxWidth: 95,
                Cell: (rowData) => {
                  return (
                    <IconButton
                      variant="Destructive"
                      icon="Calendar"
                      size="Big"
                      developmentUrl={ableDevelopmentUrl}
                      events={{
                        onClick: () => {
                          dispatch(deleteSelectedDevice(rowData.original.id));
                        },
                      }}
                    />
                  );
                },
              },
            }}
          />
        </Column>
      </Grid>
      {!isTableVisible && <ViewItemsFloater />}
    </div>
  );
};
export default SelectedDevicesTable;
