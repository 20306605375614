import "./style.scss";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import { IPlan } from "@state/types";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { renderText } from "@utils/helper";
import { TextStyle } from "@able/react";

const dotSpace = <>&#x2022; &nbsp;</>;

const generateList = (list, field) => {
  return (
    <div>
      {list.map((item) => {
        return (
          <div key={`${item.category}-${item.name}`}>
            {field === "addOns" &&
            (!item.name || item.category === item.name) ? (
              <div className="selection">
                {dotSpace} {renderText(item.category)}
              </div>
            ) : (
              <div className="selection">
                {dotSpace} {renderText(`${item.category} - ${item.name}`)}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

interface IProps {
  plan: IPlan;
  field: "barrings" | "addOns";
}

export default function Selections(props: IProps) {
  const { plan, field } = props;
  const selectionsObject = plan[field] || {};

  const selections = Object.values(selectionsObject);
  if (selections.length === 0) {
    return <>- -</>;
  } else if (selections.length < 3) {
    return generateList(selections, field);
  } else {
    return (
      <div>
        {field === "addOns" &&
        (!selections[0].name ||
          selections[0].category === selections[0].name) ? (
          <div className="selection">
            {dotSpace} {renderText(selections[0].category)}
          </div>
        ) : (
          <div className="selection">
            {dotSpace}
            {renderText(`${selections[0].category} - ${selections[0].name}`)}
          </div>
        )}
        <ToolTip
          title={
            field === "barrings"
              ? SIM_CONFIG_TABLE.BARRING
              : SIM_CONFIG_TABLE.ADD_ONS
          }
          description={generateList(selections.slice(1), field)}
        >
          <TextStyle className="more-selections-tooltip">
            {`+${selections.length - 1} more`}
          </TextStyle>
        </ToolTip>
      </div>
    );
  }
}
