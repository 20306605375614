import "./style.scss";
import { useEffect, useState } from "react";
import { Column, Grid, MessageSection, Spacing, TextStyle } from "@able/react";
import { ableDevelopmentUrl, renderBoldText, renderText } from "@utils/helper";
import { useShouldEnableGetCartHook, useCartAttributes } from "@utils/cart";
import { ToggleButton } from "telstra-ui/components/toggle/ToggleButton";
import TableView from "./table-view";
import ListView from "./list-view";
import GridView from "./grid-view";
import { CustomAdvanceFilter } from "./components/advance-filter";
import { useGetOffersQuery } from "@services/catalog";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import {
  NEW_PLAN_ORDER,
  SELECT_PLAN_VIEWS,
} from "@pages/new-plan-order/constant";
import BillingAccount from "@components/billing-account";
import { updateMultipleOffers } from "@state/select-plans-slice";
import { useCommonGetCartQuery } from "@services/cart";
import { IOffer, PatchCart } from "@state/types";
import { useParams } from "react-router-dom";
import { CART } from "@utils/common-static-data";
import { setError } from "@state/error";
import ErrorScreen from "@components/error-screen";
import { setCartId, updateBillingAccount } from "@state/cart-slice";
import { customErrorMessages } from "@components/error-screen/constant";

const SelectPlan = () => {
  const customError = useAppSelector((state) => state.error.isError);
  const dispatch = useAppDispatch();
  const { cartId } = useParams();
  const [billingError, setBillingError] = useState(null);

  useEffect(() => {
    dispatch(setCartId(cartId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skipGetCart = useShouldEnableGetCartHook();

  const { data: cartData, isFetching: isCartDataFetching } =
    useCommonGetCartQuery(
      { cartId },
      {
        skip: !skipGetCart,
      }
    );

  const billingAccountNumber = useCartAttributes().getItemAttribute(
    CART.ATTRIBUTES.BILLING_ACCOUNT_NUMBER
  );

  const {
    data: offers = [],
    isLoading: loadingOffers,
    isFetching: isOffersFetching,
    isError,
    error,
  } = useGetOffersQuery();
  const cartError = useAppSelector((state) => state.cart.error);

  const updateSelectedOffers = (data: PatchCart, allOffers: IOffer[]) => {
    const offersObj = {};
    allOffers.forEach((offer) => {
      offersObj[`${offer.id}`] = offer;
    });
    const allSelectedOffers = [];
    const { cartItems } = data;
    for (const cartItem of cartItems) {
      const { itemRelationships, quantity } = cartItem;
      if (!itemRelationships.length) continue;
      const offerId = cartItem.productOffering.id;
      allSelectedOffers.push({ ...offersObj[offerId], quantity });
    }
    dispatch(updateMultipleOffers(allSelectedOffers));
  };

  useEffect(() => {
    if (!isOffersFetching && !isCartDataFetching && cartData && offers) {
      if (cartData.cartItems) {
        updateSelectedOffers(cartData, offers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartDataFetching, isOffersFetching]);

  useEffect(() => {
    if (billingAccountNumber) {
      dispatch(
        updateBillingAccount({
          billingAccount: billingAccountNumber,
          invalidBillingAccount: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAccountNumber]);

  const [selectedView, setSelectedView] = useState("table");
  const selectedFilters = useAppSelector(
    (state) => state.commonStore.selectedFilters
  );

  const selectedOffers = useAppSelector(
    (state) => state.selectPlans.selectedOffers
  );

  const selectedOffersCount = selectedOffers.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  );

  const onChangeView = (view: string) => {
    setSelectedView(view);
  };

  const renderViews = () => {
    return <Spacing top="spacing4x">{renderView(selectedView)}</Spacing>;
  };

  const catchBillingError = (err) => {
    setBillingError(err);
  };

  const getFilteredOffers = () => {
    const filteredOffers = [];
    for (const filter of selectedFilters) {
      for (const plan of offers) {
        if (filter.type === "plan" && filter.id === plan.planType) {
          filteredOffers.indexOf(plan) === -1 && filteredOffers.push(plan);
        }
      }
    }
    return filteredOffers;
  };

  const renderView = (view) => {
    const filteredOffers =
      selectedFilters.length > 0 ? getFilteredOffers() : offers;
    switch (view) {
      case "grid":
        return <GridView offers={filteredOffers} />;
      case "list":
        return <ListView offers={filteredOffers} />;
      default:
        return <TableView offers={filteredOffers} />;
    }
  };

  useEffect(() => {
    if (isError && !isOffersFetching) {
      dispatch(setError(true));
    } else {
      dispatch(setError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isOffersFetching]);

  if (customError) {
    if (error) {
      let customErrorMsg = "";

      if ("status" in error) {
        const { status } = error;
        customErrorMsg = customErrorMessages.offersAPI[status];
      }
      return <ErrorScreen error={error} customErrorMsg={customErrorMsg} />;
    } else if (billingError) {
      return <ErrorScreen error={billingError} />;
    }
  }

  return (
    <Grid>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <Spacing top="spacing4x">
          <BillingAccount catchBillingError={catchBillingError} />
        </Spacing>
      </Column>
      <Spacing top="spacing4x">
        <Column cols={12} bsm={12} bmd={12} blg={12}>
          <TextStyle alias="HeadingD">{NEW_PLAN_ORDER.FILTERS}</TextStyle>
        </Column>
      </Spacing>
      <Spacing top="spacing2x">
        <Column cols={12} bsm={12} bmd={12} blg={12}>
          {!loadingOffers && <CustomAdvanceFilter offers={offers} />}
        </Column>
      </Spacing>
      {cartError?.errorCode === 1000 && (
        <Spacing top="spacing4x">
          <Column cols={12} bsm={12} bmd={12} blg={12}>
            <MessageSection
              variant="Error"
              developmentUrl={ableDevelopmentUrl}
              description={cartError?.errorDescription}
              className="message-section"
            />
          </Column>
        </Spacing>
      )}
      <Column cols={6} bsm={6} bmd={6} blg={6} className="selected-items-text">
        <Spacing
          top={cartError?.errorCode === 1000 ? "spacing4x" : "spacing2x"}
          className={["offer-count"]}
        >
          {renderBoldText(`${selectedOffersCount}`)}
          &nbsp;
          {renderText(" item(s) selected")}
        </Spacing>
      </Column>
      <Column cols={6} bsm={6} bmd={6} blg={6}>
        <ToggleButton
          className="toggleButton tl-toggle-variant-modern"
          id="customId"
          size="large"
          options={SELECT_PLAN_VIEWS}
          value={selectedView}
          selectionMode="single"
          onChange={(option) => onChangeView(option.value)}
        />
      </Column>
      {loadingOffers ? "Loading..." : renderViews()}
    </Grid>
  );
};

export default SelectPlan;
