import "./style.scss";
import React from "react";
import {
  Column,
  Grid,
  MessageSection,
  Spacing,
  useToggle,
  ActionButton,
} from "@able/react";
import SimConfigurationTable from "./table-view";
import { ERROR_TYPES, NEW_PLAN_ORDER } from "../../constant";
import CustomPhoneNumberModal from "./custom-phone-number";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  useGetFeatureFlagHook,
  ableDevelopmentUrl,
  refreshPhoneNumber,
} from "@utils/helper";
import { SIM_CONFIG_ERRORS, TRY_AGAIN } from "@utils/common-static-data";
import { useLazyRefreshNumberQuery } from "@services/numbers";
import { setRefreshedPhonePlanId } from "@state/sim-configuration-slice";
import { NormalizedPlan } from "@state/types";

const SIMConfiguration = () => {
  const [modalIsOpen, toggleModal] = useToggle();
  const dispatch = useAppDispatch();
  const { SIM_CONFIG_NUMBER_REFRESH, SIM_CONFIG_NUMBER_PARTIAL_SUCCESS } =
    ERROR_TYPES;

  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );

  const { enableCustomSearch } = useGetFeatureFlagHook();
  const simConfigError = useAppSelector(
    (state) => state.error.simConfigurationError
  );

  const { errorCode, errorDescription, errorType } = simConfigError || {};
  const refreshedPhonePlanId = useAppSelector(
    (state) => state.simConfiguration.refreshedPhonePlanId
  );
  const plansInfo: [] = useAppSelector((state) => state.cart.normalizedPlans);
  const planIndex =
    plansInfo.length &&
    plansInfo.findIndex(
      (currentPlan: NormalizedPlan) => currentPlan?.id === refreshedPhonePlanId
    );
  const plan = plansInfo.length && planIndex && plansInfo[planIndex];

  const [refreshNumber] = useLazyRefreshNumberQuery();
  return (
    <Grid>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        {enableCustomSearch && (
          <Spacing top="spacing4x">
            <div className="searchCustomButton">
              {selectedPlans.length > 0 ? (
                <ActionButton
                  developmentUrl={ableDevelopmentUrl}
                  element="button"
                  label={NEW_PLAN_ORDER.CUSTOM_NUMBER.SEARCH_TEXT}
                  variant="MediumEmphasis"
                  data-testid="customPhoneNumber"
                  events={{
                    onClick: toggleModal,
                  }}
                />
              ) : (
                <Spacing top="spacing5x" />
              )}
            </div>
          </Spacing>
        )}
        {((errorCode === 500 && errorType === SIM_CONFIG_NUMBER_REFRESH) ||
          (errorCode === 200 &&
            errorType === SIM_CONFIG_NUMBER_PARTIAL_SUCCESS)) && (
          <Spacing top="spacing2x">
            <Grid>
              <Column cols={12} bsm={12} bmd={12} blg={12}>
                <MessageSection
                  variant="Error"
                  developmentUrl={ableDevelopmentUrl}
                  description={errorDescription}
                  actionText={errorCode === 500 ? TRY_AGAIN : ""}
                  className="error-message-section"
                  actionCallback={(e) => {
                    e.preventDefault();
                    refreshPhoneNumber(
                      dispatch,
                      setRefreshedPhonePlanId,
                      refreshedPhonePlanId,
                      refreshNumber,
                      plan,
                      planIndex,
                      SIM_CONFIG_ERRORS
                    );
                  }}
                />
              </Column>
            </Grid>
          </Spacing>
        )}
        <Spacing top="spacing4x">
          <SimConfigurationTable />
        </Spacing>
        {enableCustomSearch && (
          <CustomPhoneNumberModal
            modalIsOpen={modalIsOpen}
            toggleModal={toggleModal}
          />
        )}
      </Column>
    </Grid>
  );
};

export default SIMConfiguration;
