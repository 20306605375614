/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { Column, MessageSection, Spacing } from "@able/react";
import { ToggleButton } from "telstra-ui/components/toggle/ToggleButton";
import { useEffect, useState } from "react";
import { useAppSelector } from "@state/hooks";
import ConfigurePlansListView from "./components/list-view";
import { CONFIGURE_BARRINGS_AND_ADDONS } from "../constant";
import { ableDevelopmentUrl } from "@utils/helper";

const NewPlansByCategory = () => {
  const plans = useAppSelector((store) => store.cart.normalizedPlans);
  const plansByCategory = new Map();
  const categories = [];

  plans.forEach((currentPlan) => {
    const category = currentPlan.category;
    if (plansByCategory.has(category)) {
      plansByCategory.get(category).push(currentPlan);
    } else {
      categories.push(category);
      plansByCategory.set(category, [currentPlan]);
    }
  });

  const [activeCategory, setActiveCategory] = useState(categories[0]);

  useEffect(() => {
    if (!plansByCategory.get(activeCategory)) {
      setActiveCategory(categories[0]);
    }
  }, [plans.length]);

  const options = categories
    .map((category) => {
      return {
        id: category,
        label: `${category} (${plansByCategory.get(category).length})`,
        value: category,
        active: activeCategory === category,
        width: 500,
      };
    })
    // this helps to keep the toggle buttons in order even if plans changed.
    .sort((a, b) => (a.id < b.id ? -1 : 1));

  return (
    <>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <Spacing top="spacing5x">
          <ToggleButton
            id="categoryTab"
            size="large"
            options={options}
            value={activeCategory}
            selectionMode="single"
            onChange={(option) => {
              setActiveCategory(option.value);
            }}
          />
          <Spacing top="spacing3x">
            {plansByCategory.get(activeCategory) && (
              <ConfigurePlansListView
                plansByCategoryActiveCategory={plansByCategory.get(
                  activeCategory
                )}
                key={activeCategory}
              />
            )}
          </Spacing>
          <Spacing bottom="spacing10x">
            <div tabIndex={0}>
              <MessageSection
                description={CONFIGURE_BARRINGS_AND_ADDONS.INFORMATION_MESSAGE}
                variant="Information"
                developmentUrl={ableDevelopmentUrl}
                className="message-section"
              />
            </div>
          </Spacing>
        </Spacing>
      </Column>
    </>
  );
};

export default NewPlansByCategory;
