/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useRef, useState } from "react";
import {
  TextField,
  TextStyle,
  Spacing,
  Select,
  ActionButton,
  ModalContent,
  MessageSection,
} from "@able/react";
import { ableDevelopmentUrl, REGULAR_EXPRESSIONS } from "@utils/helper";
import { Button } from "telstra-ui/components/button/Button";
import Countdown from "./count-down";
import AvailableNumberTable from "./phone-numbers-table";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import {
  deleteSelectedNumbers,
  setAvailableNumbers,
} from "@state/sim-configuration-slice";
import { setSimConfigurationError } from "@state/error";
import { ERROR_TYPES, NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import {
  useLazySwapNumbersQuery,
  useLazyUnlockNumbersQuery,
  useLazyFetchNumberQuery,
} from "@services/numbers";
import { SIM_CONFIG_ERRORS, TRY_AGAIN } from "@utils/common-static-data";
import { updatePlansArrUsingIndex } from "@state/cart-slice";
import { CONSTANTS } from "@services/constant";

const BodyContent = ({ handleAssignment }) => {
  const dispatch = useAppDispatch();
  const paragraphRef = useRef<null | HTMLParagraphElement>(null);
  const { SIM_CONFIG_NUMBER_SEARCH, SIM_CONFIG_NUMBER_ASSIGNMENT } =
    ERROR_TYPES;
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );
  const { availableNumbers } = useAppSelector(
    (state) => state.simConfiguration
  );

  const simConfigError = useAppSelector(
    (state) => state.error.simConfigurationError
  );

  const { errorCode, errorDescription, errorType } = simConfigError || {};
  const selectedPlansLength = selectedPlans.length;
  const totalPlansSelected = selectedPlansLength.toString();
  const [searchFormState, setSearchFormState] = useState({
    action: "",
    quantity: "",
    patternType: "",
    pattern: "",
    numbersForUnassignment: null,
  });
  const [quantity, setQuantity] = useState("");
  const [validateFields, setValidateFields] = useState(false);
  const [resetCounter, setResetCounter] = useState(false);

  const [fetchNumbers] = useLazyFetchNumberQuery();

  useEffect(() => {
    setSearchFormState({
      ...searchFormState,
      quantity: totalPlansSelected,
    });
  }, [totalPlansSelected]);

  useEffect(() => {
    setValidateFields(false);
  }, [searchFormState]);

  useEffect(() => {
    if (errorType === SIM_CONFIG_NUMBER_ASSIGNMENT && paragraphRef.current) {
      paragraphRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);

  const searchNumbersHandler = async () => {
    setValidateFields(true);
    if (
      Number(searchFormState.quantity) <= 0 ||
      (searchFormState.pattern === "" && searchFormState.patternType !== "")
    ) {
      return;
    } else {
      try {
        const params = { ...searchFormState };

        if (availableNumbers.length !== 0) {
          const numbersForUnassignment = availableNumbers.map(
            (currentNumber) => currentNumber.number
          );
          params.numbersForUnassignment = { numbersForUnassignment };
          params.action = CONSTANTS.NUMBERS.LOCK_UNLOCK;
        }
        dispatch(deleteSelectedNumbers());
        const { data } = await fetchNumbers(params).unwrap();
        dispatch(setAvailableNumbers(data?.numbers));
        setQuantity(searchFormState.quantity);
        setResetCounter((prev) => !prev);
        dispatch(setSimConfigurationError(null));
      } catch (err: any) {
        const { status } = err;

        dispatch(
          setSimConfigurationError({
            errorType: SIM_CONFIG_NUMBER_SEARCH,
            errorCode: status,
            errorDescription: SIM_CONFIG_ERRORS[status]?.FETCH_NUMBERS,
          })
        );
      }
    }
  };

  const actionCallbackHandler = (e) => {
    e.preventDefault();
    if (errorType === SIM_CONFIG_NUMBER_ASSIGNMENT) {
      handleAssignment();
    }
  };

  return (
    <>
      <TextStyle alias="Base" element="div">
        <p ref={paragraphRef}>
          {NEW_PLAN_ORDER.CUSTOM_NUMBER.MESSAGE_1}
          {totalPlansSelected}
          {NEW_PLAN_ORDER.CUSTOM_NUMBER.MESSAGE_2}
        </p>
      </TextStyle>
      <Spacing top="spacing2x" bottom="spacing5x">
        <div id="searchForm">
          <div className="form-field">
            <TextField
              label="Quantity"
              id="quantity"
              name="quantity"
              developmentUrl={ableDevelopmentUrl}
              events={{
                onChange: (element) => {
                  if (
                    element.target.value === "" ||
                    REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(element.target.value)
                  ) {
                    setSearchFormState({
                      ...searchFormState,
                      quantity: element.target.value,
                    });
                  }
                },
              }}
              value={searchFormState.quantity}
              required={true}
              invalid={validateFields && Number(searchFormState.quantity) <= 0}
              invalidInputText={
                NEW_PLAN_ORDER.CUSTOM_NUMBER.QUANTITY_INVALID_INPUT
              }
            />
          </div>
          <div className="form-field">
            <Select
              id="patternType"
              label="Pattern type"
              options={NEW_PLAN_ORDER.CUSTOM_NUMBER.PATTERN_TYPE_OPTIONS}
              events={{
                onChange: (element) => {
                  setSearchFormState({
                    ...searchFormState,
                    patternType: element.target.value,
                    pattern: "",
                  });
                },
              }}
              value={searchFormState.patternType}
            />
          </div>
          <div className="form-field">
            <TextField
              label="Pattern"
              id="pattern"
              name="pattern"
              value={searchFormState.pattern}
              maxLength={searchFormState.patternType !== "" ? 7 : 8}
              events={{
                onChange: (element) => {
                  if (
                    element.target.value === "" ||
                    REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(element.target.value)
                  ) {
                    setSearchFormState({
                      ...searchFormState,
                      pattern: element.target.value,
                    });
                  }
                },
              }}
              developmentUrl={ableDevelopmentUrl}
              invalid={
                validateFields &&
                searchFormState.patternType !== "" &&
                searchFormState.pattern === ""
              }
              invalidInputText={
                NEW_PLAN_ORDER.CUSTOM_NUMBER.PATTERN_INVALID_INPUT
              }
            />
          </div>
          <div className="form-field">
            <ActionButton
              element="button"
              label={NEW_PLAN_ORDER.CUSTOM_NUMBER.SEARCH_BUTTON}
              variant="MediumEmphasis"
              className="searchNumberButton"
              events={{
                onClick: searchNumbersHandler,
              }}
            />
          </div>
        </div>
      </Spacing>
      {errorCode === 500 &&
        (errorType === SIM_CONFIG_NUMBER_ASSIGNMENT ||
          errorType === SIM_CONFIG_NUMBER_SEARCH) && (
          <Spacing bottom="spacing2x">
            <MessageSection
              variant="Error"
              developmentUrl={ableDevelopmentUrl}
              description={errorDescription}
              actionText={
                errorType === SIM_CONFIG_NUMBER_ASSIGNMENT ? TRY_AGAIN : ""
              }
              actionElement="button"
              className="message-section"
              actionCallback={actionCallbackHandler}
            />
          </Spacing>
        )}
      <Spacing bottom="spacing2x">
        {availableNumbers.length !== 0 && (
          <>
            <Countdown
              seconds={300}
              setSearchFormState={setSearchFormState}
              resetCounter={resetCounter}
            />
            <Spacing top="spacing2x">
              <AvailableNumberTable quantity={quantity} />
            </Spacing>
          </>
        )}
      </Spacing>
    </>
  );
};

const FooterContent = ({ handleAssignment }) => {
  const { availableNumbers, selectedNumbers } = useAppSelector(
    (state) => state.simConfiguration
  );

  return (
    availableNumbers.length !== 0 && (
      <Button
        variant="emphasis"
        disabled={selectedNumbers.length === 0}
        onClick={() => handleAssignment()}
      >
        {NEW_PLAN_ORDER.CUSTOM_NUMBER.ASSIGN_BUTTON}
      </Button>
    )
  );
};

const CustomPhoneNumberModal = (props) => {
  const { modalIsOpen, toggleModal } = props;
  const {
    SIM_CONFIG_NUMBER,
    SIM_CONFIG_NUMBER_ASSIGNMENT,
    SIM_CONFIG_NUMBER_PARTIAL_SUCCESS,
  } = ERROR_TYPES;
  const dispatch = useAppDispatch();
  const [unlockNumbers] = useLazyUnlockNumbersQuery();
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );
  const plans = useAppSelector((state) => state.cart.normalizedPlans);
  const { selectedNumbers, availableNumbers } = useAppSelector(
    (state) => state.simConfiguration
  );
  const [swapNumbers] = useLazySwapNumbersQuery();

  const handleClose = async () => {
    if (availableNumbers.length !== 0) {
      const numbersForUnassignment = availableNumbers.map(
        (currentNumber) => currentNumber.number
      );
      try {
        await unlockNumbers(numbersForUnassignment).unwrap();
        dispatch(deleteSelectedNumbers());
      } catch (err: any) {
        const { status } = err;
        dispatch(
          setSimConfigurationError({
            errorType: SIM_CONFIG_NUMBER,
            errorCode: status,
            errorDescription: SIM_CONFIG_ERRORS[status]?.ASSIGN_NUMBERS,
          })
        );
      }
      dispatch(deleteSelectedNumbers());
    }
    dispatch(setAvailableNumbers([]));
  };

  const handleAssignment = async () => {
    let count = 0,
      index = 0;
    const plansToBeUpdated = [];
    const indexOfPlansToBeUpdated = [];
    const numbersForUnassignment = [];

    while (count < selectedNumbers.length) {
      const { id, phoneNumber } = plans[index];
      if (selectedPlans.includes(id)) {
        plansToBeUpdated.push(id);
        indexOfPlansToBeUpdated.push(index);
        numbersForUnassignment.push(phoneNumber);
        count++;
      }
      index++;
    }
    const payload = {
      planIndexes: indexOfPlansToBeUpdated,
      fields: {
        planUpdated: true,
      },
    };
    dispatch(updatePlansArrUsingIndex(payload));

    const newSelectedPlans = selectedPlans.filter(
      (planId) => !plansToBeUpdated.includes(planId)
    );

    try {
      const res = await swapNumbers({
        selectedNumbers,
        numbersForUnassignment,
        indexOfPlansToBeUpdated,
        newSelectedPlans,
      }).unwrap();
      if (res.code === 200) {
        dispatch(setSimConfigurationError(null));
        if (res.responseCode === "PARTIAL_SUCCESS") {
          dispatch(
            setSimConfigurationError({
              errorType: SIM_CONFIG_NUMBER_PARTIAL_SUCCESS,
              errorCode: res.code,
              errorDescription: SIM_CONFIG_ERRORS[res.code].PARTIAL_SUCCESS,
            })
          );
        }
        dispatch(setAvailableNumbers([]));
        dispatch(deleteSelectedNumbers());
        toggleModal();
      }
    } catch (err: any) {
      const { status } = err;
      dispatch(
        setSimConfigurationError({
          errorType: SIM_CONFIG_NUMBER_ASSIGNMENT,
          errorCode: status,
          errorDescription: SIM_CONFIG_ERRORS[status]?.ASSIGN_NUMBERS,
        })
      );
    }
  };

  return (
    <ModalContent
      developmentUrl={ableDevelopmentUrl}
      title={NEW_PLAN_ORDER.CUSTOM_NUMBER.SEARCH_TEXT}
      variant="Expansive"
      bodyContent={<BodyContent handleAssignment={handleAssignment} />}
      footerContent={<FooterContent handleAssignment={handleAssignment} />}
      isShowing={modalIsOpen}
      setHideDialog={toggleModal}
      onClose={handleClose}
    />
  );
};

export default CustomPhoneNumberModal;
