import img404 from "./assets/404.svg";
import img401 from "./assets/401.svg";
import img400 from "./assets/400.svg";
import img500 from "./assets/500.svg";

type ErrorObject = {
  title: string;
  body: string;
  image: string;
};

const errorMessages = {
  400: {
    title: "Something went wrong",
    body: "Our server could not complete your request.\n Please submit a support enquiry and quote error code 400.",
    image: img400,
  },
  401: {
    title: "Unauthorised",
    body: "Error 401. Your session might have expired. Try signing into this platform again.",
    image: img401,
  },
  404: {
    title: "Page not found",
    body: "The page you are looking for does not exist. If that does not seem right, submit a support enquiry and quote error code 404.",
    image: img404,
  },
  500: {
    title: "Something went wrong",
    body: "Our server could not complete your request.\n Please submit a support enquiry and quote error code 500.",
    image: img500,
  },
};

export const getPageDetails = (errCode) => {
  let errData: ErrorObject;
  switch (errCode) {
    case 400:
      errData = errorMessages[400];
      break;
    case 401:
      errData = errorMessages[401];
      break;
    case 404:
      errData = errorMessages[404];
      break;
    case 500:
    default:
      errData = errorMessages[500];
  }
  return errData;
};

export const customErrorMessages = {
  cartAPI: {
    500: "Our server could not to complete your request.",
  },
  contactAPI: {
    500: "Could not retrieve customer details. Try again. \n If that does not fix it, submit a support enquiry and quote error code 500.",
  },
  offersAPI: {
    400: "Unable to fetch offer. Try again. If that does not fix it, submit a support enquiry and quote error code 400.",
    500: "Unable to fetch offer. Try again. If that does not fix it, submit a support enquiry and quote error code 500.",
    404: "Unable to fetch offer. Try again. If that does not fix it, submit a support enquiry and quote error code 404.",
  },
};

export const ERROR_SCREEN = {
  TRY_AGAIN: "Try again",
};
